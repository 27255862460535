import styled from "@emotion/styled";
import gLogo from "../../UI/images/google_logo200.png";
export const StyledCard = styled.div`
  form {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
    }
  }
`;

export const BaseButton = styled.button`
  padding: 12px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  background: #0079ee;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
`;

export const GoogleSignInButton = styled.button`
  padding: 12px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  background: #fff;
  border: 1px solid #818285;
  border-radius: 4px;
  color: #57585c;
  cursor: pointer;
  background-image: url(${gLogo});
  background-size: 20px 20px;
  background-position: 8px 12px;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  padding: 36px;
  width: 380px;
  box-sizing: border-box;
`;

export const StyledP = styled.p`
  margin: 0;
  color: #0079ee;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  text-align: end;
  cursor: pointer;
  margin-top: 12px;
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
