import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { formatIdentifier, formatCsvDatum } from "formatters";
import { TrashCan } from "UI/components/Icons";
import {
  Aggregator,
  OutcomeSelectionConfig,
  DrilldownConfig,
  Filter,
} from "types";
import {
  AggregationControls,
  MultiVarSelector,
  VarSelector,
} from "./aggregatorPieces";
import {selectDatasets, selectDatasetsByLabel, selectVariableNames} from "modelSlice/selectors";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
  /* Outer container. Two children:
     - aggregatorFields (containing a bunch of form controls)
     - delete button
  */
  aggregatorControlContainer: {
    display: "flex",
    backgroundColor: "#E6F2FE",
    borderRadius: 4,
    border: "1px solid #B3D7FA",
    alignItems: "center",
    marginBottom: 12,
    justifyContent: "space-between",
  },
  aggregatorFields: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  aggregatorLabel: {
    fontWeight: "bold",
    margin: "0 0 8px 0",
    color: "#57585C",
  },
  trashCan: {
    padding: "24px",
  },
});

type EACProps = {
  config: Aggregator;
  setter: (key: string, newValue: any) => void;
  deleter: () => void;
  varSelector: React.ReactNode;
  extraControls?: React.ReactNode;
};
const ExploreAggregatorControls = (props: EACProps) => {
  const classes = useStyles();
  return (
    <fieldset className={classes.aggregatorControlContainer}>
      <div className={classes.aggregatorFields}>
        <AggregationControls aggregator={props.config} setter={props.setter} />
        {props.varSelector}
        {props.extraControls}
      </div>
      <div
        className={classes.trashCan}
        onClick={() => {
          props.deleter();
        }}
      >
        <TrashCan />
      </div>
    </fieldset>
  );
};

type OSCProps = {
  config: OutcomeSelectionConfig;
  setter: (key: string, newValue: any) => void;
  deleter: () => void;
};
export function OutcomeSelectionControls(props: OSCProps) {
  const { config, setter } = props;
  const varnames = useSelector(selectVariableNames);
  const varSelector = (
    <MultiVarSelector
      varNames={varnames}
      selected={config.variableNames}
      onChange={(newVars: string[]) => {
        setter("variableNames", newVars);
      }}
    />
  );
  return <ExploreAggregatorControls varSelector={varSelector} {...props} />;
}

type DdProps = {
  config: DrilldownConfig;
  setter: (key: string, newValue: any) => void;
  deleter: () => void;
};
export function DrilldownControls(props: DdProps) {
  const { config, setter } = props;
  const ds = useSelector(selectDatasetsByLabel)[config.dataset];
  const datasets = useSelector(selectDatasets);
  const varnames = ds?.variables.map((v) => v.short_name) || [];
  const classes = useStyles();
  const dsSelector = (
    <div>
      <label className={classes.aggregatorLabel}>Dataset:</label>
      <Select
        value={config.dataset}
        onChange={(e) => {
          setter("dataset", e.target.value);
        }}
      >
        {datasets.map((ds) => (
          <MenuItem key={ds.label} value={ds.label}>
            {formatIdentifier(ds.label)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
  const rowSelector = config.dataset && (
    <div>
      <label className={classes.aggregatorLabel}>Rows:</label>
      <Select
        multiple={true}
        value={config.keyIndex || []}
        onChange={(e) => {
          // TODO: Why doesn't type checker believe that this is an array?
          const newVal = e.target.value;
          if (!(newVal instanceof Array)) {
            console.error("Type mismatch!", newVal);
            return;
          }
          setter("keyIndex", newVal);
        }}
      >
        {(ds?.keyVals || []).map((rowLabel, rowIndex) => (
          <MenuItem key={rowIndex} value={rowIndex}>
            {formatCsvDatum(rowLabel)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
  const varSelector = (
    <MultiVarSelector
      varNames={varnames}
      selected={config.variableNames}
      onChange={(newVars: string[]) => {
        setter("variableNames", newVars);
      }}
    />
  );
  const extras = (
    <>
      {dsSelector}
      {rowSelector}
    </>
  );
  return (
    <ExploreAggregatorControls
      varSelector={varSelector}
      extraControls={extras}
      {...props}
    />
  );
}

type FilterProps = {
  config: Filter;
  setter: (key: string, newValue: any) => void;
  deleter: () => void;
};
// NB: max and min are handled elsewhere. May or may not want to incorporate
// them into this component.
export function FilterControls(props: FilterProps) {
  const varnames = useSelector(selectVariableNames);
  const varSelector = (
    <VarSelector
      varNames={varnames}
      selected={props.config.variable}
      onChange={(newVar: string) => {
        props.setter("variable", newVar);
      }}
    />
  );
  return <ExploreAggregatorControls varSelector={varSelector} {...props} />;
}
