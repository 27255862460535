
export const getTypeAheadWords = (
  variables,
  policies,
  datasets,
  externalModels,
  functionNames
) => {
  // Bit of a hack to account for old-style policies that might be mid-migration.
  const attributeNames = policies.attributes || [];
  const datasetNames = datasets.map((ds) => ds.label);
  const externalModelNames = externalModels.map((m) => m.label);
  const varNames = variables.map((v) => v.short_name);

  const typeAheadTokens = {
    modelPrimitives: [
      ...varNames,
      ...attributeNames,
      ...datasetNames,
      ...externalModelNames,
    ],
    functions: functionNames,
  };

  return typeAheadTokens;
};


