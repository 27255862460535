import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { Histograms } from "components/Charting/Histogram";
import { TimeSeriesChart } from "components/Charting/TimeSeriesCharts";
import { DagDrawing } from "./DagDrawing";
import { EquationHelp } from "./EquationHelp";
import { ErrorBoundary } from "ErrorBoundary";
import { Status as ServerStatus } from 'api/useMainApi';
import { 
  Deps,
  SimResultRow,
} from 'api/types';

const styleMaker = makeStyles(() =>
  createStyles({
    navLinks: {
      color: "#0079EE",
      textDecoration: "none",
      marginRight: "24px !important",
      cursor: "pointer",
    },
    activeNavLinks: {
      color: "#57585C",
      borderBottom: "1px solid #57585C",
      paddingBottom: 4,
    },
  })
);

// Identifiers for the possible visualization types in the right pane
type VizOption = ("dag" | "timeSeries" | "histograms");
type RadioDat = {
  label: string;
  id: VizOption;
}
const RADIO_DATA: RadioDat[] = [
  { label: "Model visualization", id: "dag" },
  { label: "Time Series", id: "timeSeries" },
  { label: "Point In Time", id: "histograms" },
];

type Props = {
  serverStatus: ServerStatus;
  showEquationHelp: boolean;
  setShowEquationHelp: (newVal: boolean) => void;
  mainSimResults: SimResultRow[];
  // May be null if api results are still being loaded (i.e. computing = true)
  deps: Deps | null;
};
export function RightSplitMain(props: Props) {
  const [right, setRight] = useState<VizOption>("timeSeries");
  const simResults = props.mainSimResults;
  const { serverStatus, showEquationHelp } = props;
  const setShowEquationHelp = props.setShowEquationHelp;
  const classes = styleMaker();
  if (showEquationHelp) {
    return (
      <div className="splitright">
        <div style={{ paddingLeft: 30 }}>
          <EquationHelp closeEquationHelp={() => setShowEquationHelp(false)} />
        </div>
      </div>
    );
  } else {
    const radios = RADIO_DATA.map((dat) => (
      <label key={dat.id}>
        <span
          onClick={() => setRight(dat.id)}
          className={
            classes.navLinks +
            " " +
            (dat.id === right && classes.activeNavLinks)
          }
        >
          {dat.label}
        </span>
      </label>
    ));
    const computing = serverStatus === 'loading';
    let content;
    if (serverStatus === 'error') {
      content = <p>Server error.</p>;
    } else if (serverStatus === 'quiescent') {
      content = null;
    } else { // status is loaded or loading
      // we'll be wrapping this in an ErrorBoundary
      let innerContent;
      switch (right) {
        case 'dag':
          innerContent = serverStatus === 'loaded' ? (
            <DagDrawing
                  deps={props.deps}
              />
          ) : (<p>Loading...</p>);
          break;
        case 'timeSeries':
          innerContent = (
            <TimeSeriesChart
              computing={computing}
              data={simResults}
            />
          );
          break;
        case 'histograms':
          innerContent = (
            <Histograms
              computing={computing}
              data={simResults}
            />
          );
      }
      content = <ErrorBoundary>{ innerContent }</ErrorBoundary>;
    }
    return (
      <div className="splitright">
        {radios}
        {content}
      </div>
    );
  }
}
