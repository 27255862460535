import { 
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';

//import { Model } from 'types';
import { initialState } from './state';
import reducers from './reducers';

// bit of a hack
interface FireBase {
  db: {
    collection: (name: string) => {
      doc: (x: any) => {
        get: () => any,
        update: (newVal: any) => any,
      },
      add: (x: any) => any,
    };
  };
}

type fetchModelArg = {
  firebase: FireBase,
  modelId: string,
};
export const fetchModel = createAsyncThunk('model/fetchModel',
                                           async ({firebase, modelId}:fetchModelArg) => {
  // If a document with this modelId doesn't exist or isn't accessible by the
  // current user, this promise will reject, causing the whole thunk to reject.
  const snapshot = await firebase.db.collection("models").doc(modelId).get();
  const dat = snapshot.data();
  //console.log(typeof(dat.createdOn));
  // bleh, work around non-serializable attribute
  // https://googleapis.dev/nodejs/firestore/latest/Timestamp.html
  dat.createdOn = dat.createdOn.toMillis();
  return dat;
});

const selectMetaModel = (state) => {
  const dat = state.model;
  const meta: any = {
    model: dat.model,
    viewable: dat.viewable || false,
  };
  if (dat.version) {
    meta.version = dat.version;
  }
  if (dat.owner_uid) {
    meta.owner_uid = dat.owner_uid;
  }
  return meta;
};

// TODO: move thunks and their extraReducers to separate module?
export const pushUpdate = createAsyncThunk('model/pushUpdate',
                            async ({firebase, modelId}:fetchModelArg, thunkAPI) => {
  const state = thunkAPI.getState();
  const meta = selectMetaModel(state);
  /* Technically this update invocation is wasteful. We don't need to pass
     in the whole metaModel object - it's sufficient to pass in just the properties
     that have changed. But this is probably fine.
  */
  const response = await firebase.db.collection("models")
    .doc(modelId)
    .update(meta);
  return response;
});

type pushForkArg = {
  firebase: FireBase,
  userId: string,
};
export const pushFork = createAsyncThunk('model/pushFork',
                            async ({firebase, userId}:pushForkArg, thunkAPI) => {
  const state = thunkAPI.getState();
  const meta = selectMetaModel(state);
  meta.owner_uid = userId;
  meta.createdOn = new Date();
  // Forks default to private visibility.
  meta.viewable = false;
  const response = await firebase.db.collection("models")
    .add(meta);
  return response;
});


export const slice = createSlice({
  name: 'model',
  initialState: initialState,
  reducers: reducers,
  extraReducers: builder => {
    builder.addCase(fetchModel.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(fetchModel.fulfilled, (state, action) => {
      state.status = 'succeeded';
      const metamodel = action.payload;
      state.owner_uid = metamodel.owner_uid;
      state.viewable = metamodel.viewable;
      state.model = metamodel.model;
      state.version = metamodel.version;
      state.dirty = false;
      state.example = null;
    });
    builder.addCase(fetchModel.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(pushUpdate.pending, (state, action) => {
      state.syncing = true;
      state.dirty = false;
    });
    builder.addCase(pushUpdate.fulfilled, (state, action) => {
      state.syncing = false;
    });
    builder.addCase(pushUpdate.rejected, (state, action) => {
      console.error("Error occurred when trying to push update to FireStore: ",
                  action.error);
      // TODO: may want to set some state flag here which Simulator can read
      // and announce in the UI? 
      state.syncing = false;
    });
    builder.addCase(pushFork.pending, (state, action) => {
      state.syncing = true;
      state.dirty = false;
    });
    builder.addCase(pushFork.fulfilled, (state, action) => {
      state.syncing = false;
      state.example = null;
    });
    builder.addCase(pushFork.rejected, (state, action) => {
      console.error("Error occurred when trying to push fork to FireStore: ",
                  action.error);
      state.syncing = false;
    });
  },
});

export const reducer = slice.reducer;

export const actions = slice.actions;

