import React, { Fragment } from "react";
import TableWrapper from "../variableTable/TableWrapper";
import CloseIconBlue from "../../../../UI/images/closeIconBlue.svg";
import styled from "@emotion/styled";

export function EquationHelp(props) {
  const closeEquationHelp = props.closeEquationHelp;

  const content = [
    { equation: "5", description: "Variable is always 5" },
    {
      equation: "price * quantity",
      description: "Multiplication of two variables",
    },
    { equation: "centimeters / 100", description: "Division" },
    { equation: "wind_speed ^ 2", description: "Exponentiation" },
    {
      equation: "min(demand, production)",
      description: "Lesser value of two variables",
    },
    {
      equation: "max(demand, production)",
      description: "Greater value of two variables",
    },
    {
      equation: "price[t-1] + 1",
      description: "One more than the price in the previous period",
    },
    {
      equation: "t / 5",
      description:
        "Current time period number divided by 5. The time period is always the variable t",
    },
    {
      equation: "where(score>70, 1, 0)",
      description:
        "Assign value of 1 if score is greater than 70. Otherwise assign value of 0",
    },
    {
      equation: "where(score>70 and age<10, 1, 0)",
      description: "Assign value based on two clauses both being true",
    },
    {
      equation: "where(score>70 or age<10, 1, 0)",
      description:
        "Assign value based on at least one of two clauses being true",
    },
    {
      equation: "uniform(0, 7)",
      description:
        "A random number from the uniform distribution between 0 and 7",
    },
    {
      equation: "normal(0, 1)",
      description:
        "A random number from the normal distribution with mean 0 and standard deviation 1",
    },
    {
      equation: "sum(TRANSACTIONS.dollar_value)",
      description:
        "The total value for all dollar_value entries in TRANSACTIONS dataset. Can also be used with dataset-specific variables, in which case it sums over values for the same policy and time period",
    },
    {
      equation: "join(FORECASTS.period == t, FORECASTS.unit_sales)",
      description:
        "The value of unit_sales in the FORECASTS table for the row in which FORECASTS.period has the same value as t.",
    },
  ];

  return (
    <Fragment>
      <div>
        <StyledImg
          src={CloseIconBlue}
          onClick={() => closeEquationHelp()}
          alt="close-icon"
        />
      </div>
      <div style={{ paddingTop: 12 }}>
        <TableWrapper align="center" cellPadding={"10"} width={"90%"}>
          <thead>
            <tr>
              <TableHead>Equation</TableHead>
              <TableHead>Description</TableHead>
            </tr>
          </thead>
          <tbody>
            {content.map((row, index) => {
              return (
                <tr key={index}>
                  <TableBody>{row.equation}</TableBody>
                  <TableBody>{row.description}</TableBody>
                </tr>
              );
            })}
          </tbody>
        </TableWrapper>
      </div>
    </Fragment>
  );
}

const TableHead = styled.td`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding: 4px 8px 8px 8px;
`;
const StyledImg = styled.img`
  width: 24px;
`;
const TableBody = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  background: #e6f2fe;
  padding: 8px 8px;
`;
