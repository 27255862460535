import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { StyledCard, Content, StyledP, FlexWrapper } from "./Styles";
import { SignInForm, SignInGoogle } from "./SignIn";
import Title from "../../UI/components/Title";

const SignInModal = (props) => {
  return (
    <Dialog open={true} BackdropProps={{ invisible: true }}>
      <Content>
        <Title>Sign in</Title>
        <StyledCard>
          <SignInForm />
          <SignInGoogle />
          <FlexWrapper>
            <StyledP onClick={props.switchToSignUp}>Get an account</StyledP>
            <StyledP onClick={props.switchToResetPassword}>
              Forgot password?
            </StyledP>
          </FlexWrapper>
        </StyledCard>
      </Content>
    </Dialog>
  );
};

export default SignInModal;
