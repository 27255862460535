import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import { Firebase, FirebaseContext } from "./firebaseHelpers";
import store from "./store";
import * as FullStory from "@fullstory/browser";

FullStory.init({ orgId: "ZBRBN" });

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root")
);
