import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { ExampleAnchor } from "UI/components/Anchor";

export function ExampleBar(props) {
  let history = useHistory();
  if (
    history.location.pathname.search("explore") !== -1 ||
    history.location.pathname === "/models"
  ) {
    return null;
  }
  return (
    <Layout
      style={{
        marginBottom: 12,
      }}
    >
      <FlexWrapper
        style={{
          backgroundColor: "#E6F2FE",
          justifyContent: "space-between",
          padding: "12px 24px",
          border: "1px solid #B3D7FA",
          width: "100%",
          margin: "0 24px",
        }}
      >
        <FlexContainer>
          <span>Example simulations:</span>
          <ExampleAnchor href={"/example/retirement"}>
            Retirement Calculator
          </ExampleAnchor>
          <ExampleAnchor href={"/example/inventory"}>
            Inventory Management
          </ExampleAnchor>
          <ExampleAnchor href={"/example/staffing"}>
            Restaurant Staffing
          </ExampleAnchor>
          <ExampleAnchor href={"/example/airline"}>
            Airline Pricing
          </ExampleAnchor>
          <ExampleAnchor href={"/example/fraud_investigation"}>
            Fraud Investigation
          </ExampleAnchor>
        </FlexContainer>
      </FlexWrapper>
    </Layout>
  );
}

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 12px 4px 24px;
  flex-wrap: wrap;
  border-radius: 4px;
  a {
    margin-left: 12px;
  }
  a:last-child {
    margin-right: 12px;
  }
  span {
    margin-left: 4px;
  }
`;

const Layout = styled.div`
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  a {
    color: #0079ee;
  }
  a:last-child {
    margin-right: 24px;
  }
  span {
    color: #57585c;
  }
  & button {
    position: relative;
    background: transparent;
    border: 0;
    outline: 0;
  }
`;
