import { Model } from "types";

type status =
  | "idle" // initial state
  | "loading"
  | "succeeded"
  | "failed";

export interface ModelState {
  // Relative to loading of model from FireStore
  status: status;
  // Whether we're in the middle of pushing changes to FireStore
  // TODO: maybe change this to an enum to incorporate an error state
  syncing: boolean;
  error?: string;
  owner_uid?: string;
  viewable?: boolean;
  /* TODO: this should probably be typed as optional Model. But there's some
     trickiness wrt our reducers, most of which assume that Model is non-null.
     What's the correct way to assert that a model actually exists when these
     are called?
     Might want to apply some higher-order wrapper around them that poops out
     (and raises a warning?) if model is empty/falsey. Or maybe just need to
     make a clear decision on where in the call stack the responsibility for
     handling this condition is.
     (Such a wrapper might also be useful for setting some 'dirty' bit which
     would indicate to Simulator that there has been a material change to the
     model that needs to cause a FireStore update/fork.)
  */
  model?: Model;
  example?: {
    name: string;
    description: string;
  };
  version?: number;
  // Whether there are material changes since last sync with FireStore.
  /* TODO: We might be able to do a better job with the case where a model state update
     occurs while a FireStore update is in progress (i.e. syncing=true). In that case,
     the local state update will set dirty=true, but we will not fire a pushUpdate
     thunk because syncing is true. Then when the pending pushUpdate thunk resolves, we
     set syncing=false, and *then* simulator will push another update, and flip the dirty
     bit back to false. It would probably be preferable to cancel the pending push
     when the local update comes in, and immediately fire a new one.
  */
  dirty: boolean;
  /* Number of model interactions this session. Used to erect a signin-wall after a certain
     number of interactions.
   */
}

const BLANK_MODEL: Model = {
  datasets: [],
  decision: "",
  externalModels: [],
  filters: [],
  granularity: "Week",
  // NB: These were originally initialized to length-1 arrays containing a dummy
  // collection on new models. There may be some code that breaks because it depends
  // on this.
  dsVarCollections: [],
  mainVarCollections: [],
  periods: 10,
  policies: { policyNames: [], attributes: [], formulas: [] },
  positions: {},
  selectedPolicies: [],
  timeRange: [1, null],
  variables: [],
};

export const initialState: ModelState = {
  status: "idle",
  syncing: false,
  error: null,
  model: BLANK_MODEL,
  version: 1.0,
  /* Whether there are local changes that have yet to be synced to Firestore.
     TODO: currently an annoying (but mostly harmless) issue where no-op changes
     are causing us to set the dirty bit and do a pointless Firestore sync. e.g.
     enter the cell for some policy/variable name, and then lose focus without
     changing the value. This dispatches a rename action, which sets the dirty 
     bit. Simplest solution would be in the component callback that responds to
     the UI event, check whether there's an actual difference, and if not, don't
     dispatch the event. Feels like there ought to be a more elegant solution.
  */
  dirty: false,
};
