import React from "react";
import styled from "@emotion/styled";
import SelectIcon from "../images/select-triangle.svg";

interface ExtraProps {
  // The items we're selecting between. We will generate an <option> for each.
  options: any[];
  // String labels to show for each option. Should have same length as options.
  labels?: string[];
}
type Props = ExtraProps & React.HTMLProps<HTMLSelectElement>;
const Select = ({ options, labels, ...rest }: Props) => {
  return (
    <StyledSelect {...rest}>
      {options.map((option, i) => (
        <option key={option} value={option}>
          {labels ? labels[i] : option}
        </option>
      ))}
    </StyledSelect>
  );
};

export default Select;

const StyledSelect = styled.select`
    height: 24px;
    padding: 8px 16px;
    background: #EEF7FF;
    color: #57585C;
    position: relative;
    height: 36px;
    width: 100%;
    border: 1px solid #818285;
    box-sizing: border-box;
    border-radius: 2px;
    appearance:none
    -moz-appearance:none;
    -webkit-appearance:none;
    background: #FFF url(${SelectIcon}) no-repeat 94% 50%;
    & option {
        color: #57585C;
        padding-top: 12px;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 2px gray;
    }
`;
