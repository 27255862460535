import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import Graph from "react-graph-vis";

import { Deps } from 'api/types';
import { actions } from 'modelSlice';
import {
  selectPolicies,
  selectPositions,
  selectVariableNames,
} from 'modelSlice/selectors';

// react-graph-vis doesn't publish type declarations, so this is a legal fiction
// cf. API docs at https://visjs.github.io/vis-network/docs/network/
type Network = {
  on: (action: string, f: Function) => void;
  getPositions: () => {};
};

interface Node {
  id: string;
  label: string;
  color?: string;
  x?: number;
  y?: number;
  physics?: boolean;
}

interface Edge {
  from: string;
  to: string;
}

const POLICY_NODE_ID = "policy";

type DagProps = {
  deps: Deps;
}
export function DagDrawing(props: DagProps) {
  const positions = useSelector(selectPositions);
  const { policyNames, attributes } = useSelector(selectPolicies);
  const variableNames = useSelector(selectVariableNames);
  const identifiers = [...variableNames, ...attributes];
  let nodes: Node[] = identifiers.map((variable) => ({
    id: variable,
    label: variable,
  }));


  if (policyNames.length > 0) {
    const label = policyNames.join(" vs ");
    const decision_variable = {
      id: POLICY_NODE_ID,
      label: label,
      color: "lightgreen",
      x: -200,
      y: 80,
    };
    nodes.push(decision_variable);
  }

  nodes = nodes.map((variable) => ({
    ...variable,
    ...positions[variable.id],
    physics: !positions.hasOwnProperty(variable.id),
  }));

  const each_variable_edges = nodes.map((variable) =>
      props.deps[variable.id]
        ? props.deps[variable.id].map((dep) => ({
            to: variable.id,
            from: dep,
          }))
        : []
  );
  const depEdges: Edge[] = [].concat.apply([], each_variable_edges);
  // All attributes are treated as having a dependency on the policy node
  const attrEdges = attributes.map( (attr) => ({
    from: POLICY_NODE_ID,
    to: attr,
  }));
  const edges: Edge[] = [...depEdges, ...attrEdges];

  const state = {
    options: {
      width: "100%",
      height: "600px",
      physics: {
        forceAtlas2Based: {
          springLength: 300,
          springConstant: 0.1,
          avoidOverlap: 100,
        },
      },
      interaction: { multiselect: true, dragView: true },
    },
    graph: {
      nodes: nodes,
      edges: edges,
    },
  };

  const [network, setNetwork] = useState<Network>();
  const dispatch = useDispatch();

  useEffect(() => {
    network?.on("release", function (params) {
      const varName = this.getNodeAt(params.pointer.DOM);
      // may be nulldefined if user was dragging the canvas rather than a
      // particular node
      if (!varName) {
        return;
      }
      const position = network.getPositions()[varName];
      dispatch(actions.setDagPosition({position, varName}));
    });
  }, [network, dispatch]);

  return (
    <div id="graph">
      <Graph
        id={"graphId"}
        graph={state.graph}
        options={state.options}
        getNetwork={(network) => setNetwork(network)}
      />
    </div>
  );
}
