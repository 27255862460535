import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const PeriodSlider = withStyles({
  root: {
    color: "#0079EE",
    height: 12,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default PeriodSlider;
