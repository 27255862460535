import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";

import PeriodSlider from "./PeriodSlider";
import {
  Aggregator,
} from 'types';
import {
  selectGranularity,
  selectNumPeriods,
} from 'modelSlice/selectors';
import { formatVarName } from "formatters";
import {useSelector} from "react-redux";

// Could probably be fancier about typing this using keyof/generics?
type AggregatorParamSetter = (param: string, value: any) => void;

const useStyles = makeStyles({
  aggregationControls: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "12px 48px 12px 12px",
  },
  aggregationLabel: {
    fontWeight: "bold",
    margin: "0 0 8px 0",
    color: "#57585C",
  },
  granularityLabel: {
    fontSize: "12px",
    opacity: ".7",
    textAlign: "center",
    display: "block",
    color: "#57585C",
  },
  variableControls: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "12px",
  },
  sliderControl: {
    width: "100%",
    '& .MuiSlider-root': {
      padding: "12px 0 0 0",
    },
  },
});

/* Form controls for setting the aggregation type of an Aggregator object, as
   well as the related "time" field when aggregation type is set to "point".
*/
type AggProps = {
  aggregator: Aggregator;
  setter: AggregatorParamSetter;
};
export const AggregationControls = (props: AggProps) => {
  const { aggregator, setter } = props;
  const numPeriods = useSelector(selectNumPeriods);
  const granularity = useSelector(selectGranularity);
  const classes = useStyles();
  const timeSlider = aggregator.type === 'point' && (
    <div className={classes.sliderControl}>
      <PeriodSlider
        aria-label="period slider"
        valueLabelDisplay="on"
        value={aggregator.time}
        min={1}
        max={numPeriods}
        onChangeCommitted={(_, value) => setter("time", value)} />
      <label className={classes.granularityLabel}>{ granularity }</label>
    </div>
  );

  return (
    <>
      <div className={classes.aggregationControls}>
        <label className={classes.aggregationLabel}>Outcome Type:</label>
        <Select
          value={aggregator.type}
          onChange={(e) => {
            setter("type", e.target.value);
          }}>
          <option value="total">Total</option>
          <option value="mean">Mean</option>
          <option value="point">Point In Time</option>
        </Select>
        { timeSlider }
      </div>
    </>
  );
};

const renderVariableOption = (varname: string) => {
  return (
    <Chip
      label={formatVarName(varname)}
      key={varname}
    />
  );
};

type VarSelectorProps = {
  varNames: string[];
  selected: string | null;
  onChange: (newValue: string) => void;
};
export const VarSelector = (props: VarSelectorProps) => {
  const varNames = props.varNames;
  const classes = useStyles();
  return (
    <div className={classes.variableControls}>
      <label className={classes.aggregationLabel}>Variable:</label>
      <Select
        value={props.selected}
        onChange={(e) => { props.onChange(e.target.value as string) }}
        input={<Input />}
        renderValue={renderVariableOption}
      >
        { varNames.map((name) => (
            <MenuItem key={name} value={name}>
              {formatVarName(name)}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};
type MultiVarSelectorProps = {
  varNames: string[];
  selected: string[];
  onChange: (newValue: string[]) => void;
};
export const MultiVarSelector = (props: MultiVarSelectorProps) => {
  const varNames = props.varNames;
  const classes = useStyles();
  return (
    <div className={classes.variableControls}>
      <label className={classes.aggregationLabel}>Variables:</label>
      <Select
        multiple={true}
        value={props.selected}
        onChange={(e) => { props.onChange(e.target.value as string[]) }}
        input={<Input />}
        renderValue={ (selected: string[]) => {
          return selected.map(renderVariableOption);
        }}
      >
        { varNames.map((name) => (
            <MenuItem key={name} value={name}>
              {formatVarName(name)}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};
