export function backend_url() {
  const REMOTE_BACKEND_URL =
    "https://us-central1-dai-sim-app.cloudfunctions.net/main";
  const LOCAL_BACKEND_URL = "http://0.0.0.0:8080/";

  if (process.env.NODE_ENV === "production") {
    // in production, we only use the remote backend
    return REMOTE_BACKEND_URL;
  } else {
    if (process.env.REACT_APP_BACKEND === "remote") {
      return REMOTE_BACKEND_URL;
    } else if (process.env.REACT_APP_BACKEND === "local") {
      return LOCAL_BACKEND_URL;
    } else {
      alert(
        "defaulting to remote backend; if you don't want this alert start the app like this: `REACT_APP_BACKEND=local npm run start` or `REACT_APP_BACKEND=remote npm run start`"
      );
      return REMOTE_BACKEND_URL;
    }
  }
}
