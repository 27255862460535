import React, {useEffect} from "react";
import Slider from "@material-ui/core/Slider";

type EventValueHandler = (event: any, value: any) => void;
type Props = {
  timeRange: any;
  handleTimeMouseUp: EventValueHandler;
  numPeriods: number;
};
/* Slider for setting range of time periods (i.e. Model.timeRange)
*/
export function TimePeriodSlider(props: Props) {

  const [minT, maxT] = props.timeRange;

  const handleTimeChange = (event, newValue) => {
    // timeSliderSelected is the slider value in the interface at the moment
    // minT and maxT are the values synced with the rest of the app
    setTimeSliderSelected(newValue);
  };

  const [timeSliderSelected, setTimeSliderSelected] = React.useState(props.timeRange);

  useEffect(() => {
    setTimeSliderSelected([minT, maxT]);
  }, [minT, maxT]);

  return (
    <Slider
      value={timeSliderSelected}
      onChange={handleTimeChange}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
      onChangeCommitted={props.handleTimeMouseUp}
      max={props.numPeriods}
      min={1}
    />)
}
