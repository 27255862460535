import React from "react";
import styled from "@emotion/styled";

const TableWrapper = (props) => {
  return <StyledTable>{props.children}</StyledTable>;
};

export default TableWrapper;

const StyledTable = styled.table`
  border-spacing: 4px 1px;
  padding: 24px;

  td > p {
    font-weight: bold;
    margin-bottom: 12px;
  }
`;
