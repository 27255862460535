export const plotConfig = {
  range: {
    category: [
      "#30a2da",
      "#fc4f30",
      "#e5ae38",
      "#6d904f",
      "#8b8b8b",
      "#b96db8",
      "#ff9e27",
      "#56cc60",
      "#52d2ca",
      "#52689e",
      "#545454",
      "#9fe4f8",
    ],
  },
};
