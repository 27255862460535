export function format_errors(errors_dict) {
  const error_array = [];
  for (const name in errors_dict) {
    for (const type in errors_dict[name]) {
      for (const i in errors_dict[name][type]) {
        error_array.push({
          name: name,
          type: type,
          detail: errors_dict[name][type][i],
        });
      }
    }
  }

  return error_array.map((error) => `${error.name}: ${error.detail}`);
}
