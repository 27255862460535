import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import { SemiControlledInput as SCInput } from "UI/components/StyledInput";
import Select from "UI/components/Select";
import Title from "UI/components/Title";
import { actions } from "modelSlice";
import { granularityOptions } from "types";
import { selectModel } from "../../../modelSlice/selectors";

const TopCard = (props) => {
  const dispatch = useDispatch();
  const { decision, granularity, periods } = useSelector(selectModel);
  const viewable = useSelector((state) => state.model.viewable);
  const setViewable = (val) => {
    dispatch(actions.setViewable(val));
  };

  const [periodsErr, setPeriodsErr] = useState<string | null>(null);

  const setPeriod = (val: number) => {
    if (val > 500) {
      setPeriodsErr("Number of periods must be no more than 500.");
    } else {
      setPeriodsErr(null);
      dispatch(actions.setNumPeriods(val));
    }
  };

  return (
    <>
      <TopCardWrapper>
        <FlexWrapper>
          <Title>Simulation Name</Title>
          <SCInput
            value={decision}
            onBlur={(e) => {
              dispatch(
                actions.setModelProperty({
                  field: "decision",
                  value: e.target.value,
                })
              );
            }}
            placeholder={"Unnamed Simulation"}
            type="text"
          />
        </FlexWrapper>

        <FlexWrapper>
          <Title>Simulation Period</Title>
          <Select
            id="granularity"
            onChange={(e) =>
              dispatch(
                actions.setModelProperty({
                  field: "granularity",
                  value: e.currentTarget.value,
                })
              )
            }
            options={granularityOptions}
            value={granularity}
          />
        </FlexWrapper>
        <FlexWrapper>
          <Title>Number of Periods</Title>
          {periodsErr && <Error>{periodsErr}</Error>}
          <SCInput
            value={periods}
            onBlur={(e) => setPeriod(Number(e.target.value))}
            type="number"
          />
        </FlexWrapper>

        {props.ownsModel && (
          <FlexWrapper>
            <Title>Sharing</Title>
            <Select
              id="viewable"
              options={["Private", "Viewable with link"]}
              value={viewable ? "Viewable with link" : "Private"}
              onChange={(e) => {
                if (e.currentTarget.value === "Viewable with link") {
                  setViewable(true);
                } else {
                  setViewable(false);
                }
              }}
            />
          </FlexWrapper>
        )}
      </TopCardWrapper>
    </>
  );
};

export default TopCard;

export const TopCardWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-right: 20px;
  }

  input {
    min-width: 100%;
  }
`;

const Error = styled.p`
  font-size: 12px;
  background-color: #ffb6c1;
  line-height: 100%;
`;
