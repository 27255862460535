import { Excerpt, ExcerptRow } from 'api/types';

const makeDataTableContents = (
  datasetResults,
  fileData,
  timePeriod,
  numItemsInDataTable,
  valsNotShownInDataTable,
  policyIndex
) => {
  const resultsForDataTable =
    datasetResults?.length > 0
      ? datasetResults.filter(
          (row) => row.t === timePeriod && row.policyId === policyIndex
        )
      : [];
  const dataTableContents = [...Array(numItemsInDataTable).keys()].map((i) => {
    return {
      ...fileData[i],
      ...resultsForDataTable[i],
    };
  });
  valsNotShownInDataTable.forEach((k) =>
    dataTableContents.map((row) => delete row[k])
  );
  return dataTableContents;
};

export const getDatasetExpansionPanelResults = (
  datasetResults,
  fileData,
  timePeriod,
  numItemsInDataTable,
  policyIndex,
) => {
  const valsNotShownInDataTable = ["simId", "policyId", "t", "rowId"];
  const usableTimePeriod = timePeriod ? timePeriod : 1;

  return makeDataTableContents(
    datasetResults,
    fileData,
    usableTimePeriod,
    numItemsInDataTable,
    valsNotShownInDataTable,
    policyIndex
  );
};

export const getErrorsInDataset = (errors, dataset) => {
  if (!errors) {
    return null;
  }
  const out = Object.fromEntries(
    Object.entries(errors)
      .filter(([k, v]) => k.split(".")[0].startsWith(dataset.label))
      .map(([k, v]) => [k.split(".")[1], v])
  );
  return out;
};

export const getSimulatedOutputData = (
  excerpt: Excerpt,
  rowIx: number,
  policyIx: number,
) : object[] => {
  const columnsToDrop = ['rowId', 'policyId'];
  // NB: data is already sorted by t when we receive it from the server
  return excerpt
    .filter( (row: ExcerptRow) => (row.rowId === rowIx && row.policyId === policyIx) )
    .map( (row: ExcerptRow) => (
        Object.fromEntries(
            Object.entries(row).filter( ([k, v]) => !columnsToDrop.includes(k) )
        )
    ));
};
