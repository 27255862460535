import React, { useState } from "react";
import styled from "@emotion/styled";
import { LinearProgress } from '@material-ui/core';

import Select from "UI/components/Select";
import { DataOutputTable } from "./DataOutputTable";
import {
  selectPolicyNames,
  selectTimeRange,
  selectGranularity
} from "modelSlice/selectors";
import {useSelector} from "react-redux";

type Props = {
  mainSimResults: any;
};
export function SimulationOutputTable(props: Props) {
  const simOutput = props.mainSimResults;
  const timeRange = useSelector(selectTimeRange);
  const granularity = useSelector(selectGranularity);
  // String identifier of policy for which to show results.
  const [policy, setPolicy] = useState<string>();
  const policyNames = useSelector(selectPolicyNames);

  // simOutput may be null if results are still being loaded.
  if (simOutput === null) {
    return (<LinearProgress />);
  }


  const ixPolicy = policy ? policyNames.indexOf(policy) : 0;

  const maxNumToShow = 7;
  const minTimeToShow = Math.max(
    0,
    Math.min(timeRange[0], timeRange[1] - maxNumToShow)
  );
  const maxTimeToShow = Math.min(minTimeToShow + maxNumToShow);
  const dataToShow = simOutput
    .filter((row) => policyNames.length === 0 || row.policyId === ixPolicy)
    .filter((sim) => sim.t >= minTimeToShow)
    .filter((sim) => sim.t <= maxTimeToShow)
    .filter((sim) => sim.simId < 2)
    .sort((row1, row2) => row1.simId - row2.simId || row1.t - row2.t)
    .map((obj) => ({ ["t (" + granularity + ")"]: obj["t"], ...obj }))
    .map(({ simId, policyId, t, ...otherData }) => otherData); //remove cols we won't show

  return (
    <div style={{ overflow: "scroll" }}>
      {policyNames.length > 1 && (
        <div
          style={{
            padding: "24px 24px 0 24px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SubTitle>Policy to show:</SubTitle>
          <div style={{ width: "40%", marginLeft: 24 }}>
            <Select
              id="policyToShow"
              onChange={(e) => setPolicy(e.currentTarget.value)}
              options={policyNames}
              value={policy}
            />
          </div>
        </div>
      )}
      <DataOutputTable data={dataToShow} numberRowsToShow={maxNumToShow} />
    </div>
  );
}

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #57585c;
`;
