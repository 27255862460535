import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import styled from "@emotion/styled";
import { makeStyles, Snackbar } from "@material-ui/core";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { NotReallyALink, TopLink } from "UI/components/Anchor";
import { useAuthUser } from "App";
import logo from "UI/images/decision-ai-logo.svg";
import breadcrumbSlash from "UI/images/breadcrumb_slash.svg";
import { withFirebase, useFirebase } from "firebaseHelpers";
import { pushFork } from "modelSlice/slice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTab-root": {
      minWidth: 40,
      color: "#0079EE",
      padding: 0,
      paddingLeft: 4,
      marginRight: 24,
    },
    "& .MuiTabs-root": {
      overflow: "visible",
    },
    "& .MuiTabs-indicator": {
      bottom: 12,
      backgroundColor: "#57585C",
    },
    "& .Mui-selected": {
      color: "#57585C",
    },
  },
  navWrapper: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 12,
      height: "auto",
    },
  },
  modelName: {
    color: "#57585C",
    fontWeight: 800,
    fontSize: 14,
    marginRight: 16,
  },

  disabledLink: {
    marginRight: "24px !important",
    fontSize: 14,
  },
  navLinks: {
    color: "#0079EE",
    textDecoration: "none",
    marginRight: "24px !important",
    fontSize: 14,
  },
  activeNavLinks: {
    color: "#57585C",
    borderBottom: "1px solid #57585C",
    paddingBottom: 4,
  },
}));

export const Top = (props) => {
  const history = useHistory();
  const currentUrl = window.location.pathname;
  const inExplore = currentUrl.includes("/explore/");
  const classes = useStyles();
  const title = useSelector((state) => state.model.model.decision);

  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const firebase = useFirebase();
  const [forkingToast, setForkingToast] = useState(false);
  const doFork = () => {
    dispatch(
      pushFork({
        firebase: firebase,
        userId: authUser.uid,
      })
    )
      .then(unwrapResult)
      .then((docRef) => {
        const path = "/model/" + docRef.id;
        history.push(path);
        setForkingToast(true);
      });
  };
  const modelIsExplorable = useSelector(
    (state) =>
      state.model.model &&
      (state.model.model.variables.length ||
        state.model.model.datasets?.some((ds) => ds.variables.length))
  );

  return (
    <LinksContainer>
      <Link to="/">
        <Img src={logo} alt="logo" />
      </Link>
      <Nav>
        <div className={classes.navWrapper}>
          <Img src={breadcrumbSlash} alt="" />
          <span className={classes.modelName}>
            {title || "Unnamed Simulation"}
          </span>
          <Img src={breadcrumbSlash} alt="" />

          <NavLink
            to={inExplore ? currentUrl.replace("/explore", "") : currentUrl}
            exact
            onClick={(e) => history.push(e.target.value)}
            activeClassName={classes.activeNavLinks}
            className={classes.navLinks}
          >
            Build
          </NavLink>
          {modelIsExplorable ? (
            <>
              <NavLink
                to={inExplore ? currentUrl : "/explore" + currentUrl}
                activeClassName={classes.activeNavLinks}
                className={classes.navLinks}
              >
                Explore
              </NavLink>
              <NotReallyALink onClick={doFork}>Duplicate</NotReallyALink>
              <Snackbar
                open={forkingToast}
                autoHideDuration={5000}
                message="Simulation duplicated."
                onClose={() => {
                  setForkingToast(false);
                }}
              />
            </>
          ) : (
            <>
              <span className={classes.disabledLink}>Explore</span>
              <span className={classes.disabledLink}>Duplicate</span>
            </>
          )}
        </div>
      </Nav>
      <Container>
        <TopLink to={"/"}>New Simulation</TopLink>
        <TopLink to={"/models"}>Your Simulations</TopLink>
        <a
          style={{ textDecoration: "none" }}
          href={"https://docs.decision.ai/main.html"}
          target={"_blank"}
        >
          Documentation
        </a>

        <SignOutLink />
      </Container>
    </LinksContainer>
  );
};

const SignOutLinkBase = ({ firebase }) => (
  <NotReallyALink
    onClick={() => {
      firebase.doSignOut();
      window.location.pathname = "/";
    }}
  >
    Sign Out
  </NotReallyALink>
);

const SignOutLink = withFirebase(SignOutLinkBase);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  a {
    margin-left: 12px;
    background-color: transparent;
    color: #0079ee;
    line-height: 16px;
    text-align: right;
    color: #0079ee;
  }
  span {
    margin-right: 8px;
  }
`;

const Img = styled.img`
  width: auto;
  height: 32px;
  margin-right: 16px;
`;

const LinksContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 32px;
  align-items: center;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  a {
    margin-right: 12px;
  }
`;
