import React from "react";
import styled from "@emotion/styled";

export const TableData = (props) => {
  return (
    <StyledTableData width={props.width}>{props.children}</StyledTableData>
  );
};

const StyledTableData = styled.td`
  box-sizing: border-box;
  width: ${(props) => props.width};
  height: "36";
  overflow: "hidden";
  border-radius: 2px;

  .error {
    background-color: lightpink;
  }
`;
