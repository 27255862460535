import { configureStore } from '@reduxjs/toolkit';

import { ModelState } from 'modelSlice/state';
import { reducer } from 'modelSlice/slice';

export interface RootState {
  model: ModelState;
}

export default configureStore({
  reducer: {
    model: reducer,
  },
});
