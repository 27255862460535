import React, { useState } from "react";
import whiteLogo from "../../UI/images/white-logo.png";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import PasswordForgetModal from "./ForgotPasswordModal";
import backgroundLogo from "../../UI/images/dai-big-background.svg";
import styled from "@emotion/styled";

enum UserAction {
  SignIn,
  SignUp,
  ResetPassword,
}

const AuthPage = () => {
  const [modalType, setModalType] = useState<UserAction>(UserAction.SignIn);
  const switchToSignIn = () => setModalType(UserAction.SignIn);
  const switchToSignUp = () => setModalType(UserAction.SignUp);
  const switchToResetPassword = () => setModalType(UserAction.ResetPassword);
  return (
    <div
      style={{
        backgroundColor: "#0079EE",
        height: "100vh",
        minHeight: "100vh",
        width: "100%",
        backgroundImage: `url(${backgroundLogo})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "140% 0%",
        backgroundSize: "80%",
      }}
    >
      <Img src={whiteLogo} alt="logo" />

      {modalType == UserAction.SignIn ? (
        <SignInModal
          switchToSignUp={switchToSignUp}
          switchToResetPassword={switchToResetPassword}
        />
      ) : modalType == UserAction.SignUp ? (
        <SignUpModal
          switchToSignIn={switchToSignIn}
          switchToResetPassword={switchToResetPassword}
        />
      ) : (
        <PasswordForgetModal
          switchToSignIn={switchToSignIn}
          switchToSignUp={switchToSignUp}
        />
      )}
    </div>
  );
};

export default AuthPage;

const Img = styled.img`
  float: left;
  width: auto;
  height: 32px;
  margin-top: 24px;
  margin-left: 24px;
`;
