import KeenTracking from 'keen-tracking';

import { rollbarError } from 'RollbarInit';

const client = new KeenTracking({
    projectId: '5fabf8850207e92f58f4f4af',
    writeKey: '62207babe43139bb7233d1dc8c1e332350e15a7bdab8bd28bf61fc28e71a5df33f51213908b9ddceee00230bf4eeea2ff015d2f26a468df619aa8074a26f80e192ddf6c8e965d652188814aa827fafc3dcf63357ec28931e421e4aded3eb10c2'
});

const recordEvent = (eventName: string, meta: object) => {
  const eventMeta = {
    env: process.env.NODE_ENV, // production or development
    ...meta,
  };
  console.debug('Recording event', eventName, eventMeta);
  client.recordEvent(eventName, eventMeta)
  .catch( (err) => {
    console.error(err);
    rollbarError(err);
  });
};
export default recordEvent;

