import React from "react";
import {
  createStyles,
  makeStyles,
  FormLabel,
  FormControl,
} from "@material-ui/core";

import Select from "UI/components/Select";
import { TimePeriodSlider } from "./TimePeriodSlider";

export type ConfidenceRegion = null | [number, number];
type ConfidenceOption = {
  label: string;
  value: ConfidenceRegion;
};
const CONFIDENCE_OPTIONS: ConfidenceOption[] = [
  {
    label: "Off",
    value: null,
  },
  {
    label: "25–75%",
    value: [25, 75],
  },
  {
    label: "10–90%",
    value: [10, 90],
  },
];

type EventValueHandler = (event: any, value: any) => void;
type Props = {
  timeRange: [number, number];
  numPeriods: number;
  handleTimeMouseUp: EventValueHandler;
  confidenceRegion: ConfidenceRegion;
  setConfidenceRegion: (newVal: ConfidenceRegion) => void;
};
/* Form controls for setting time range and confidence region
 */
const TimeSeriesControls = (props: Props) => {
  const classes = useStyles();
  // index of selected confidence region option
  const confidenceOptionIndex = CONFIDENCE_OPTIONS.findIndex((opt) => {
    if (opt.value === null || props.confidenceRegion === null) {
      return opt.value === props.confidenceRegion;
    } else {
      return (
        opt.value[0] === props.confidenceRegion[0] &&
        opt.value[1] === props.confidenceRegion[1]
      );
    }
  });
  return (
    <div className={classes.root}>
      <FormControl className={classes.timeControl}>
        <FormLabel className={classes.label}>Time Periods</FormLabel>
        <TimePeriodSlider
          timeRange={props.timeRange}
          numPeriods={props.numPeriods}
          handleTimeMouseUp={props.handleTimeMouseUp}
        />
      </FormControl>

      <FormControl className={classes.confidenceControl}>
        <FormLabel className={classes.label}>Confidence Region</FormLabel>
        <Select
          options={[0, 1, 2]}
          value={confidenceOptionIndex}
          onChange={(e) => {
            const ix = e.currentTarget.value;
            props.setConfidenceRegion(CONFIDENCE_OPTIONS[ix].value);
          }}
          labels={CONFIDENCE_OPTIONS.map((opt) => opt.label)}
        />
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      marginTop: "1em",
    },
    label: {
      color: "#57585C",
      fontSize: 16,
      paddingBottom: ".5em",
      minWidth: "150px",
    },
    timeControl: {
      width: "60%",
      marginRight: "8%",
    },
    confidenceControl: {},
  })
);

export default TimeSeriesControls;
