import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Snackbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { ErrorBoundary } from "../../../../ErrorBoundary";
import { getCsvSummary } from "./csvTools";
import { useFirebase } from "firebaseHelpers";
import "./FileDropzone.css";

const handleFileUpload = (
  fileContent,
  forDataset,
  fileExtension,
  metadata,
  setMetadata,
  firebase,
  modelId,
  fileLabel
) => {
  const fname = [fileLabel, modelId, Date.now()].join("_");

  const firebaseDir = forDataset ? "userFiles/" : "userExternalModelFiles/";

  var fileRef = firebase.storage
    .ref()
    .child(firebaseDir + fname + "." + fileExtension);
  const uploadTask = forDataset
    ? fileRef.putString(fileContent)
    : fileRef.put(fileContent);

  uploadTask.on(
    "state_changed",
    function (snapshot) {
      // actions to take while uploading. e.g. Identify paused uploads
    },
    function (error) {
      // TODO: Add better error handling
      //https://firebase.google.com/docs/storage/web/upload-files
      console.log("Upload failed");
    },
    function () {
      // handle successful upload
      const fileSummary = forDataset ? getCsvSummary(fileContent) : [];

      const labels = metadata.map((i) => i.label);
      const indexToUpdate = labels.indexOf(fileLabel);
      const isNewItem = indexToUpdate === -1;
      const newMetadataValue = [...metadata];

      //uri and objectPath store array of all values ever used, with newest first
      const newUriArray = isNewItem
        ? [uploadTask.snapshot.ref.toString()]
        : [uploadTask.snapshot.ref.toString(), ...metadata[indexToUpdate].uri];

      const newObjectPathArray = isNewItem
        ? [uploadTask.snapshot.ref.fullPath]
        : [
            uploadTask.snapshot.ref.fullPath,
            ...metadata[indexToUpdate].objectPath,
          ];

      const newItemMetadata = {
        label: fileLabel,
        uri: newUriArray,
        objectPath: newObjectPathArray,
        ...fileSummary,
      };

      if (forDataset) {
        if (isNewItem) {
          newItemMetadata["variables"] = [];
        } else {
          newItemMetadata["variables"] = metadata[indexToUpdate]["variables"];
        }
      }

      if (isNewItem) {
        newMetadataValue.push(newItemMetadata);
      } else {
        newMetadataValue[indexToUpdate] = newItemMetadata;
      }
      setMetadata(newMetadataValue);
    }
  );
};

interface Props {
  modelId: string;
  label: string;
  fileType: string;
  metadata: any;
  setMetadata: any;
}
export const FileDropzone = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string|null>(null);

  const forDataset = props.fileType === "dataset";
  const metadata = props.metadata;
  const setMetadata = props.setMetadata;
  const firebase = useFirebase();

  const openModal = () => {
    if (!props.label.length) {
      return setError("Input file label to continue.");
    }
    setOpen(true);
  };

  const onDrop = (accceptedFiles) => {
    accceptedFiles.forEach((file) => {
      var reader = new FileReader();
      const fileExtension = file.path.split(".")[
        file.path.split(".").length - 1
      ];
      if (forDataset) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const fileContent = reader.result;
        handleFileUpload(
          fileContent,
          forDataset,
          fileExtension,
          metadata,
          setMetadata,
          firebase,
          props.modelId,
          props.label
        );
      };
    });
  };

  return (
    <>
      <Button
        onClick={openModal}
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          textTransform: "none",
          color: "#0079EE",
        }}
        variant="contained"
        endIcon={<CloudUploadIcon color="primary" />}
      >
        Upload
      </Button>
      <ErrorBoundary>
        <DropzoneDialog
          acceptedFiles={
            forDataset ? ["text/csv", "application/vnd.ms-excel"] : [""]
          }
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          filesLimit={1}
          dropzoneClass={"border-primary"}
          maxFileSize={10000000}
          dialogTitle={""}
          open={open}
          showPreviews={false}
          showPreviewsInDropzone={false}
          dropzoneText={"Drop file here or click to browse"}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            onDrop(files);
            setOpen(false);
          }}
        />
      </ErrorBoundary>

      {error && (
        <Snackbar
          open
          autoHideDuration={2000}
          onClose={() => setError(null)}
          message={error}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      )}
    </>
  );
};
