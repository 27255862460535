import React, { Fragment, useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Fab, LinearProgress as LoadingIndicator } from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";

import { Card } from "UI/components/Card";
import Title from "UI/components/Title";
import Wrapper from "../../../UI/Wrapper";
import GraphsSection from "./GraphsSection";
import { Leaderboard } from "./Leaderboard";
import { varWithAggToName } from "formatters";
import ScenarioCreator from "./Scenario/ScenarioCreator";
import { getSummaryStats } from "../../Charting/TimeSeriesCharts";
import { ConfidenceRegion } from "../../Charting/TimeSeriesControls";
import AdvancedOptionsDrawer from "./AdvancedOptionsDrawer";
import { EXPLORE_NUM_SIMS } from "api/constants";
import DrilldownLeaderboard from "./DrilldownLeaderboard";
import {
  getAggsForExplorer,
  flattenMainVarCollections,
} from "./Scenario/aggAndFilterLogic";
import { actions } from "modelSlice";
import useDrilldownApi from "api/useDrilldownApi";
import { OutcomeSelectionControls, DrilldownControls } from "./Aggregators";

export function Explorer(props) {
  const mainVariables = props.variables;
  const mainVariableNames = mainVariables.map(
    (variable) => variable.short_name
  );
  const policies = props.policies;
  const granularity = props.granularity;
  const datasets = props.datasets;
  const numPeriods = props.numPeriods;
  const setSelectedPolicies = props.setSelectedPolicies;
  const filters = props.filters;
  const filtersSetter = props.filtersSetter;
  const mainVarCollections = props.mainVarCollections;
  // Variables / aggregations shown in drilldown
  const dsVarCollections = props.dsVarCollections;
  // may be nullish if we're in the middle of API requests
  const simResults = props.simResults;
  // number from 0-1 indicating loading progress
  const apiProgress = props.apiProgress;
  const simResultsLoading = props.simResults === null;
  const [drillData, drillDataLoading] = useDrilldownApi(props.backendUrl);

  // TODO: do something sensible when policies are deleted
  const selectedPolicies =
    props.selectedPolicies === null
      ? policies.policyNames.map((p, i) => i)
      : props.selectedPolicies;

  // TODO: Lots of messy/overlapping logic here that should probably be encapsulated
  // in a dedicated component for just the drilldown UI.
  const eligibleForDrilldown = useMemo(
    () => datasets.some((ds) => ds.variables.length),
    [datasets]
  );
  let ddKeyCol;
  if (dsVarCollections?.length) {
    const config = dsVarCollections[0];
    const matchingDs = datasets.find((ds) => ds.label === config.dataset);
    if (!matchingDs) {
      // Not a cause for concern - config.dataset may be null for a newly
      // created dd config.
      console.log("No dataset found matching drilldown config:", config);
    } else {
      ddKeyCol = matchingDs.keyName;
    }
  }
  let drilldownRenderedResults = null;
  if (drillDataLoading) {
    drilldownRenderedResults = <LoadingIndicator />;
  } else if (drillData) {
    drilldownRenderedResults = (
      <Wrapper>
        <Title>Drilldown Results</Title>
        <Card>
          <DrilldownLeaderboard
            policies={policies}
            selectedPolicies={selectedPolicies}
            drillData={drillData}
            config={dsVarCollections[0]}
            keyColumn={ddKeyCol}
          />
        </Card>
      </Wrapper>
    );
  }

  const [showTimeSeries, setShowTimeSeries] = useState(false);
  const [explorerTsGraphRange, setExplorerTsGraphRange] = useState([
    1,
    numPeriods,
  ]);

  const handleTsRangeMouseUp = (event, newValue) => {
    setExplorerTsGraphRange([newValue[0], newValue[1]]);
  };

  const [confidenceRegion, setConfidenceRegion] = useState<ConfidenceRegion>(null);

  const mainVarsWithAggSettings = flattenMainVarCollections(mainVarCollections);

  const mainLongNames = mainVarsWithAggSettings.map(
    (v) => varWithAggToName(v) //, granularity)
  );

  let countsByPolicyId,
    mainFilteredData,
    mainHistogramData,
    mainLeaderboardData,
    mainTimeSeriesData;

  if (!simResultsLoading) {
    [
      countsByPolicyId,
      mainFilteredData,
      mainHistogramData,
      mainLeaderboardData,
    ] = getAggsForExplorer(
      filters,
      simResults,
      policies.policyNames,
      selectedPolicies,
      mainLongNames,
      EXPLORE_NUM_SIMS,
      mainVarsWithAggSettings
    );

    const makeTimeSeriesData = () => {
      const timeSeriesDataAllPolicies = getSummaryStats(
        mainFilteredData,
        policies,
        mainVarsWithAggSettings.map((v) => v.varName),
        explorerTsGraphRange[0],
        explorerTsGraphRange[1],
        confidenceRegion,
      );

      return timeSeriesDataAllPolicies.filter(
        (row) =>
          policies.length === 0 ||
          selectedPolicies === null ||
          selectedPolicies.includes(row.policyId)
      );
    };

    mainTimeSeriesData = showTimeSeries ? makeTimeSeriesData() : [];
  }

  const dispatch = useDispatch();

  const setMainVarCollectionParam = (ix) => (key, val) => {
    dispatch(
      actions.setMainVarCollectionParam({
        i: ix,
        key,
        val,
      })
    );
  };
  const setDsVarCollectionParam = (ix) => (key, val) => {
    dispatch(
      actions.setDsVarCollectionParam({
        i: ix,
        key,
        val,
      })
    );
  };

  const newDrilldown = () => dispatch(actions.newDrilldownConfig());
  const newOutcomeSelection = () => {
    dispatch(actions.newOutcomeSelection());
  };

  return (
    <div style={{ padding: "0 24px" }}>
      <Wrapper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Title style={{ paddingTop: 4 }}>Outcome Selection</Title>
          </div>
          <AdvancedOptionsDrawer
            numPeriods={numPeriods}
            explorerTsGraphRange={explorerTsGraphRange}
            handleTsRangeMouseUp={handleTsRangeMouseUp}
            confidenceRegion={confidenceRegion}
            setConfidenceRegion={setConfidenceRegion}
            downloadSimResults={props.downloadSimResults}
            decision={props.decision}
            showTimeSeries={showTimeSeries}
            setShowTimeSeries={setShowTimeSeries}
          />
        </div>

        <Card>
          <div style={{ padding: 12 }}>
            {mainVarCollections.map((varCollection, i) => (
              <Fragment key={"mainVarCollection" + String(i)}>
                <OutcomeSelectionControls
                  config={varCollection}
                  setter={setMainVarCollectionParam(i)}
                  deleter={() =>
                    dispatch(actions.deleteOutcomeSelectionConfig(i))
                  }
                />
              </Fragment>
            ))}
            <AddButton
              aria-label="add"
              onClick={newOutcomeSelection}
              size={"small"}
            >
              <AddIcon />
            </AddButton>
          </div>
        </Card>
      </Wrapper>
      {mainVarCollections
        .map((vc) => vc.variableNames)
        .filter((vc) => vc.length > 0).length > 0 && (
        <Wrapper>
          <Title>Outcome Results</Title>
          <Card>
            {simResultsLoading ? (
              <LoadingIndicator
                variant="determinate"
                value={apiProgress * 100}
              />
            ) : (
              <>
                <Leaderboard
                  data={mainLeaderboardData}
                  longNames={mainLongNames}
                  selectedPolicies={selectedPolicies}
                  setSelectedPolicies={setSelectedPolicies}
                />
                <GraphsSection
                  policies={policies}
                  histogramData={mainHistogramData}
                  timeSeriesData={mainTimeSeriesData}
                  showTimeSeries={showTimeSeries}
                  confidenceRegion={confidenceRegion}
                  granularity={granularity}
                  longNames={mainLongNames}
                  rawVarNames={mainVarsWithAggSettings.map((v) => v.varName)}
                />
              </>
            )}
          </Card>
        </Wrapper>
      )}

      {/* -------------------- DATASETS SECTION BELOW ------------------ */}

      {eligibleForDrilldown && (
        <Wrapper>
          <Title style={{ marginBottom: 0 }}>Drilldown Selection</Title>
          <Card>
            <div style={{ paddingTop: 8 }}>
              {dsVarCollections.map((varCollection, i) => (
                <Fragment key={"dsVarCollection" + String(i)}>
                  <DrilldownControls
                    config={varCollection}
                    setter={setDsVarCollectionParam(i)}
                    deleter={() => dispatch(actions.deleteDrilldownConfig(i))}
                  />
                </Fragment>
              ))}
              {/* Currently not allowing multiple ds var collecitons */}
              {/* Need to think about visualizing results before enabling that */}
              {dsVarCollections.length === 0 && (
                <AddButton
                  aria-label="add"
                  onClick={newDrilldown}
                  size={"small"}
                >
                  <AddIcon />
                </AddButton>
              )}
            </div>
          </Card>
        </Wrapper>
      )}
      {drilldownRenderedResults}
      <ScenarioCreator
        filters={filters}
        policies={policies}
        countsByPolicyId={countsByPolicyId}
        data={simResults}
        filtersSetter={filtersSetter}
        confidenceRegion={confidenceRegion}
        variableNames={mainVariableNames}
        periodType={granularity}
        numPeriods={numPeriods}
      />
    </div>
  );
}

export const AddButton = styled(Fab)`
  background-color: #0079ee;
  color: #fff;
  &:hover {
    text-decoration: none;
    background-color: #3f93f5;
  }
`;
