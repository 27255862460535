import React from "react";
import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  exampleAnchor: {
    marginLeft: "12px",
    marginRight: "4px",
    border: "none",
    textDecoration: "none",
    textAlign: "right",
    color: "#0079ee",
  },
  notReallyALink: {
    border: "none",
    cursor: "pointer",
    color: "#0079ee",
    textDecoration: "capitalize",
  },
  topLink: {
    textDecoration: "none",
  },
}));

export const ExampleAnchor = (props) => {
  const classes = useStyles();
  return (
    <NavLink
      to={props.href}
      className={classes.exampleAnchor}
      activeStyle={{ color: "#000000" }}
    >
      {props.children}
    </NavLink>
  );
};

export const NotReallyALink = (props) => {
  const classes = useStyles();
  return (
    <a className={classes.notReallyALink} {...props}>
      {props.children}
    </a>
  );
};

export const TopLink = (props) => {
  const classes = useStyles();
  return (
    <NavLink className={classes.topLink} {...props}>
      {props.children}
    </NavLink>
  );
};
