import React, { Component } from "react";
import { withFirebase } from "firebaseHelpers";
import { FlexWrapper, GoogleSignInButton, BaseButton } from "./Styles";
import StyledInput from "../../UI/components/StyledInput";

type State = {
  email: string;
  password: string;
  error: null | { message: string };
};
type Props = {
  firebase: any;
  closeModal: Function;
};
const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};
class SignInFormBase extends Component<Props, State> {
  state = INITIAL_STATE;

  onSubmit = (event) => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = (event) => {
    // @ts-ignore
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <form onSubmit={this.onSubmit}>
        <FlexWrapper>
          <p>Email:</p>
          <StyledInput
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </FlexWrapper>
        <FlexWrapper>
          <p>Password:</p>
          <StyledInput
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </FlexWrapper>

        <BaseButton disabled={isInvalid} type="submit">
          Sign In
        </BaseButton>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInGoogleBase extends Component<
  Props,
  { error: null | { message: string } }
> {
  state = { error: null };

  onSubmit = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        this.setState({ error: null });
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  render() {
    const { error } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <GoogleSignInButton type="submit">
          Sign In with Google
        </GoogleSignInButton>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInGoogle = withFirebase(SignInGoogleBase);

const SignInForm = withFirebase(SignInFormBase);

export { SignInForm, SignInGoogle };
