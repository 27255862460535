import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "firebaseHelpers";
import styled from "@emotion/styled";
import StyledInput from "../../UI/components/StyledInput";
import { BaseButton } from "./Styles";

const INITIAL_STATE = {
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class SignUpFormBase extends Component<any, typeof INITIAL_STATE> {
  state = INITIAL_STATE;

  onSubmit = (event) => {
    const { email, passwordOne } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push("/");
        this.props.closeModal();
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    // TODO: Some dynamism going on here that's making us fight against the type
    // checker. Similar pattern in sibling components, e.g. in SignIn.tsx
    // @ts-ignore
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <FlexWrapper>
          <p>Email:</p>
          <StyledInput
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </FlexWrapper>
        <FlexWrapper>
          <p>Password: </p>
          <StyledInput
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
          />
        </FlexWrapper>
        <FlexWrapper>
          <p>Confirm Password:</p>
          <StyledInput
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm Password"
          />
        </FlexWrapper>
        <BaseButton disabled={isInvalid} type="submit">
          Sign Up
        </BaseButton>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export const SignUpForm = withRouter(withFirebase(SignUpFormBase));

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
