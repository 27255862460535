import React from "react";
import styled from "@emotion/styled";

import { formatVarName } from "formatters";

export const format_number = (num) => {
  if (num === undefined || num === null) {
    return "";
  }
  if (typeof num !== "number") {
    return num;
  }

  let candidate = null;
  const numDigits = 7;
  if (num > 10 ** numDigits) {
    candidate = num.toExponential(numDigits);
  } else if (Math.abs(num) < 10 ** -3) {
    candidate = num.toExponential(3);
  } else {
    const digitsAfterDecimal = Math.max(
      0,
      numDigits - Math.ceil(Math.log10(Math.abs(num)))
    );
    candidate = num.toFixed(Math.min(digitsAfterDecimal, 99));
  }
  const basic = num.toString();
  return candidate.length < basic.length ? candidate : basic;
};

function limitedRows(rows, limit) {
  if (Number.isInteger(limit)) {
    return rows.filter((_, i) => i < limit);
  }
  return rows;
}

type TableProps = {
  data: object[];
  numberRowsToShow: number;
  datasetVars?: any;
  prettifyColumns?: boolean;
};

/* Displays a sample of columnar data, such as a preview of dataset csv files,
  or simulation output.
*/
export function DataOutputTable({
  // Array of row objects, with each row having a key for each column.
  data,
  numberRowsToShow,
  datasetVars,
  // If true, then convert underscores to spaces and apply title case.
  // Otherwise, preserve formatting
  prettifyColumns = true,
}: TableProps) {
  const variables = data.length ? Object.keys(data[0]) : [];

  const userDefined = datasetVars && datasetVars[0];
  let index;
  if (userDefined) {
    index = variables.findIndex((v) => userDefined.short_name === v);
  }

  return (
    <Table>
      <tbody>
        <tr>
          {variables.map((variable, i) => (
            <TableHead
              key={variable}
              style={{ borderLeft: i === index ? "1px solid" : "" }}
            >
              {prettifyColumns ? formatVarName(variable) : variable}
            </TableHead>
          ))}
        </tr>
        {limitedRows(data, numberRowsToShow).map((row, rownum) => (
          <tr key={"row" + String(rownum)}>
            {variables.map((variable, i) =>
              row[variable] !== null ? (
                <TableBody
                  key={variable}
                  style={{ borderLeft: i === index ? "1px solid" : "" }}
                >
                  <div>{row[variable] && format_number(row[variable])}</div>
                </TableBody>
              ) : (
                <TableBody key={variable}>?</TableBody>
              )
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const TableHead = styled.th`
  flex: 1;
  color: #57585c;
  border: 1px solid #cdcdcf;
  border-collapse: collapse;
  padding: 12px 12px;
`;
const TableBody = styled.td`
  color: #57585c;
  border: 1px solid #cdcdcf;
  border-collapse: collapse;
  padding: 12px 12px;

  div {
  }
`;
const Table = styled.table`
  border-spacing: 0px;
  border-width: 1px;
  width: 100%;
  padding: 24px;
`;
