import React from "react";

import EnhancedTable from "./Table";
import {selectPolicyNames} from 'modelSlice/selectors';
import {useSelector} from "react-redux";

/* Displays tabular summary data corresponding to "OutcomeSelection" aggregations.
   Also includes affordances for setting selectedPolicies.
   Complemented by GraphsSection component which shows similar info graphically.
*/
interface LbProps {
  data: any[];
  longNames: string[];
  selectedPolicies: number[];
  setSelectedPolicies: Function;
}
export function Leaderboard(props: LbProps) {
  const policies = useSelector(selectPolicyNames);
  const longNames = props.longNames;

  if (!(policies.length)) {
    return null;
  }
  const rows = props.data.map((row) => ({
    ...row,
    option: policies[row.policyId],
  }));

  return (
    <EnhancedTable
      rows={rows}
      firstColumnName={"policyId"}
      // TODO: Change this from option to the name of the option. Need to do that in underlying data too
      firstColumnLabel={"option"}
      selected={props.selectedPolicies}
      setSelected={props.setSelectedPolicies}
      otherColumnNames={longNames}
    />
  );
}
