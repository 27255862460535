import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Raleway",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#0079EE",
    },
  },
});
