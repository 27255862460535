import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import TextareaAutosize from "react-textarea-autosize";

type Props = {
  // Formula string
  val: string;
  setVal: (formula: string) => void;
  className?: string;
  // TODO: This is not actually accurate. Object with multiple string[] values.
  typeAheadWords: object;
  onBlur: () => void;
};
export function FormulaEditor(props: Props) {
  // Array of typeahead choices
  const [choices, setChoices] = useState<string[]>([]);
  // Index of currently focused choice
  const [currentFocus, setCurrentFocus] = useState(0);
  // lexical token that cursor is currently on
  const [currentToken, setCurrentToken] = useState<string>();

  const val = props.val;
  const setVal = props.setVal;
  const words = props.typeAheadWords;
  const inp = useRef<HTMLInputElement>();

  const choose = useCallback(
    (ixChoice) => {
      console.log("choosing " + ixChoice);
      const chosen = choices[ixChoice];
      const toAppend = chosen.substr(currentToken.length);
      setVal(val + toAppend);
      setChoices([]);
    },
    [currentToken, choices, val, setVal]
  );

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 40) {
        // down
        e.preventDefault();
        setCurrentFocus((i) => (choices.length + i + 1) % choices.length);
      } else if (e.keyCode === 38) {
        // up
        e.preventDefault();
        setCurrentFocus((i) => (choices.length + i - 1) % choices.length);
      } else if (e.keyCode === 13) {
        // return key - choose!
        if (choices.length > 0) {
          e.preventDefault();
          choose(currentFocus);
        }
      }
    }
    const currInput = inp.current;
    currInput.addEventListener("keydown", handleKeyDown);
    return function cleanupListener() {
      currInput.removeEventListener("keydown", handleKeyDown);
    };
  }, [choices, currentFocus, choose]);

  function handleChange(event) {
    const newVal = event.target.value;
    setVal(newVal);
    const splitted = newVal.split(/\W+/);
    const last = splitted[splitted.length - 1];
    if (last.length > 0) {
      setChoices(
        [...words["modelPrimitives"], ...words["functions"]]
          .filter((w) => typeof w !== "undefined")
          .filter((w) => w.startsWith(last))
      );
      setCurrentToken(last);
    } else {
      setChoices([]);
    }
  }

  function pickColor(word) {
    if (words["functions"].includes(word)) {
      return "#C0CCF8";
    } else if (words["modelPrimitives"].includes(word)) {
      return "AliceBlue";
    } else {
      return null;
    }
  }

  const setStyled = (value = "") => {
    return {
      width: "100%",
      borderRadius: 2,
    };
  };

  return (
    <OuterContainer>
      <TextareaAutosize
        style={setStyled(val)}
        inputRef={(variable) => {
          inp.current = variable;
        }}
        value={val}
        onBlur={() => {
          setChoices([]);
          props.onBlur();
        }}
        className={props.className}
        onChange={handleChange}
      />

      {choices.length > 0 && (
        <ItemsContainer>
          {choices.map((choice, i) => (
            <div
              key={i}
              onMouseEnter={() => setCurrentFocus(i)}
              onMouseDown={(e) => {
                e.preventDefault();
                choose(i);
              }} // this works but "onClick" doesn't.
              style={{
                zIndex: 1000,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 4,
                backgroundColor:
                  currentFocus === i ? "lightgrey" : pickColor(choice),
              }}
            >
              {choice}
            </div>
          ))}
        </ItemsContainer>
      )}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const ItemsContainer = styled.div`
  position: absolute;
  border: 1px solid black;
  border-top: none;
  z-index: 99;
  top: 80%; /*slightly overlap the text entry cell:*/
  left: 1;
  right: 1;
  background-color: white;
`;
