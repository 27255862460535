import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    border: "1px solid #818285",
    borderRadius: "2px",
    textAlign: "center",
    height: "36px",
    minWidth: "160px",
    "& input": {
      paddingLeft: "8px",
      width: "100%",
      height: "100%",
      border: "none",
      boxSizing: "border-box",
      background: "inherit",
      textAlign: "start",
      fontFamily: "roboto-mono",
    },
    "& input:focus, & textarea:focus, & select:focus": {
      outline: "none"
    },
    "&.error": {
      background: "lightpink",
      border: "none",
    },
    "&.disabled": {
      background: "#efefef",
      border: "none",
    }
  }
}));

interface Props {
  className?: string,
  error?: boolean,
  disabled?: boolean,
}

const TableDataHeader: FunctionComponent<Props> = ({className, error, disabled, children}) => {
  const classes = useStyles();
  const classList = clsx(classes.root, className, {error, disabled});

  return (
    <td className={classList}>{children}</td>
  );
};

export default TableDataHeader;
