import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { DataOutputTable } from "../DataOutputTable";
import { parseCSV } from "../FileDropzone/csvTools";
import { VariablesTable } from "../VariablesEquations";
import {
  getSimulatedOutputData,
  getDatasetExpansionPanelResults,
} from "./dataViewUtils";
import Select from "UI/components/Select";
import { Excerpt } from "api/types";
import { useFirebase } from "firebaseHelpers";
import { RootState } from "store";
import { actions } from "modelSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px",
    backgroundColor: "#f5f5f5",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailsPanel: {
    overflow: "auto",
  },
}));

const PanelWrapper = (props) => {
  const classes = useStyles();
  return (
    <Accordion defaultExpanded={props.defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{props.headingText}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.detailsPanel}
        style={{ display: "block" }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

type Setter = (newValue: any) => void;
type PanelProps = {
  dataset: any;
  allDatasets: any;
  setDatasets: Setter;
  setShowEquationHelp: Setter;
  typeAheadWords: any;
  datasetResults: Excerpt;
  errors: any;
  policyIndex?: number;
};

/* The expandable panels ("Data", "User Defined Variables", "Simulated Output"),
  for one dataset.

  Data: should show excerpted rows from csv data, plus values of user defined variables for t=1 
        (the latter separated by a horizontal rule)
  User Defined Variables: table for entry, similar to the 'global' Variables table
  Simulated Output: For one selected row, show the values of user defined variables for
      the first few timesteps. Also a column for the row identifier (i.e. the first row
      of the csv).
*/
export default function DatasetExpansionPanel({
  dataset,
  allDatasets,
  setDatasets,
  setShowEquationHelp,
  typeAheadWords,
  datasetResults,
  errors,
  policyIndex,
}: PanelProps) {
  const firebase = useFirebase();
  const classes = useStyles();
  // index of selected row to show in preview
  const [rowIndex, setRowIndex] = useState(0);
  type csvRow = object;
  const [fileData, setFileData] = useState([]); // useState<csvRow[]>([]);
  const timeRangeStart = useSelector(
    (state: RootState) => state.model.model.timeRange[0]
  );
  const dispatch = useDispatch();

  const numRowsToShow = 5;
  useEffect(() => {
    const gsReference = firebase.storage.refFromURL(dataset.uri[0]);
    gsReference
      .getDownloadURL()
      .then((url) => {
        axios({ method: "get", responseType: "blob", url })
          .then((response) => {
            const fileReader = new FileReader();
            fileReader.readAsText(response.data);
            fileReader.onload = () => {
              setFileData(parseCSV(fileReader.result as string));
            };
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => console.log(error));
  }, [dataset, firebase.storage]);

  const thisDatasetVars = dataset.hasOwnProperty("variables")
    ? dataset.variables
    : [];

  const setThisDatasetVars = (variables) => {
    const thisDatasetIndex = allDatasets.findIndex(
      (ds) => ds.label === dataset.label
    );
    dispatch(
      actions.setDatasetVariables({
        vars: variables,
        datasetIndex: thisDatasetIndex,
      })
    );
  };
  if (fileData.length === 0) {
    return <div />;
  }
  const dataTableContents = getDatasetExpansionPanelResults(
    datasetResults,
    fileData,
    timeRangeStart,
    numRowsToShow,
    policyIndex
  );
  // Name of the column we're using for row identifiers (the first column)
  const labelColumn: string = Object.keys(fileData[0])[0];
  const rowLabels: string[] = fileData.map((row) =>
    Object.values(row)[0].toString()
  );
  const rowIndices: number[] = [...Array(rowLabels.length).keys()];

  const newTypeAheadWords = { ...typeAheadWords };
  newTypeAheadWords["modelPrimitives"] = [
    ...typeAheadWords.modelPrimitives,
    ...dataset.columnNames,
  ];

  return (
    <div className={classes.root}>
      <PanelWrapper headingText={"Data"}>
        <DataOutputTable
          data={dataTableContents}
          numberRowsToShow={numRowsToShow}
          prettifyColumns={false}
          datasetVars={thisDatasetVars}
        />
      </PanelWrapper>
      <PanelWrapper
        headingText={"User Defined Variables"}
        defaultExpanded={true}
      >
        <VariablesTable
          setVariables={setThisDatasetVars}
          errors={errors}
          serverError={false}
          variables={thisDatasetVars}
          typeAheadWords={newTypeAheadWords}
          setShowEquationHelp={setShowEquationHelp}
        />
      </PanelWrapper>

      {datasetResults?.length > 0 && (
        <PanelWrapper headingText={"Simulated Output"}>
          <div>
            <div
              style={{
                paddingLeft: "12px",
                paddingBottom: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SubTitle>{labelColumn}: </SubTitle>
              <Select
                id="keyToShow"
                onChange={(e) =>
                  setRowIndex(parseInt(e.currentTarget.value, 10))
                }
                labels={rowLabels}
                options={rowIndices}
                value={rowIndex}
              />
            </div>
            <DataOutputTable
              data={getSimulatedOutputData(
                datasetResults,
                rowIndex,
                policyIndex
              )}
              numberRowsToShow={numRowsToShow}
              datasetVars={[]}
            />
          </div>
        </PanelWrapper>
      )}
    </div>
  );
}

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-transform: capitalize;
`;
