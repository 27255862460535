import React, { useMemo, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import $ from "jquery";
import styled from "@emotion/styled";

import { TrashCan, DragHandle, UpDownArrows } from "UI/components/Icons";
import TableWrapper from "./variableTable/TableWrapper";
import LabelTable from "./variableTable/TableLabel";
import { FormulaEditor } from "./FormulaEditor";
import { format_errors } from "../../../errors";
import { isEmpty } from "../../../equals";
import { Variable } from "types";
import { TableData } from "./variableTable/TableData";

const toValidIdentifier = (s) => s.replace(/[\W_]+/g, "_").toLowerCase();

const FORMATTERS = {
  short_name: toValidIdentifier,
  // We lowercased formulas before adding datasets, but those are upper case. Now doing nothing with them
  equation: (s) => s,
  initial: (s) => s,
  intentional_short_name: (s) => s,
};

export const VARIABLE_TABLE_WIDTHS = {
  trash_icon: "1%",
  short_name: "24%",
  equation: "60%",
  initial: "15%",
};

type Props = {
  variables: Variable[];
  setVariables: Function;
  errors: any;
  serverError: boolean;
  typeAheadWords: string[];
  setShowEquationHelp: Function;
};
/* A table for editing variable definitions. Used for both top-level variables
   and Dataset-specific variables.
*/
export function VariablesTable(props: Props) {
  const { setVariables, variables, serverError, setShowEquationHelp } = props;
  // Whether we're in the middle of dragging a row
  const [dragging, setDragging] = useState(false);
  const [dragTarget, setDragTarget] = useState<(number|null)>(null);
  // A local copy of variables provided via props. We do mutations on these and then
  // sync them to redux when appropriate.
  const [inputVariables, setInputVariables] = useState<Variable[]>(variables);
  useEffect(() => {
    setInputVariables(variables);
  }, [variables]);
  const setVariablesToTempVariables = () => {
    setVariables(inputVariables);
  };
  const inputVariablesWithExtra: Variable[] = useMemo(
    () => [...inputVariables, { short_name: "", equation: "" }],
    [inputVariables]
  );

  /* Make a local copy of props.errors. Currently when the user edits any cell,
     we nuke our record of errors (presumably to avoid the scenario where we
     seem to be nagging them about an issue they've already fixed). A more nuanced
     approach might be to annotate a particular cell with info about an error in
     props.errors only if our local data for the corresponding variable matches
     with props.variables.
   */
  const [errors, setErrors] = useState(props.errors);
  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const setValue = (i: number, val, key: keyof Variable) => {
    const old = inputVariables[i];
    const newVal = FORMATTERS[key](val);
    const newVariable = { ...old, [key]: newVal };

    if (key !== "equation" && newVariable.short_name && !newVariable.equation) {
      newVariable.equation = "0";
    }

    let newVariables = [...inputVariables];
    newVariables[i] = newVariable;
    setInputVariables(newVariables);
  };

  useEffect(() => {
    let textarea = $(".okay");
    let rowsNums = textarea.length / 3;
    for (let i = 0; i < rowsNums; i++) {
      let greaterHeight = 0;
      let rowElements = $(".row-" + i);
      for (let j = 0; j < rowElements.length; j++) {
        if ($(rowElements[j]).outerHeight() > greaterHeight) {
          greaterHeight = $(rowElements[j]).outerHeight();
        }
      }
      for (let j = 0; j < rowElements.length; j++) {
        $(rowElements[j]).outerHeight(greaterHeight);
      }
    }
    const getClass = (subString, source) => {
      let selectedClass = "";
      for (let i = 0; i < source.classList.length; i++) {
        if (source.classList[i].includes(subString)) {
          selectedClass = source.classList[i];
          return selectedClass;
        }
      }
    };

    const textareaResize = function (source) {
      // Equation cells can expand vertically to show the full equation. That's provided by some external component.
      // This resizing code keeps all cells in a given row aligned (same height)

      // TODO: Expanding a cell in row1 of any variables table currently causes a bug where row1 in all other variables tables
      // also expand (e.g. variables tables for other datasets). Fix that by passing something here that modifies the className
      // for a row to be specific to each variable
      if (!source) {
        return;
      }
      let row = getClass("row-", source);

      let dests = $("." + row);
      const resizeEvent = function () {
        for (let i = 0; i < dests.length; i++) {
          if (dests[i]) {
            $(dests[i]).outerHeight($(source).outerHeight());
          }
        }
      };
      resizeEvent();
    };

    for (let i = 0; i < textarea.length; i++) {
      textareaResize(textarea[i]);
    }
  });

  const removeVariable = (i: number) => {
    const newVariables = inputVariables
      .slice(0, i)
      .concat(inputVariables.slice(i + 1));
    setVariables(newVariables);
  };

  const reorderVariable = (srcIndex: number, destIndex: number) => {
    console.log(`Reordering ${srcIndex} => ${destIndex}`);
    const newVars = [...inputVariables];
    const [popped] = newVars.splice(srcIndex, 1);
    const dest = srcIndex < destIndex ? destIndex - 1 : destIndex;
    newVars.splice(dest, 0, popped);
    setInputVariables(newVars);
    setVariables(newVars);
  };

  return (
    <div>
      <TableWrapper>
        <tbody>
          <tr>
            <td>
              <strong></strong>
            </td>
            <LabelTable className={"cell"}>
              <p>Name</p>
            </LabelTable>

            <LabelTable className={"cell"}>
              <p>
                Formula
                <PseudoLink
                  onClick={() => setShowEquationHelp(true)}
                  style={{ fontWeight: "normal", marginLeft: 12 }}
                >
                  See Examples
                </PseudoLink>
              </p>
            </LabelTable>
            
            <LabelTable className={"cell"}>
              <p>Initial Value</p>
            </LabelTable>

            <LabelTable className={"cell"}>
              <UpDownArrows />
            </LabelTable>
          </tr>

          { /* TODO: May be able to do something more clever with keys to better handle
              reordering use case.
          */}
          {inputVariablesWithExtra.map((variable, i) => (
          <React.Fragment key={i}>
            { /* TODO: move some of this event logic to Interstice component? */}
            <RowInterstice
              onDragEnter={(e) => {
                e.preventDefault();
                console.log("Got dragEnter on i=", i, " dropEffect=", e.dataTransfer.dropEffect);
                e.dataTransfer.dropEffect = 'move';
                setDragTarget(i);
              }}
              onDragOver={(e) => {
                // For some reason if we don't attach this handler we don't get 
                // drop events...
                // TODO: for some reason we're seeing dropEffect=none here despite
                // trying to set it to 'move' in dragEnter handler...
                //console.log(" dropEffect=", e.dataTransfer.dropEffect);
                e.dataTransfer.dropEffect = 'move';
                e.preventDefault();
              }}
              onDragLeave={(e) => {
                console.log("Got dragLeave on i=", i, " dropEffect=", e.dataTransfer.dropEffect);
                // Actually not sure if this is necessary. I *think* browser
                // will automatically set this to none when entering/overing 
                // another element without drag handlers set up...
                e.dataTransfer.dropEffect = 'none';
                setDragTarget(null);
              }}
              onDrop={(e) => {
                console.log("Got drop on i=", i);
                e.preventDefault();
                // TODO: verify tableId match
                const srcIndex = Number(e.dataTransfer.getData('rowIndex'));
                reorderVariable(srcIndex, i);
                // handled in dragend event
                //setDragging(false);
              }}
              dragging={dragging}
              active={dragTarget === i}
            />
            <tr>
              {i < inputVariables.length ? (
                <td
                  onClick={(e) => removeVariable(i)}
                  width={VARIABLE_TABLE_WIDTHS.trash_icon}
                >
                  <TrashCan />
                </td>
              ) : (
                <td width={VARIABLE_TABLE_WIDTHS.trash_icon} />
              )}
              <TableData width={VARIABLE_TABLE_WIDTHS.short_name}>
                <VarNameCell
                  className={
                    (errors?.[variable.short_name]?.["short_name"]
                      ? "error"
                      : "okay") +
                    " cell" +
                    " col-" +
                    "short_name" +
                    " row-" +
                    i
                  }
                  type={"text"}
                  value={variable["short_name"]}
                  onChange={(e) => {
                    setErrors({});
                    setValue(i, e.target.value, "short_name");
                  }}
                  onBlur={() => {
                    setVariablesToTempVariables();
                  }}
                />
              </TableData>
              <TableData width={VARIABLE_TABLE_WIDTHS.equation}>
                <FormulaCell
                  typeAheadWords={props.typeAheadWords}
                  val={variable["equation"]}
                  setVal={(val) => {
                    setErrors({});
                    setValue(i, val, "equation");
                  }}
                  className={
                    (errors?.[variable.short_name]?.equation
                      ? "error"
                      : "okay") +
                    " cell" +
                    " col-" +
                    "equation" +
                    " row-" +
                    i
                  }
                  onBlur={() => {
                    setVariablesToTempVariables();
                  }}
                />
              </TableData>
              <TableData width={VARIABLE_TABLE_WIDTHS.initial}>
                <InitialValueCell
                  disabled={
                    variable["initial"] === undefined &&
                    !(
                      errors?.[variable.short_name] &&
                      errors[variable.short_name]["initial"] &&
                      errors[variable.short_name]["initial"][0]
                    )
                  }
                  className={
                    (errors?.[variable.short_name]?.initial
                      ? "error"
                      : "okay") +
                    " cell" +
                    " col-" +
                    "initial" +
                    " row-" +
                    i
                  }
                  type={"text"}
                  value={variable["initial"] || ""}
                  onChange={(e) => {
                    setErrors({});
                    setValue(i, e.target.value, "initial");
                  }}
                  onBlur={() => {
                    setVariablesToTempVariables();
                  }}
                />
              </TableData>
              {i < inputVariables.length && (
                <td
                  draggable
                  onDragStart={(e) => {
                    console.log("Starting drag");
                    setDragging(true);
                    e.dataTransfer.setData('rowIndex', String(i));
                    // TODO: come up with unique id here, so items can't be dragged
                    // between different variable tables.
                    e.dataTransfer.setData('tableId', 'foo');
                    // this is the default value on init
                    //e.dataTransfer.dropEffect = 'none';
                    //e.dataTransfer.dropEffect = 'move';
                    e.dataTransfer.effectAllowed = 'move';
                  }}
                  onDragEnd={(e) => {
                    console.log("Ending drag");
                    setDragging(false);
                    setDragTarget(null);
                  }}
                >
                  <DragHandle />
                </td>
              )}
            </tr>
          </React.Fragment>
          ))}
        </tbody>
      </TableWrapper>
      <div className={"errorMessage"}>
        {errors &&
          !isEmpty(errors) &&
          format_errors(errors).map((errMessage) => (
            <p key={errMessage}>{errMessage}</p>
          ))}
      </div>
      {serverError && (
        <div>
          <p className={"errorMessage"}>
            {" "}
            Unable to run simulation. The formula examples link above may help
            with debugging.
          </p>
        </div>
      )}
    </div>
  );
}

type IntersticeProps = {
  dragging: boolean;
  active: boolean;
} & React.HTMLProps<HTMLTableRowElement>;
const RowInterstice = (props: IntersticeProps) => {
  const { dragging, active, ...rest } = props;
  const rowStyle = {
    height: dragging ? (active ? "16px" : "12px") : "0px",
    transition: "all 0.1s ease-out",
    backgroundColor: '#0079EE',
    opacity: active ? '.5' : '0',
    width: '100%',
  };
  return (
    <tr style={rowStyle}
      {...rest}
    >
      {/* We need a dummy td for this to participate properly in drag events*/}
      <td colSpan={5}></td>
    </tr>
  )
};

export const StyledImg = styled.img`
  cursor: pointer;
`;

const FormulaCell = styled(FormulaEditor)`
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  overflow: hidden;
  border-radius: 2px;
  font-family: "Roboto-mono";
`;

const NonFormulaCell = styled(TextareaAutosize)`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px;
  overflow: hidden;
  font-family: "Roboto-mono";
  :disabled {
    border: none;
    background: #efefef;
  }
`;

const VarNameCell = styled(NonFormulaCell)``;

const InitialValueCell = styled(NonFormulaCell)``;

// https://stackoverflow.com/a/1368286/262271
export const PseudoLink = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  &:focus {
    outline: none;
  }
`;
