export type AggregationType = "mean" | "total" | "point";

export interface Aggregator {
  type: AggregationType;
  // Set iff type = "point"
  // TODO: code is not currently set up to follow the convention that null means
  // max possible period, but it probably should be this way.
  time?: number;
}

export interface MultivarAggregator extends Aggregator {
  variableNames: string[];
}

export interface DrilldownConfig extends MultivarAggregator {
  dataset?: string; // label
  // Indices of selected rows
  keyIndex: number[];
  /* NB: the keyVals prop has been deprecated, as it was redundant in combination
     with keyIndex and led to consistency issues. Old models (pre ~10/19/20)
     will still have this prop, but it has no effect.
    */
  // labels of selected rows
  //keyVals: string[];
}

export interface OutcomeSelectionConfig extends MultivarAggregator {
}

/* Defines a filter that applies to all the outcomes we show in the Explorer.
   In particular, we restrict the data we show to those simulations where the
   given aggregator applied to the given variable is in the range [min, max]
   e.g. where variable "foo" has a total value between 5 and 10
*/
export interface Filter extends Aggregator {
  variable: string;
  // A value of null indicates no limit.
  min: number | null;
  max: number | null;
}

interface BaseVariable {
  short_name: string;
  equation: string;
}

export interface Variable extends BaseVariable {
  initial?: string;
}

interface FileBasedEntity {
  label: string;
  // Array of file paths in Firebase storage. e.g. "userExternalModelFiles/MY_MODEL_byJpU3vSoa_1600625190190.pickle"
  // Each path represents a different version of the file. Ordered from most to least recently uploaded.
  objectPath: string[];
  // Like above, but full URIs, e.g. "gs://dai-sim-app.appspot.com/userExternalModelFiles/MY_MODEL..."
  uri: string[];
}

// We set the dynamicTyping flag to be true when parsing csv data using
// papaparse, hence we might get any of these three types. See
//   https://react-papaparse.js.org/docs#config
export type CsvDatum = string | number | boolean;
export interface Dataset extends FileBasedEntity {
  columnNames: string[];
  // Name of the column used to uniquely identify rows
  keyName: string;
  // Array of values corresponding to key column
  keyVals: CsvDatum[];
  variables: Variable[];
}

export interface ExternalModel extends FileBasedEntity {}

export interface Policies {
  policyNames: string[];
  attributes: string[];
  // pseudo 2-d array
  formulas: any[];
}

type DagPosition = {
  x: number;
  y: number;
};
export const granularityOptions: string[] = [
  "Year",
  "Quarter",
  "Month",
  "Week",
  "Day",
  "Hour",
  "Minute",
  "Second",
];
export type Granularity = typeof granularityOptions[number];

// Mirrors the schema of our Firestore models collection
export interface Model {
  datasets: Dataset[];
  // answer to "what are you deciding between?"
  decision: string;
  // Dataset specific variables shown in the explorer drilldown
  dsVarCollections: DrilldownConfig[];
  externalModels: ExternalModel[];
  // Determines which simulations are included when calculating outcomes displayed in the explorer
  filters: Filter[];
  granularity: Granularity;
  // Also some kind of explore mode configuration
  mainVarCollections: OutcomeSelectionConfig[];
  // Number of time periods to simulate (not counting hidden initial t=0 timestep)
  periods: number;
  policies: Policies;
  positions?: { [varName: string]: DagPosition };
  // TODO: looks like this is null on a bunch of extant models. Mark as optional?
  // TODO: We should either enforce that this must always be in sorted order, or we
  // should make sure that clients reading this value use a selector that sorts by
  // index. It looks a bit bad if policies aren't always in the same order.
  selectedPolicies: number[];

  /* Inclusive range of timestep values. Various results shown in Build mode
     (Simulated Output table, right pane visualizations) will be limited to
     this range.
     If the right limit is null, this is interpreted as the maximum timestep (i.e. this.periods).
  */
  timeRange: [number, (number | null)];
  variables: Variable[];
}

export interface Example extends Model {
  description: string;
}
