import React, { Fragment } from "react";
import {useDispatch, useSelector} from 'react-redux';

import { FileSectionHead } from "./FileSectionHead";
import {selectExternalModels} from 'modelSlice/selectors';
import { actions } from 'modelSlice';

type Props = {
  modelId: string;
};
export const ExternalModels = (props: Props) => {
  const externalModels = useSelector(selectExternalModels);
  const dispatch = useDispatch();
  const setExternalModels = (val) => {
    dispatch(
      actions.setModelProperty({ field: "externalModels", value: val })
    );
  };
  const modelId = props.modelId;
  return (
    <Fragment>
      {externalModels.map((model, i) => (
        <FileSectionHead
          key={i}
          modelId={modelId}
          label={model.label}
          metadata={externalModels}
          setMetadata={setExternalModels}
          fileType={"model"}
        />
      ))}
      <FileSectionHead
        key={externalModels.length}
        modelId={modelId}
        label={""}
        metadata={externalModels}
        setMetadata={setExternalModels}
        fileType={"model"}
      />
    </Fragment>
  );
};
