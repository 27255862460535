import React from "react";
import styled from "@emotion/styled";
import { ErrorBoundary } from "../../ErrorBoundary";

export const Card = (props) => {
  return (
    <ErrorBoundary>
      <CardContainer  style={props.style}>{props.children}</CardContainer>
    </ErrorBoundary>
  );
};

const CardContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

export default Card;
