import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  tableData: {
    border: "1px solid #818285",
    borderRadius: "2px",
    textAlign: "center",
    height: "36px",
    minWidth: "160px",
    "& textarea, & input": {
      paddingLeft: "8px",
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      border: "none",
      background: "inherit",
      textAlign: "start",
      fontFamily: "Roboto-mono",
    },
    "& input:focus, & textarea:focus, & select:focus": {
      outline: "none",
    },
    "&.disabled": {
      background: "#efefef",
      border: "none",
    },
  },
}));

interface Props {
  className?: string;
  disabled?: boolean;
  borderLeftColor?: string;
}

const TableData: FunctionComponent<Props> = ({
  className,
  disabled,
  borderLeftColor,
  children,
}) => {
  const classes = useStyles();
  const classList = clsx(classes.tableData, className, { disabled });

  return (
    <td
      className={classList}
      style={{
        borderLeft: borderLeftColor ? "4px solid " + borderLeftColor : "",
      }}
    >
      {children}
    </td>
  );
};

export default TableData;
