import { Example } from "types";

type Examples = { [exampleName: string]: Example };

export const EXAMPLES: Examples = {
  fraud_investigation: {
    filters: [],
    description: "",
    granularity: "Month",
    decision: "Fraud Investigation",
    selectedPolicies: [0, 1],
    policies: {
      attributes: ["fraud_prob_threshold", "expected_val_threshold"],
      formulas: [{ arr: [".15", "0"] }, { arr: ["0", "100"] }],
      policyNames: ["Probability Based", "Amount Based"],
    },
    timeRange: [1, null],
    dsVarCollections: [],
    positions: {},
    externalModels: [
      {
        label: "FRAUD_MODEL",
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607621602801.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607621460643.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607621315343.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607620846687.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607620269338.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607619741145.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607614551800.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/FRAUD_MODEL__1607611432153.pkl",
        ],
        objectPath: [
          "userExternalModelFiles/FRAUD_MODEL__1607621602801.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607621460643.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607621315343.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607620846687.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607620269338.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607619741145.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607614551800.pkl",
          "userExternalModelFiles/FRAUD_MODEL__1607611432153.pkl",
        ],
      },
      {
        objectPath: [
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607621608423.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607621443367.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607620552108.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607615468222.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607614626854.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607611744823.pkl",
          "userExternalModelFiles/NEXT_VALUE_MODEL__1607611448331.pkl",
        ],
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607621608423.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607621443367.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607620552108.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607615468222.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607614626854.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607611744823.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEXT_VALUE_MODEL__1607611448331.pkl",
        ],
        label: "NEXT_VALUE_MODEL",
      },
    ],
    datasets: [
      {
        objectPath: [
          "userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607620260123.csv",
          "userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607619754650.csv",
          "userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607614528168.csv",
          "userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607611468578.csv",
        ],
        columnNames: [
          "claim_id",
          "cpt_code",
          "first_amount",
          "previous_claim_amount",
          "state",
          "md_prev_fraud_incidents",
          "actually_fraud",
        ],
        label: "CLAIMS",
        uri: [
          "gs://dai-sim-app.appspot.com/userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607620260123.csv",
          "gs://dai-sim-app.appspot.com/userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607619754650.csv",
          "gs://dai-sim-app.appspot.com/userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607614528168.csv",
          "gs://dai-sim-app.appspot.com/userFiles/CLAIMS_2jbxegYhPjnIurEPhBfX_1607611468578.csv",
        ],
        keyName: "claim_id",
        keyVals: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44,
          45,
          46,
          47,
          48,
          49,
          50,
          51,
          52,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
          60,
          61,
          62,
          63,
          64,
          65,
          66,
          67,
          68,
          69,
          70,
          71,
          72,
          73,
          74,
          75,
          76,
          77,
          78,
          79,
          80,
          81,
          82,
          83,
          84,
          85,
          86,
          87,
          88,
          89,
          90,
          91,
          92,
          93,
          94,
          95,
          96,
          97,
          98,
          99,
          100,
          101,
          102,
          103,
          104,
          105,
          106,
          107,
          108,
          109,
          110,
          111,
          112,
          113,
          114,
          115,
          116,
          117,
          118,
          119,
          120,
          121,
          122,
          123,
          124,
          125,
          126,
          127,
          128,
          129,
          130,
          131,
          132,
          133,
          134,
          135,
          136,
          137,
          138,
          139,
          140,
          141,
          142,
          143,
          144,
          145,
          146,
          147,
          148,
          149,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          162,
          163,
          164,
          165,
          166,
          167,
          168,
          169,
          170,
          171,
          172,
          173,
          174,
          175,
          176,
          177,
          178,
          179,
          180,
          181,
          182,
          183,
          184,
          185,
          186,
          187,
          188,
          189,
          190,
          191,
          192,
          193,
          194,
          195,
          196,
          197,
          198,
          199,
          200,
          201,
          202,
          203,
          204,
          205,
          206,
          207,
          208,
          209,
          210,
          211,
          212,
          213,
          214,
          215,
          216,
          217,
          218,
          219,
          220,
          221,
          222,
          223,
          224,
          225,
          226,
          227,
          228,
          229,
          230,
          231,
          232,
          233,
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264,
          265,
          266,
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280,
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317,
          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          334,
          335,
          336,
          337,
          338,
          339,
          340,
          341,
          342,
          343,
          344,
          345,
          346,
          347,
          348,
          349,
          350,
          351,
          352,
          353,
          354,
          355,
          356,
          357,
          358,
          359,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371,
          372,
          373,
          374,
          375,
          376,
          377,
          378,
          379,
          380,
          381,
          382,
          383,
          384,
          385,
          386,
          387,
          388,
          389,
          390,
          391,
          392,
          393,
          394,
          395,
          396,
          397,
          398,
          399,
          400,
          401,
          402,
          403,
          404,
          405,
          406,
          407,
          408,
          409,
          410,
          411,
          412,
          413,
          414,
          415,
          416,
          417,
          418,
          419,
          420,
          421,
          422,
          423,
          424,
          425,
          426,
          427,
          428,
          429,
          430,
          431,
          432,
          433,
          434,
          435,
          436,
          437,
          438,
          439,
          440,
          441,
          442,
          443,
          444,
          445,
          446,
          447,
          448,
          449,
          450,
          451,
          452,
          453,
          454,
          455,
          456,
          457,
          458,
          459,
          460,
          461,
          462,
          463,
          464,
          465,
          466,
          467,
          468,
          469,
          470,
          471,
          472,
          473,
          474,
          475,
          476,
          477,
          478,
          479,
          480,
          481,
          482,
          483,
          484,
          485,
          486,
          487,
          488,
          489,
          490,
          491,
          492,
          493,
          494,
          495,
          496,
          497,
          498,
          499,
          500,
          501,
          502,
          503,
          504,
          505,
          506,
          507,
          508,
          509,
          510,
          511,
          512,
          513,
          514,
          515,
          516,
          517,
          518,
          519,
          520,
          521,
          522,
          523,
          524,
          525,
          526,
          527,
          528,
          529,
          530,
          531,
          532,
          533,
          534,
          535,
          536,
          537,
          538,
          539,
          540,
          541,
          542,
          543,
          544,
          545,
          546,
          547,
          548,
          549,
          550,
          551,
          552,
          553,
          554,
          555,
          556,
          557,
          558,
          559,
          560,
          561,
          562,
          563,
          564,
          565,
          566,
          567,
          568,
          569,
          570,
          571,
          572,
          573,
          574,
          575,
          576,
          577,
          578,
          579,
          580,
          581,
          582,
          583,
          584,
          585,
          586,
          587,
          588,
          589,
          590,
          591,
          592,
          593,
          594,
          595,
          596,
          597,
          598,
          599,
          600,
          601,
          602,
          603,
          604,
          605,
          606,
          607,
          608,
          609,
          610,
          611,
          612,
          613,
          614,
          615,
          616,
          617,
          618,
          619,
          620,
          621,
          622,
          623,
          624,
          625,
          626,
          627,
          628,
          629,
          630,
          631,
          632,
          633,
          634,
          635,
          636,
          637,
          638,
          639,
          640,
          641,
          642,
          643,
          644,
          645,
          646,
          647,
          648,
          649,
          650,
          651,
          652,
          653,
          654,
          655,
          656,
          657,
          658,
          659,
          660,
          661,
          662,
          663,
          664,
          665,
          666,
          667,
          668,
          669,
          670,
          671,
          672,
          673,
          674,
          675,
          676,
          677,
          678,
          679,
          680,
          681,
          682,
          683,
          684,
          685,
          686,
          687,
          688,
          689,
          690,
          691,
          692,
          693,
          694,
          695,
          696,
          697,
          698,
          699,
          700,
          701,
          702,
          703,
          704,
          705,
          706,
          707,
          708,
          709,
          710,
          711,
          712,
          713,
          714,
          715,
          716,
          717,
          718,
          719,
          720,
          721,
          722,
          723,
          724,
          725,
          726,
          727,
          728,
          729,
          730,
          731,
          732,
          733,
          734,
          735,
          736,
          737,
          738,
          739,
          740,
          741,
          742,
          743,
          744,
          745,
          746,
          747,
          748,
          749,
          750,
          751,
          752,
          753,
          754,
          755,
          756,
          757,
          758,
          759,
          760,
          761,
          762,
          763,
          764,
          765,
          766,
          767,
          768,
          769,
          770,
          771,
          772,
          773,
          774,
          775,
          776,
          777,
          778,
          779,
          780,
          781,
          782,
          783,
          784,
          785,
          786,
          787,
          788,
          789,
          790,
          791,
          792,
          793,
          794,
          795,
          796,
          797,
          798,
          799,
          800,
          801,
          802,
          803,
          804,
          805,
          806,
          807,
          808,
          809,
          810,
          811,
          812,
          813,
          814,
          815,
          816,
          817,
          818,
          819,
          820,
          821,
          822,
          823,
          824,
          825,
          826,
          827,
          828,
          829,
          830,
          831,
          832,
          833,
          834,
          835,
          836,
          837,
          838,
          839,
          840,
          841,
          842,
          843,
          844,
          845,
          846,
          847,
          848,
          849,
          850,
          851,
          852,
          853,
          854,
          855,
          856,
          857,
          858,
          859,
          860,
          861,
          862,
          863,
          864,
          865,
          866,
          867,
          868,
          869,
          870,
          871,
          872,
          873,
          874,
          875,
          876,
          877,
          878,
          879,
          880,
          881,
          882,
          883,
          884,
          885,
          886,
          887,
          888,
          889,
          890,
          891,
          892,
          893,
          894,
          895,
          896,
          897,
          898,
          899,
          900,
          901,
          902,
          903,
          904,
          905,
          906,
          907,
          908,
          909,
          910,
          911,
          912,
          913,
          914,
          915,
          916,
          917,
          918,
          919,
          920,
          921,
          922,
          923,
          924,
          925,
          926,
          927,
          928,
          929,
          930,
          931,
          932,
          933,
          934,
          935,
          936,
          937,
          938,
          939,
          940,
          941,
          942,
          943,
          944,
          945,
          946,
          947,
          948,
          949,
          950,
          951,
          952,
          953,
          954,
          955,
          956,
          957,
          958,
          959,
          960,
          961,
          962,
          963,
          964,
          965,
          966,
          967,
          968,
          969,
          970,
          971,
          972,
          973,
          974,
          975,
          976,
          977,
          978,
          979,
          980,
          981,
          982,
          983,
          984,
          985,
          986,
          987,
          988,
          989,
          990,
          991,
          992,
          993,
          994,
          995,
          996,
          997,
          998,
          999,
        ],
        variables: [
          {
            short_name: "predicted_fraud_prob",
            equation:
              "FRAUD_MODEL.predict_proba(\n  cpt_code,\n  state,\n  claim_amount,\n  accumulated_claim_amount,\n  md_prev_fraud_incidents\n)",
          },
          {
            initial: "first_amount",
            equation:
              "NEXT_VALUE_MODEL.predict(\n  cpt_code,\n  claim_amount[t-1],\n  accumulated_claim_amount[t-1]\n)",
            short_name: "claim_amount",
          },
          {
            equation:
              "where(fraud_identified[t-1], 0, predicted_fraud_prob * claim_amount)",
            initial: "0",
            short_name: "investigation_value",
          },
          {
            short_name: "amount_paid",
            equation: "where(fraud_identified, 0, claim_amount)",
          },
          {
            short_name: "investigated",
            equation:
              "above_value_thresh * (predicted_fraud_prob > fraud_prob_threshold)",
          },
          {
            short_name: "above_value_thresh",
            equation: "investigation_value > expected_val_threshold",
          },
          {
            initial: "0",
            equation:
              "fraud_identified[t-1] or (investigated and actually_fraud)",
            short_name: "fraud_identified",
          },
          {
            initial: "0",
            equation: "accumulated_claim_amount[t-1] + claim_amount",
            short_name: "accumulated_claim_amount",
          },
        ],
      },
    ],
    mainVarCollections: [
      { type: "mean", variableNames: ["investigations", "paid"] },
    ],
    periods: 8,
    variables: [
      {
        short_name: "investigations",
        equation: "sum(CLAIMS.investigated)",
      },
      {
        short_name: "total_payments",
        equation: "sum(CLAIMS.amount_paid)",
      },
    ],
  },
  inventory: {
    description: "",
    periods: 20,
    variables: [
      { initial: "", short_name: "profit", equation: "revenue - costs" },
      {
        initial: "0",
        equation: "quantity_sold * price",
        short_name: "revenue",
      },
      { equation: "normal(90, 10)", short_name: "price" },
      { equation: "DEMAND_FORECAST_MODEL.predict(t)", short_name: "demand" },
      {
        short_name: "total_stock_available",
        equation: "leftover_stock[t-1] + order_from_wholesaler",
      },
      {
        short_name: "quantity_sold",
        equation: "min(total_stock_available, demand)",
      },
      {
        initial: "500",
        short_name: "leftover_stock",
        equation: "total_stock_available[t-1] - quantity_sold[t-1]",
      },
      {
        short_name: "costs",
        equation:
          "order_from_wholesaler * 80 + leftover_stock * 10 \n# wholesale cost is 80. Inventory holding cost is $10 per unit",
      },
    ],
    datasets: [],
    externalModels: [
      {
        label: "DEMAND_FORECAST_MODEL",
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/DEMAND_FORECAST_MODEL__1607127947905.pkl",
        ],
        objectPath: [
          "userExternalModelFiles/DEMAND_FORECAST_MODEL__1607127947905.pkl",
        ],
      },
    ],
    dsVarCollections: [],
    selectedPolicies: [0, 1],
    filters: [
      { max: null, min: null, time: 1, variable: "price", type: "point" },
    ],
    mainVarCollections: [{ type: "mean", variableNames: ["profit"] }],
    positions: {
      total_stock_available: { x: -153, y: -80 },
      price: { y: -3, x: 1 },
      revenue: { x: -154, y: 84 },
      profit: { y: 195, x: -153 },
      leftover_stock: { y: -177, x: -323 },
      leftover_inventory: { x: -324, y: -177 },
      policy: { y: -284, x: -157 },
      costs: { y: -2, x: -316 },
      order_demand_mult: { y: -156, x: -143 },
      quantity_sold: { x: -152, y: -1 },
      stock_available: { x: -152, y: -91 },
      demand: { x: 2, y: -78 },
      order_from_wholesaler: { y: -176, x: -155 },
    },
    granularity: "Week",
    timeRange: [1, null],
    decision: "Inventory management",
    policies: {
      attributes: ["order_from_wholesaler"],
      policyNames: ["Always Order 1500", "Replace previous sales"],
      formulas: [{ arr: ["1500"] }, { arr: ["demand[t-1]"] }],
    },
  },
  airline: {
    description:
      "An airline finding the optimal pricing rule for their flights",
    timeRange: [1, 60],
    variables: [
      {
        short_name: "cumulative_revenue",
        initial: "0",
        equation: "cumulative_revenue[t-1] + revenue",
      },
      {
        initial: "120",
        short_name: "revenue",
        equation: "seats_sold * price",
      },
      {
        short_name: "seats_sold",
        equation: "min(demand, seats_remaining)",
      },
      {
        equation:
          "COMPETITOR_PRICE_MODEL.predict(price[t-1], t, competitor_seats_remaining[t-1])",
        short_name: "competitor_price",
        initial: "180",
      },
      {
        equation:
          "max(0, competitor_seats_remaining[t-1] - competitor_seats_sold)",
        short_name: "competitor_seats_remaining",
        initial: "100",
      },
      {
        equation:
          "poisson(DEMAND_MODEL.predict(days_until_flight, price, competitor_price))",
        short_name: "demand",
      },
      {
        initial: "60",
        equation: "days_until_flight[t-1] - 1",
        short_name: "days_until_flight",
      },
      {
        equation:
          "COMPETITOR_DEMAND_MODEL.predict(days_until_flight, competitor_price, price)",
        short_name: "competitor_seats_sold",
      },
      {
        equation: "max(0, seats_remaining[t-1] - seats_sold[t-1])",
        short_name: "seats_remaining",
        initial: "120",
      },
    ],
    datasets: [],
    selectedPolicies: [0, 1],
    dsVarCollections: [],
    mainVarCollections: [
      { type: "total", variableNames: ["revenue", "seats_sold"], time: 60 },
      { type: "point", variableNames: ["seats_remaining"], time: 24 },
    ],
    filters: [
      { min: 0, max: 4, time: 1, variable: "seats_sold", type: "point" },
      {
        min: 310,
        variable: "competitor_price",
        time: 1,
        max: 340,
        type: "mean",
      },
    ],
    periods: 60,
    policies: {
      attributes: ["price"],
      formulas: [
        { arr: ["325 + days_until_flight - 0.5 *seats_remaining"] },
        { arr: ["450 - 0.5*competitor_price"] },
      ],
      policyNames: ["Capacity based", "Competition based"],
    },
    granularity: "Day",
    positions: {
      cumulative_revenue: { x: 180, y: 224 },
      seats_sold: { y: 11, x: 177 },
      competitor_seats_remaining: { x: -182, y: 161 },
      demand: { x: 73, y: 8 },
      revenue: { x: 178, y: 110 },
      competitor_price: { x: -50, y: -148 },
      days_until_flight: { y: -63, x: -49 },
      competitor_seats_sold: { y: 17, x: -179 },
      seats_remaining: { y: -147, x: 173 },
      policy: { y: -216, x: -128 },
    },
    externalModels: [
      {
        label: "DEMAND_MODEL",
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/DEMAND_MODEL__1604849169243.pkl",
        ],
        objectPath: ["userExternalModelFiles/DEMAND_MODEL__1604849169243.pkl"],
      },
      {
        label: "COMPETITOR_PRICE_MODEL",
        objectPath: [
          "userExternalModelFiles/COMPETITOR_PRICE_MODEL__1604849174957.pkl",
        ],
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/COMPETITOR_PRICE_MODEL__1604849174957.pkl",
        ],
      },
      {
        label: "COMPETITOR_DEMAND_MODEL",
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/COMPETITOR_DEMAND_MODEL__1604849182246.pkl",
        ],
        objectPath: [
          "userExternalModelFiles/COMPETITOR_DEMAND_MODEL__1604849182246.pkl",
        ],
      },
    ],
    decision: "Airline Pricing",
  },
  staffing: {
    description:
      "A sandwich restaurant wants to decide how many people to have working during lunch. See the impact staffing has on line length, revenue and profitability.",
    mainVarCollections: [
      {
        time: 180,
        type: "point",
        variableNames: ["cumulative_profit", "new_people_in_line"],
      },
      { variableNames: ["line_length"], type: "mean", time: null },
    ],
    filters: [
      {
        type: "point",
        min: 3.9535714285714283,
        variable: "new_people_in_line",
        max: 9,
        time: 1,
      },
    ],
    variables: [
      {
        short_name: "cumulative_profit",
        equation: "cumulative_profit[t-1] + profit",
        initial: "0",
      },
      {
        initial: "",
        short_name: "revenue",
        equation: "8 * number_served",
      },
      {
        equation: "labor_cost + ingredients_cost",
        short_name: "total_cost",
      },
      {
        equation: "poisson(NEW_PEOPLE_IN_LINE.predict(t, line_length[t-1]))",
        short_name: "new_people_in_line",
      },
      {
        short_name: "number_served",
        equation: "min(line_length[t-1], max_production)",
      },
      {
        short_name: "labor_cost",
        equation: "3 * 15 * workers / 60",
      },
      {
        equation: "7 * number_served",
        short_name: "ingredients_cost",
      },
      {
        short_name: "profit",
        equation: "revenue - total_cost",
      },
      {
        short_name: "line_length",
        equation: "line_length[t-1] + new_people_in_line - number_served",
        initial: "0",
      },
    ],
    externalModels: [
      {
        label: "NEW_PEOPLE_IN_LINE",
        uri: [
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600625190190.pickle",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600458739892.pkl",
          "gs://dai-sim-app.appspot.com/userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600445282802.pkl",
        ],
        objectPath: [
          "userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600625190190.pickle",
          "userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600458739892.pkl",
          "userExternalModelFiles/NEW_PEOPLE_IN_LINE_undefined_1600445282802.pkl",
        ],
      },
    ],
    timeRange: [1, null],
    selectedPolicies: null,
    datasets: [],
    dsVarCollections: [],
    policies: {
      policyNames: ["medium", "high"],
      formulas: [{ arr: ["4", "2"] }, { arr: ["5", "3"] }],
      attributes: ["max_production", "workers"],
    },
    positions: {
      cumulative_revenue: { x: -8, y: -51 },
      line_length: { x: 144, y: -80 },
      policy: { y: -294, x: -91 },
      total_cost: { y: -161, x: -145 },
      new_people_in_line: { y: -172, x: 203 },
    },
    periods: 180,
    decision: "Restaurant Staffing",
    granularity: "Minute",
  },
  retirement: {
    description: "See what age you can retire at.",
    selectedPolicies: null,
    externalModels: [],
    periods: 55,
    dsVarCollections: [],
    decision: "Retirement age",
    variables: [
      {
        short_name: "net_worth",
        equation:
          "net_worth[t-1] +stock_earnings + earnings - expenditures - taxes",
        initial: "500000",
      },
      {
        short_name: "stock_returns",
        equation: "normal(.08, .1)",
      },
      {
        equation:
          "where(age<retirement_age, earnings[t-1] * uniform(1, 1.06), 0)",
        short_name: "earnings",
        initial: "125000",
      },
      { short_name: "age", equation: "35 + t" },
      {
        initial: "60000",
        short_name: "expenditures",
        equation: "expenditures[t-1] * uniform(1.01, 1.06)",
      },
      {
        equation: ".35 * (earnings + stock_earnings)",
        short_name: "taxes",
      },
      {
        equation: "max(0, net_worth[t-1]) * stock_returns",
        short_name: "stock_earnings",
      },
      {
        short_name: "broke",
        equation: "where(net_worth<0, 1, 0)",
      },
      {
        equation: "where(broke, last_age_before_broke[t-1], age)",
        initial: "0",
        short_name: "last_age_before_broke",
      },
    ],
    timeRange: [1, 55],
    policies: {
      attributes: ["retirement_age"],
      policyNames: ["age 50", "age 55", "age 60", "age 65"],
      formulas: [
        { arr: ["50"] },
        { arr: ["55"] },
        { arr: ["60"] },
        { arr: ["65"] },
      ],
    },
    mainVarCollections: [
      {
        variableNames: ["broke", "last_age_before_broke"],
        type: "point",
        time: 55,
      },
    ],
    positions: {
      stock_earnings: { x: 296, y: -35 },
      taxes: { x: 138, y: -191 },
      net_worth: { y: -86, x: -54 },
      policy: { y: -226, x: -177 },
      broke: { y: 59, x: -22 },
      expenditures: { y: -91, x: 151 },
      earnings: { x: -21, y: -220 },
      last_age_before_broke: { y: -16, x: -206 },
      age: { y: -163, x: -200 },
    },
    datasets: [],
    granularity: "Year",
    filters: [],
  },
};
