import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { StyledCard, Content, StyledP } from "./Styles";
import { SignInGoogle } from "./SignIn";
import Title from "../../UI/components/Title";
import { SignUpForm } from "./SignUp";

const SignUpModal = (props) => {
  return (
    <Dialog open={true} BackdropProps={{ invisible: true }}>
      <Content>
        <Title>Sign Up</Title>
        <StyledCard>
          <SignUpForm />
          <SignInGoogle />
          <StyledP onClick={props.switchToSignIn}>
            Already have an account?
          </StyledP>
        </StyledCard>
      </Content>
    </Dialog>
  );
};

export default SignUpModal;
