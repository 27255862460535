import {useEffect} from "react";
import ReactGA from "react-ga";
import {withRouter} from "react-router";
import PropTypes from "prop-types";


const googleAnalyticsTrackingId = "UA-155304517-2";

function sendPageView(location) {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
}

function GAListener({children, history}) {

  useEffect(() => {
    ReactGA.initialize(googleAnalyticsTrackingId);
    sendPageView(history.location);
    return history.listen(sendPageView);
  }, [history]);

  return children;
}

GAListener.propTypes = {
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
};

export default withRouter(GAListener)
