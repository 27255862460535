import React, { Component } from "react";
import { withFirebase } from "firebaseHelpers";
import StyledInput from "../../UI/components/StyledInput";
import styled from "@emotion/styled";
import { BaseButton } from "./Styles";

export const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

interface State {
  email: string;
  error: null | { message: string };
}

const INITIAL_STATE: State = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component<any, State> {
  state: State = INITIAL_STATE;

  onSubmit = (event) => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = (event) => {
    // @ts-ignore
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === "";
    return (
      <form onSubmit={this.onSubmit}>
        <FlexWrapper>
          <p>Your Email</p>
          <StyledInput
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </FlexWrapper>
        <BaseButton disabled={isInvalid} type="submit">
          Email reset link.
        </BaseButton>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
