import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

const Input = styled.input`
  background: #ffffff;
  padding: 8px 16px;
  height: 36px;
  border: 1px solid #818285;
  box-sizing: border-box;
  border-radius: 2px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px gray;
  }
`;

export default Input;

/* Utility for the common case where we want a buffer of internal state to hold
   intermediate text values as they're entered keystroke-by-keystroke. Consumers
   of this API will typically pass an onBlur handler to deal with finalized new values.
   e.g.
   <SemiControlledInput
      value={resultOfSomeDbQuery}
      onBlur={(e) => db.commitNewValue(e.target.value)
   />
*/
export const SemiControlledInput = (props) => {
  const {value, ...otherProps} = props;
  const [localValue, setLocalValue] = useState(value || '');
  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);
  return (
    <Input
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      {...otherProps}
    />
  );
};
