import {createSelector} from "@reduxjs/toolkit";

export const selectModel = rootState => rootState.model.model;

export const selectPolicies = createSelector(
  selectModel,
  model => model?.policies
);

export const selectPolicyNames = createSelector(
  selectPolicies,
  policies => policies?.policyNames || []
);

export const selectDatasets = createSelector(
  selectModel,
  model => model?.datasets || []
);

export const selectNumDatasets = createSelector(
  selectDatasets,
  datasets => datasets.length
);

export const selectDatasetsByLabel = createSelector(
  selectDatasets,
  datasets => datasets.reduce( (result, ds) => {
    result[ds.label] = ds;
    return result;
  }, {})
);

export const selectExternalModels = createSelector(
  selectModel,
  model => model?.externalModels || []
);

export const selectNumExternalModels = createSelector(
  selectExternalModels,
  externalModels => externalModels.length
);

export const selectTimeRange = createSelector(
  selectModel,
  model => {
    if (!model) return;
    if (model.timeRange[1] === null) {
      return [model.timeRange[0], model.periods];
    } else {
      return model.timeRange;
    }
  }
);

export const selectGranularity = createSelector(
  selectModel,
  (model) => model?.granularity
);

export const selectNumPeriods = createSelector(
  selectModel,
  (model) => model?.periods
);

export const selectVariables = createSelector(
  selectModel,
  (model) => model?.variables || []
);

export const selectValidVariables = createSelector(
  selectVariables,
  variables => variables.filter(v => v.short_name.length && v.equation.length)
);

export const selectVariableNames = createSelector(
  selectVariables,
  variables => variables.map( v => v.short_name )
);

export const selectPositions = createSelector(
  selectModel,
  model => model?.positions
);
