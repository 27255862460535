import React from 'react';
import {rollbarError} from "./RollbarInit";


export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    rollbarError(error);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h6>Sorry! Something went wrong. We'll look into it. Feel free to email me: dan@decision.ai.</h6>
        </div>
      );
    }
    return this.props.children;
  }
}
