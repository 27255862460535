import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "@emotion/styled";

import DatasetExpansionPanel from "./DatasetExpansionPanel";
import { getErrorsInDataset } from "./dataViewUtils";
import { FileSectionHead } from "../FileSectionHead";
import Select from "UI/components/Select";
import { Excerpts } from "api/types";
import {selectPolicyNames, selectDatasets} from "modelSlice/selectors";
import { actions } from 'modelSlice';

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.12em;
  text-transform: capitalize;
`;

type Setter = (newValue: any) => void;
type DatasetsProps = {
  modelId: any;
  setShowEquationHelp: Setter;
  typeAheadWords: any;
  // Below props may be null if api results haven't loaded
  deps: any;
  datasetResults: Excerpts | null;
  datasetErrors: any;
};

export default function Datasets(props: DatasetsProps) {
  // Identifier of selected policy. Variable previews will be for that policy.
  const [policy, setPolicy] = useState<string>();
  const policyNames = useSelector(selectPolicyNames);
  const modelId = props.modelId;

  const datasets = useSelector(selectDatasets);
  const setShowEquationHelp = props.setShowEquationHelp;
  const typeAheadWords = props.typeAheadWords;
  const datasetErrors = props.datasetErrors;

  const dispatch = useDispatch();
  const setDatasets = (val) => {
    dispatch(
      actions.setModelProperty({ field: "datasets", value: val })
    );
  };

  const ixPolicy = policy ? policyNames.indexOf(policy) : 0;

  return (
    <>
      {datasets.length !== 0 && policyNames.length > 1 && (
        <div
          style={{
            padding: "12px",
            display: "flex",
            alignItems: "center",
            width: "60%",
          }}
        >
          <SubTitle>Policy to show:</SubTitle>
          <Select
            id="policyToShow"
            onChange={(e) => setPolicy(e.currentTarget.value)}
            options={policyNames}
            value={policyNames[ixPolicy]}
          />
        </div>
      )}

      {datasets.map((dataset, index) => (
        <div key={dataset.label}>
          <FileSectionHead
            modelId={modelId}
            label={dataset.label}
            metadata={datasets}
            setMetadata={setDatasets}
            fileType={"dataset"}
            style={index === 0 ? { paddingTop: 0 } : {}}
          />
          <DatasetExpansionPanel
            dataset={dataset}
            allDatasets={datasets}
            setDatasets={setDatasets}
            setShowEquationHelp={setShowEquationHelp}
            typeAheadWords={typeAheadWords}
            datasetResults={props.datasetResults?.get?.(dataset.label)}
            errors={getErrorsInDataset(datasetErrors, dataset)}
            policyIndex={ixPolicy}
          />
        </div>
      ))}
      <FileSectionHead
        key={datasets.length}
        modelId={modelId}
        label={""}
        metadata={datasets}
        setMetadata={setDatasets}
        fileType={"dataset"}
      />
    </>
  );
}
