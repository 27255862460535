import { PayloadAction } from "@reduxjs/toolkit";

import { Aggregator, Variable } from "types";

const selectAggregatorsForModel = (model): Aggregator[] => {
  return [
    ...model.dsVarCollections,
    ...model.mainVarCollections,
    ...model.filters,
  ];
};

/* Mutates the given Aggregator object, setting the given field to the given
   value. Also performs any derived updates that are required of any generic
   Aggregator object.
*/
const performGenericAggregatorUpdate = (
  // Could use generics and type field to keyof T
  agg: Aggregator,
  field: string,
  value: any,
  state
): void => {
  if (field === "type" && agg[field] !== value) {
    // if we're changing from point in time to another aggregation type, set
    // time to null. Don't want to leave around a stale value.
    if (agg.type === "point") {
      agg.time = null;
      // If we're going *to* time-based aggregation, set a sensible default value.
    } else if (value === "point") {
      agg.time = state.model.periods;
    }
  }
  agg[field] = value;
};

const processVariable = (vari: Variable) => {
  const { initial, ...rest } = vari;
  // If initial value is set to empty string, treat this as "no initial value",
  // which we'll canonically represent by omitting the initial field entirely.
  if (initial === "") {
    return rest;
  }
  return vari;
};

// Apply some generic normalization to variable values
const preprocessVariables = (vars: Variable[]) => {
  return vars.map(processVariable);
};

const modelReducers = {
  /* generic reducer for arbitrary field on metamodel.model
     WARNING: this is a dumb implementation that will not do any sort of 
     cascading updates to related fields. You should use a more specific reducer
     if one exists.
  */
  setModelProperty: (state, action) => {
    const { field, value } = action.payload;
    state.model[field] = value;
  },
  setVariables: (state, action) => {
    const { vars } = action.payload;
    state.model.variables = preprocessVariables(vars);
  },
  setDatasetVariables: (state, action) => {
    const { vars, datasetIndex } = action.payload;
    state.model.datasets[datasetIndex].variables = preprocessVariables(vars);
  },
  setViewable: (state, action: PayloadAction<boolean>) => {
    state.viewable = action.payload;
  },
  setNumPeriods: (state, action: PayloadAction<number>) => {
    const numPeriods = action.payload;
    const model = state.model;
    model.periods = numPeriods;
    // Now we need to update a bunch of other model state that might contain
    // references to out-of-bounds time steps.

    // Explore mode aggregator configs
    selectAggregatorsForModel(model).forEach((agg: Aggregator) => {
      if (agg.type === "point" && agg.time > numPeriods) {
        // Clip to the last timestep
        console.log(`Clipping time for agg from ${agg.time} to ${numPeriods}`);
        agg.time = numPeriods;
      }
    });

    // timeRange
    if (model.timeRange[1] !== null && model.timeRange[1] > numPeriods) {
      console.log(
        `Clipping timeRange max from ${model.timeRange[1]} to ${numPeriods}`
      );
      model.timeRange[1] = numPeriods;
    }
  },
  deletePolicy: (state, action: PayloadAction<number>) => {
    const i = action.payload;
    const { policyNames, formulas } = state.model.policies;
    policyNames.splice(i, 1);
    formulas.splice(i, 1);
    // Update selectedPolicies, if present
    if (state.model.selectedPolicies) {
      state.model.selectedPolicies = state.model.selectedPolicies
        .filter((selectedIx) => selectedIx !== i) // remove index of deleted policy, if present
        .map((selectedIx) => (selectedIx > i ? selectedIx - 1 : selectedIx)); // shift indices
    }
  },
  renamePolicy: (state, action: PayloadAction<{ i; name }>) => {
    const { i, name } = action.payload;
    state.model.policies.policyNames[i] = name;
  },
  appendPolicy: (state, action: PayloadAction<string>) => {
    const { policyNames, formulas, attributes } = state.model.policies;
    policyNames.push(action.payload);
    const emptyFormulaRow = { arr: attributes.map((_) => "") };
    formulas.push(emptyFormulaRow);
    // A new policy will be considered whitelisted by default
    if (state.model.selectedPolicies) {
      state.model.selectedPolicies.push(policyNames.length - 1);
    }
  },
  appendNamelessPolicy: (state, action) => {
    // Append a policy having a formula for some attribute index, but no defined name.
    const { formula, i } = action.payload;
    const { policyNames, formulas, attributes } = state.model.policies;
    policyNames.push("");
    const newFormulaRow = { arr: attributes.map((_) => "") };
    newFormulaRow.arr[i] = formula;
    formulas.push(newFormulaRow);
    // A new policy will be considered whitelisted by default
    if (state.model.selectedPolicies) {
      state.model.selectedPolicies.push(policyNames.length - 1);
    }
  },
  deleteAttribute: (state, action: PayloadAction<number>) => {
    const i = action.payload;
    const { formulas, attributes } = state.model.policies;
    attributes.splice(i, 1);
    formulas.forEach((flist) => {
      flist.arr.splice(i, 1);
    });
  },
  renameAttribute: (state, action: PayloadAction<{ i; name }>) => {
    const { i, name } = action.payload;
    state.model.policies.attributes[i] = name;
  },
  appendAttribute: (state, action: PayloadAction<string>) => {
    const name = action.payload;
    const { formulas, attributes } = state.model.policies;
    attributes.push(name);
    formulas.forEach((flist) => {
      flist.arr.push("");
    });
  },
  setFormula: (state, action) => {
    // maybe cleaner to use attribute name in cases like this. But would be
    // annoyingly inconsistent.
    const { policyIndex, attributeIndex, formula } = action.payload;
    state.model.policies.formulas[policyIndex].arr[attributeIndex] = formula;
  },
  setMainVarCollectionParam: (state, action) => {
    const { i, key, val } = action.payload;
    const aggregator = state.model.mainVarCollections[i];
    performGenericAggregatorUpdate(aggregator, key, val, state);
  },
  setDsVarCollectionParam: (state, action) => {
    const { i, key, val } = action.payload;
    const agg = state.model.dsVarCollections[i];
    if (key === "dataset" && val !== agg.dataset) {
      agg.variableNames = [];
      agg.keyIndex = [];
    }
    performGenericAggregatorUpdate(agg, key, val, state);
  },
  setFilterParam: (state, action) => {
    const { i, key, val } = action.payload;
    const agg = state.model.filters[i];
    const oldValue = agg[key];
    const changed = oldValue !== val;
    // If we're changing the variable or aggregation method, then the current
    // max and min will probably not be meaningful. Reset them to null (= no limit)
    if (changed && (key === "variable" || key === "type" || key === "time")) {
      agg.min = null;
      agg.max = null;
    }
    performGenericAggregatorUpdate(agg, key, val, state);
  },
  newOutcomeSelection: (state) => {
    const newConfig = { type: "mean", variableNames: [] };
    state.model.mainVarCollections.push(newConfig);
  },
  newDrilldownConfig: (state) => {
    const newConfig = {
      keyIndex: [],
      type: "mean",
      variableNames: [],
    };
    state.model.dsVarCollections.push(newConfig);
  },
  deleteOutcomeSelectionConfig: (state, action) => {
    const i = action.payload;
    const removed = state.model.mainVarCollections.splice(i, 1);
    if (removed.length === 0) {
      console.error("Failed to delete item at index", i);
    }
  },
  deleteDrilldownConfig: (state, action) => {
    const i = action.payload;
    const removed = state.model.dsVarCollections.splice(i, 1);
    if (removed.length === 0) {
      console.error("Failed to delete item at index", i);
    }
  },
  deleteFilter: (state, action) => {
    const i = action.payload;
    const removed = state.model.filters.splice(i, 1);
    if (removed.length === 0) {
      console.error("Failed to delete item at index", i);
    }
  },
  // Setting position of a node in model dag visualization
  setDagPosition: (state, action) => {
    const { position, varName } = action.payload;
    // If positions is undefined, need to instantiate it
    if (!state.model.positions) {
      state.model.positions = {};
    }
    state.model.positions[varName] = position;
  },
};

/* Wrap all above reducers so they have the effect of setting the dirty bit.
   TODO: Should we also add an assertion that state.model exists (and bail if
   it doesn't)?
*/
const wrapModelReducer = (reducer) => (state, action) => {
  console.debug("Applying model action:", action);
  reducer(state, action);
  state.dirty = true;
};
Object.entries(modelReducers).forEach(([name, reducer]) => {
  modelReducers[name] = wrapModelReducer(reducer);
});

export default modelReducers;
