import { createSelector } from '@reduxjs/toolkit';

import { PartialApiResults, Excerpt } from './types';
import * as policyHelpers from "policyHelpers";
import { RootState } from 'store';
import { DrilldownConfig } from 'types';

export const makeDrilldownRequestDat = (config: DrilldownConfig): object => {
  let agg;
  switch (config.type) {
    case "mean":
    case "total":
      agg = config.type;
      break;
    case "point":
      agg = {pointInTime: config.time};
      break;
  }
  return {
    agg: agg,
    datasetLabel: config.dataset,
    rowIndices: config.keyIndex,
    variables: config.variableNames,
  };
}

export const formatResponseSimData = (jsonData: string) => {
  const rawData = JSON.parse(jsonData);
  const varNames = rawData.columns;
  const data = rawData.data;
  const out = data.map((row) => (
    Object.fromEntries(varNames.map((_, i) => [varNames[i], row[i]]))
  ));
  return out;
};

export const formatDsSimData = (dsSimResultsJson): Map<string, Excerpt> => {
  // Note the DS data is in some sense JSONified twice on the backend, so we parse it to uncover high level object properties
  // but then the formatResponseSimData call parses the values of each property
  const topLevelParsed = JSON.parse(dsSimResultsJson);
  const fullyParsed = new Map(
    Object.keys(topLevelParsed).map((key) => [
      key,
      // Can't "map" on Object.entries() and I think keeping I can. Stop trying.
      formatResponseSimData(topLevelParsed[key]),
    ])
  );
  return fullyParsed;
};

const postDataForModel = (variables, policies, datasets, externalModels, periods) => {
  // Should the same thing be done for dataset variables and policy attributes?
  const valid_variables = variables.filter( (v) => v.short_name && v.equation );
  return {
    variables: valid_variables,
    policies: policyHelpers.demigratePolicies(policies),
    datasets: datasets,
    externalModels: externalModels,
    numPeriods: periods,
  };
};

export const parsePartialResultsFromResponse = (response): PartialApiResults => {
  const dat = response.data;
  return {
    // functionsAvailable, mainErrors, deps etc. can be passed unchanged
    ...dat,
    datasetVarValuesExcerpt: formatDsSimData(dat.datasetVarValuesExcerpt),
  };
}

// TODO: this is an argument for defining selectors like this in modelSlice/selectors
// separate from the corresponding useFoo hooks
export const selectModelPostData = createSelector(
  (state: RootState) => state.model.model.variables,
  (state: RootState) => state.model.model.policies,
  (state: RootState) => state.model.model.datasets,
  (state: RootState) => state.model.model.externalModels,
  (state: RootState) => state.model.model.periods,
  postDataForModel,
);
