import React, { useEffect, useRef, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Histoslider } from "histoslider";
import { histogram } from "d3";
import "d3";

import { format_number } from "../../Builder/DataOutputTable";
import { Container } from "../../../Charting/ChartUtils";
import { FilterControls } from "../Aggregators";
import { getSimSummaryStats } from "./aggAndFilterLogic";
import { actions } from "modelSlice";

function makeTwoSigFigs(x, roundUp) {
  if (x === 0) {
    return 0;
  }
  const digitsToRound = Math.floor(Math.log10(Math.abs(x)));
  const downscale_factor = 10 ** (-digitsToRound + 1);
  const upscale_factor = 10 ** (digitsToRound - 1);

  if (roundUp) {
    return Math.ceil(x * downscale_factor) * upscale_factor;
  } else {
    return Math.floor(x * downscale_factor) * upscale_factor;
  }
}

function getArrayRange(arr) {
  var max = -Infinity;
  var min = Infinity;

  arr.forEach(function (e) {
    if (e !== null && max < e) {
      max = e;
    }
    if (e !== null && min > e) {
      min = e;
    }
  });
  if (min === max) {
    return [min, max]; // hack: don't want to expand the range if the interval is empty, since then we can't detect that it was empty
  } else {
    return [makeTwoSigFigs(min, false), makeTwoSigFigs(max, true)];
  }
}

export function FilterSelector(props) {
  const variableNames = props.variablesNames;
  const filter = props.filter;
  const setFilterParam = props.setFilterParam;
  const filterVariable = filter.variable;
  const filterPeriod = filter.time;
  const data = props.data;
  const filterType = filter.type;
  const filterIndex = props.filterIndex;

  const dispatch = useDispatch();
  const deleter = () => {
    dispatch(actions.deleteFilter(filterIndex));
  };

  // TODO: Abstract out filter types so they are stored in one place
  const isAggregation = ["mean", "total"].includes(filterType);
  const dataToUse =
    isAggregation && variableNames
      ? getSimSummaryStats(data, [filterVariable])[filterType].map(
          (row) => row[filterVariable]
        )
      : data
          .filter((row) => row.t === Number(filterPeriod))
          .map((row) => row[filterVariable]);
  let [rangeMin, rangeMax] = getArrayRange(dataToUse);
  // Current left and right positions of slider
  const [minMax, setMinMax] = useState([
    filter.min === null ? rangeMin : filter.min,
    filter.max === null ? rangeMax : filter.max,
  ]);
  useEffect(() => {
    setMinMax([
      filter.min === null ? rangeMin : filter.min,
      filter.max === null ? rangeMax : filter.max,
    ]);
  }, [filter.min, filter.max, rangeMin, rangeMax]);

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    // this event listener is a hack we're using b/c HistoSlider doesn't give us an "onChangeCommitted" prop.
    const setFilterParamsToSliderRange = () => {
      setFilterParam("min", minMax[0]);
      setFilterParam("max", minMax[1]);
    };
    ref.current.addEventListener("mouseup", setFilterParamsToSliderRange);
    ref.current.addEventListener("touchend", setFilterParamsToSliderRange);
    // Need to keep a copy in this scope to refer to in the cleanup function. When
    // it runs, the value of ref.current will likely have changed.
    const ele = ref.current;
    return () => {
      ele.removeEventListener("mouseup", setFilterParamsToSliderRange);
      ele.removeEventListener("touchend", setFilterParamsToSliderRange); // for mobile
    };
  }, [minMax, setFilterParam]);

  const handleSliderChange = (newValue) => {
    setMinMax(newValue);
  };

  var histGenerator = histogram().domain([rangeMin, rangeMax]).thresholds(10);

  const getHistData = (myData) => {
    const values = myData.filter((val) => val !== null);

    const histData = histGenerator(values).map((obj) => ({
      x0: obj.x0,
      x: obj.x1,
      y: obj.length,
    }));
    return histData;
  };

  return (
    <div style={{ marginBottom: 12, borderBottom: "solid #E5E5E5 2px" }}>
      <Fragment>
        <FilterControls
          config={filter}
          setter={setFilterParam}
          deleter={deleter}
        />
        <Container ref={ref}>
          {rangeMin === rangeMax && (
            <p>
              Only one value for this variable, so it cannot be used for
              filtering.
            </p>
          )}
          {filter.variable == null || (
            <Histoslider
              // An array of objects to create the histogram
              data={getHistData(dataToUse)}
              padding={20}
              // The extent of the selection, this doesn't have to be sorted (and you shouldn't sort it to store it)
              selection={minMax}
              formatLabelFunction={(num) =>
                num === null ? "" : format_number(num)
              }
              height={100}
              width={600}
              // A function to handle a change in the selection
              onChange={handleSliderChange}
            />
          )}
        </Container>
      </Fragment>
    </div>
  );
}
