import React from "react";
import { useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

import Card from "UI/components/Card";
import Title from "UI/components/Title";
import Wrapper from "UI/Wrapper";
import { FilterSelector } from "./FilterSelector";
import { AddButton } from "../Explorer";
import { EXPLORE_NUM_SIMS } from "api/constants";
import { actions } from "modelSlice";

const allEqual = (arr) => arr.every((v) => v === arr[0]);

function ScenarioCreator(props) {
  const filters = props.filters;
  const policies = props.policies.policyNames;
  const countsByPolicyId: number[] = props.countsByPolicyId;
  const data = props.data;
  const filtersSetter = props.filtersSetter;
  const variableNames = props.variableNames;

  const initialFilter = {
    time: 1,
    variable: variableNames[0],
    type: "point",
    max: null,
    min: null,
  };

  const addFilter = () => {
    const newFilters = [...filters, initialFilter];
    filtersSetter(newFilters);
  };
  const dispatch = useDispatch();
  const setFilterParam = (ixFilter) => (key, val) => {
    dispatch(actions.setFilterParam({ i: ixFilter, key, val }));
  };

  // props.data may be null if results are still being loaded.
  if (data === null) {
    /* TODO: we should probably follow a pattern similar to the OutcomeSelection
       stuff where whilst waiting for API data, we render the form controls but
       show a loading indicator for the components displaying the results.
       But in this case, even FilterSelector has a (spurious?) dependency
       on data, so we can't easily do so.
    */
    return null;
  }

  return (
    <Wrapper style={{ marginTop: 0 }}>
      <Title>Scenario Definition</Title>
      <Card style={{ padding: 12 }}>
        {filters.map((filter, ixFilter) => (
          <FilterSelector
            key={ixFilter}
            data={data}
            filter={filter}
            filters={filters}
            filtersSetter={filtersSetter}
            variablesNames={variableNames}
            setFilterParam={setFilterParam(ixFilter)}
            periodType={props.periodType}
            numPeriods={props.numPeriods}
            filterIndex={ixFilter}
          />
        ))}
        {filters.length === 0 ? null : allEqual(
            policies.map((p, i) => countsByPolicyId[i])
          ) ? (
          <p style={{ margin: 12 }}>
            Simulations meeting criteria: {countsByPolicyId[0]} (
            {(countsByPolicyId[0] / EXPLORE_NUM_SIMS) * 100}%)
          </p>
        ) : (
          <>
            <p style={{ margin: 12 }}>Simulations meeting criteria:</p>
            <ul>
              {policies.map((p, i) => (
                <li style={{ marginLeft: "48px" }}>
                  <i>{p}</i>: {countsByPolicyId[i]} (
                  {Math.round((countsByPolicyId[i] / EXPLORE_NUM_SIMS) * 100)}%)
                </li>
              ))}
            </ul>
          </>
        )}
        <AddButton
          aria-label="add"
          onClick={addFilter}
          size={"small"}
          style={{ marginTop: "12px" }}
        >
          <AddIcon />
        </AddButton>
      </Card>
    </Wrapper>
  );
}

export default ScenarioCreator;
