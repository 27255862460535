import { CsvDatum } from 'types';

export const formatAgg = (type, time) => {
  if (type === "mean") {
    return "Mean";
  } else if (type === "total") {
    return "Total";
  } else if (type === "point") {
    return "Period " + time;
  }
};

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatVarName = (name: string) => {
  return name.split("_").map(capitalize).join(" ");
};

/* Used to format any of:
- variables
- labels for datasets/external models
- row identifiers for dataset files
*/
export const formatIdentifier = formatVarName;

export const formatCsvDatum = (datum: CsvDatum): string => {
  if (typeof datum === "string") {
    return formatIdentifier(datum);
  }
  return String(datum);
}

export const varWithAggToName = (varWithAgg) =>
  formatAgg(varWithAgg.type, varWithAgg.time) +
  " " +
  formatVarName(varWithAgg.varName);
