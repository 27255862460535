import React, { useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";

import "./App.css";
import { ErrorBoundary } from "./ErrorBoundary";
import { withFirebase, useFirebase } from "./firebaseHelpers";
import { PasswordForgetPage } from "./components/Auth/ForgotPassword";
import { ExampleBar } from "./components/Examples/ExampleBar";
import { Top } from "./components/TopLinks";
import { theme } from "./theme";
import ModelsList from "./components/ModelsList";
import GAListener from "./Tracker";
import Simulator from "./components/Simulator/Simulator";
import AuthPage from "./components/Auth/AuthPage";
import { backend_url } from "./constants";

export const AuthUserContext = React.createContext(null);

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};

const BACKEND_URL = backend_url();

// https://stackoverflow.com/a/55370253
const useFirebaseAuthentication = (firebase) => {
  const [authUser, setAuthUser] = useState(null);
  const [authInProgress, setAuthInProgress] = useState(true);

  useEffect(() => {
    const unlisten = firebase.auth.onAuthStateChanged((authUser) => {
      authUser ? setAuthUser(authUser) : setAuthUser(null);
      setAuthInProgress(false);
    });
    return () => {
      unlisten();
    };
  });

  return [authInProgress, authUser];
};

function App(props) {
  console.debug("rendering App");
  const firebase = useFirebase();
  const [authInProgress, authUser] = useFirebaseAuthentication(firebase);

  return (
    <ThemeProvider theme={theme}>
      <AuthUserContext.Provider value={authUser}>
        <ErrorBoundary>
          <Router>
            <GAListener>
              {authInProgress ? null : !authUser ? (
                <AuthPage />
              ) : (
                <>
                  <Top />
                  <ExampleBar />
                  <Switch>
                    <Route
                      path="/example/:example_name"
                      children={
                        <>
                          <Simulator
                            buildMode={true}
                            backendUrl={BACKEND_URL}
                          />
                        </>
                      }
                    />
                    <Route
                      path="/explore/example/:example_name"
                      children={
                        <Simulator buildMode={false} backendUrl={BACKEND_URL} />
                      }
                    />
                    <Route
                      path="/explore/model/:model_id"
                      children={
                        <Simulator buildMode={false} backendUrl={BACKEND_URL} />
                      }
                    />
                    <Route
                      path="/model/:model_id"
                      children={
                        <Simulator buildMode={true} backendUrl={BACKEND_URL} />
                      }
                    />
                    <Route path={"/models"}>
                      <ModelsList />
                    </Route>
                    <Route
                      children={
                        <Simulator buildMode={true} backendUrl={BACKEND_URL} />
                      }
                    />
                  </Switch>
                </>
              )}
            </GAListener>
          </Router>
        </ErrorBoundary>
      </AuthUserContext.Provider>
    </ThemeProvider>
  );
}

export default withFirebase(App);
