import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import produce from "immer";

import TableDataHeader from "./TableDataHeader";
import TableData from "./TableData";
import { FormulaEditor } from "../FormulaEditor";
import { isEmpty } from "../../../../equals";
import { format_errors as formatFormulaErrors } from "../../../../errors";
import { actions } from "modelSlice";
import { RootState } from "store";
import { Policies } from "types";
import { TrashCan } from "../../../../UI/components/Icons";
import { plotConfig } from "components/Charting/plotConfig";

const useStyles = makeStyles(() => ({
  table: {
    borderSpacing: "8px",
    padding: "36px 24px 24px 36px",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    marginRight: 12,
    width: "100%",
  },
  attr: {
    position: "absolute",
    top: 12,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  opt: {
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "rotate(-90deg) translate(0, -300%)",
  },
  title: {
    color: "#818285",
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  tBody: {
    paddingLeft: 28,
  },
  scrollWrapper: {
    overflow: "auto",
    minHeight: "220px",
    position: "relative",
  },
}));

/* Return a version of the given Policies object having an extra 'empty'
   policy at the end (policy name and all formulas are the empty string).
*/
const embiggenPolicies = produce((draft) => {
  draft.policyNames.push("");
  const newFormulas = { arr: draft.attributes.map((_) => "") };
  draft.formulas.push(newFormulas);
});

type Props = {
  // may be null if we're loading API results
  formulaErrors: any;
  typeAheadWords: any;
};
export function PolicyTable(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // TODO: think about the case where model is empty or loading (should probably
  // be dealt with upstream?)
  const policies: Policies = useSelector(
    (state: RootState) => state.model.model.policies
  );
  /* We create a local 'shadow' copy of the policies we receive from the Redux store.
     Our shadow copy has an extra placeholder policy (initially with empty name and formulas)
     at the end.
     onChange events on text fields in the table will (immutably) update our shadow
     policies, but not send the update to the store. When we get a blur event, we will
     dispatch an action to sync the change to the store.
  */
  const [shadowPols, setShadowPols] = useState<Policies>(
    embiggenPolicies(policies)
  );
  useEffect(() => {
    setShadowPols(embiggenPolicies(policies));
  }, [policies]);

  const newPolInProgress = useMemo(() => {
    const lastIx = shadowPols.policyNames.length - 1;
    return (
      shadowPols.policyNames[lastIx] !== "" ||
      shadowPols.formulas[lastIx].arr.some((f) => f !== "")
    );
  }, [shadowPols.policyNames, shadowPols.formulas]);

  // TODO: this is ugly, ugly, ugly
  if (policies instanceof Array) {
    return <p>Uh oh, schema mismatch.</p>;
  }
  const { policyNames, attributes, formulas } = shadowPols;
  const formulaErrors = props.formulaErrors;

  // Given a function mutating a (shadow) Policies object, return a fn which
  // sets shadowPols to the result of that transformation (using immer to avoid
  // actual mutation)
  const shadowSetter = (draftFn) => (...args) => {
    setShadowPols(produce((draft) => draftFn(draft, ...args)));
  };
  // Reset last policy row to be entirely blank (as if we just called embiggenPolicies)
  const resetBufferPolicy = shadowSetter((draft) => {
    const lastIx = draft.policyNames.length - 1;
    draft.policyNames[lastIx] = "";
    draft.formulas[lastIx] = { arr: draft.attributes.map((_) => "") };
  });
  const deletePolicy = (i) => {
    // Special case for last ix. It's not in redux, so just update our local pols.
    if (i === policyNames.length - 1) {
      resetBufferPolicy();
    } else {
      dispatch(actions.deletePolicy(i));
    }
  };
  const policyDeleteCells = policyNames.map((_, i) =>
    // Don't show delete icon for final (placeholder) row, unless it has content
    i !== policyNames.length - 1 || newPolInProgress ? (
      <td key={"delete" + i} onClick={() => deletePolicy(i)}>
        <TrashCan />
      </td>
    ) : (
      <td />
    )
  );
  const setShadowPolicyName = shadowSetter((draft, iPol, name) => {
    draft.policyNames[iPol] = name;
  });
  const policyLabelColors = plotConfig.range.category;
  const policyNameCells = policyNames.map((polname, i) => (
    <TableData
      key={i}
      className="okay"
      // Add left border except on shadow row... shadow is also last row
      borderLeftColor={i < policyNames.length - 1 ? policyLabelColors[i] : ""}
    >
      <input
        value={polname}
        onChange={(e) => {
          setShadowPolicyName(i, e.target.value);
        }}
        onBlur={(e) => {
          const name = e.target.value;
          if (i === policyNames.length - 1) {
            // Don't dispatch an update if the name has been left blank (i.e. if
            // the user clicks the name field, then clicks out without having entered
            // anything.
            name.length > 0 && dispatch(actions.appendPolicy(name));
          } else {
            dispatch(actions.renamePolicy({ i, name }));
          }
        }}
      />
    </TableData>
  ));
  const setShadowFormula = shadowSetter(
    (draft, iPol: number, iAttr: number, formula: string) => {
      draft.formulas[iPol].arr[iAttr] = formula;
    }
  );
  const flushShadowFormula = (iPol: number, iAttr: number) => {
    const newVal = shadowPols.formulas[iPol].arr[iAttr];
    if (iPol === policyNames.length - 1) {
      // TODO: it seems like a bad idea to let this pass without complaint. Empty
      // policy names are liable to be confusing at best, and lead to bugs in the worst case.

      // Don't dispatch an action if the formula was left blank.
      newVal.length > 0 &&
        dispatch(actions.appendNamelessPolicy({ i: iAttr, formula: newVal }));
    } else {
      dispatch(
        actions.setFormula({
          policyIndex: iPol,
          attributeIndex: iAttr,
          formula: newVal,
        })
      );
    }
  };
  // TODO: Using indices as keys here is not really correct, since we can delete rows
  // from the middle. But we don't have a stable identifier at hand as part of the
  // actual policy data (the policy name is not appropriate, since it can be changed)
  // Doesn't seem to currently lead to serious issues in practice, but something to keep an eye on.
  const extantPolicyRows = formulas.map(({ arr }, iPol) => (
    <tr key={iPol}>
      {policyDeleteCells[iPol]}
      {policyNameCells[iPol]}
      {arr.map((formula, iAttr) => (
        <TableData key={`${iPol}-${iAttr}`} className="okay">
          <FormulaEditor
            typeAheadWords={props.typeAheadWords}
            val={formula}
            setVal={(val) => setShadowFormula(iPol, iAttr, val)}
            onBlur={() => flushShadowFormula(iPol, iAttr)}
          />
        </TableData>
      ))}
      {/* Extra (uneditable) formula cell for placeholder attribute column. */}
      <TableData disabled>
        <input disabled />
      </TableData>
    </tr>
  ));
  // Attribute (column) related callbacks. NB: we don't use the shadowPols approach
  // for attributes, but rather keep pending state in the internal state of the DOM
  // nodes for uncontrolled form components.
  const deleteAttribute = (i) => dispatch(actions.deleteAttribute(i));
  // May want to do some local validation/munging of attr names. e.g.
  // const newName = name.replace(/[\W_]+/g, "_").toLowerCase();
  // Also fairly desirable to enforce uniqueness of policy and attr names (especially
  // because we currently use these as keys in a few places when creating collections of nodes)
  const renameAttribute = (i, name) =>
    dispatch(actions.renameAttribute({ i, name }));
  const onNewAttribute = (event) => {
    const name = event.target.value;
    // Don't do anything if the cell was left empty.
    if (name === "") {
      return;
    }
    dispatch(actions.appendAttribute(name));
    // After pushing the new value to the redux store, we blank the input
    // so the new value doesn't continue to appear in the 'placeholder' location.
    event.target.value = "";
  };
  return (
    <div className={classes.scrollWrapper}>
      <span className={classes.title + " " + classes.attr}>
        decision attributes
      </span>
      <span className={classes.title + " " + classes.opt}>
        decision Options
      </span>
      <table className={classes.table}>
        <tbody className={classes.tBody}>
          {/* row of delete buttons for attribute columns */}
          <tr>
            <th />
            <th />
            {attributes.map((name, i) => (
              <th key={i} onClick={() => deleteAttribute(i)}>
                <TrashCan />
              </th>
            ))}
            <th />
          </tr>

          <tr>
            <td />
            <td className={"policy_name"} />

            {/* column headings with editable attribute names */}
            {attributes.map((name, i) => (
              <TableDataHeader
                key={i}
                className={"cell"}
                error={formulaErrors?.[name]}
                disabled={i > attributes.length}
              >
                <input
                  defaultValue={name}
                  onBlur={(e) => renameAttribute(i, e.target.value)}
                />
              </TableDataHeader>
            ))}
            {/* Empty column header for adding attribute */}
            <TableDataHeader className={"cell"}>
              <input onBlur={onNewAttribute} />
            </TableDataHeader>
          </tr>
          {extantPolicyRows}
        </tbody>
      </table>
      <div className={"errorMessage"}>
        {formulaErrors &&
          !isEmpty(formulaErrors) &&
          formatFormulaErrors(formulaErrors).map((errMessage, i) => (
            <p key={i}>{errMessage}</p>
          ))}
      </div>
    </div>
  );
}
