import React, { Fragment } from "react";
import Card from "../../../UI/components/Card";
import { histogramSpec } from "../../Charting/Histogram";
import { timeSeriesSpec } from "../../Charting/TimeSeriesCharts";
import { VegaLite } from "react-vega";

function GraphsSection(props) {
  const policies = props.policies.policyNames;
  const longNames = props.longNames;

  const addPolicyNames = (data) => {
    return data.map((row) =>
      showPolicies ? { ...row, option: policies[row.policyId] } : row
    );
  };

  const showPolicies = policies && policies.length > 0;
  const histogramData = addPolicyNames(props.histogramData);
  const timeSeriesData = addPolicyNames(props.timeSeriesData);
  const granularity = props.granularity;
  const rawVarNames = props.rawVarNames;

  longNames.map((variableName, index) =>
    timeSeriesSpec(
      timeSeriesData.filter((row) => row.varName === rawVarNames[index]),
      rawVarNames[index],
      showPolicies,
      granularity,
      policies,
      props.confidenceRegion,
    )
  );

  return (
    <Card style={{ padding: "24px" }}>
      {longNames.map((variableName, index) => (
        <Fragment key={variableName + "graphs"}>
          <VegaLite
            style={{ marginTop: 36, width: 500 }}
            spec={histogramSpec(
              histogramData,
              variableName,
              showPolicies,
              variableName,
              policies
            )}
          />

          {props.showTimeSeries && (
            <VegaLite
              style={{ marginTop: 36, width: 500 }}
              spec={timeSeriesSpec(
                timeSeriesData.filter(
                  (row) => row.varName === rawVarNames[index]
                ),
                rawVarNames[index],
                showPolicies,
                granularity,
                policies,
                props.confidenceRegion,
              )}
            />
          )}
        </Fragment>
      ))}
    </Card>
  );
}

export default GraphsSection;
