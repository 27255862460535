import React, { useEffect, useState } from "react";

import EnhancedTable from "./Table";
import { useAuthUser } from "App";
import { useFirebase } from "firebaseHelpers";

function createdOn(createdOn) {
  const d = new Date(createdOn.seconds * 1000);
  const month = d.toLocaleString("default", { month: "long" });
  const time = d.toLocaleString("default", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${month} ${d.getDate()}, ${d.getFullYear()} ${time}`;
}

function TableContainer(props) {
  const COLUMNS = [
    { name: "name", label: "Simulation Name" },
    { name: "created_at", label: "Created At" },
  ];
  const rows = props.docs.map((doc) => ({
    id: doc.id,
    data: {
      name: doc.data().model.decision,
      created_at: createdOn(doc.data().createdOn),
    },
    created_at_raw: doc.data().createdOn.seconds,
  }));

  return <EnhancedTable rows={rows} columns={COLUMNS} />;
}

function ModelListMain(props) {
  const { authUser, docs } = props;
  if (authUser) {
    return docs ? <TableContainer docs={docs} /> : null;
  }

  return <p>Log in to see your saved models.</p>;
}

export default function ModelsList(props) {
  const [docs, setDocs] = useState();
  const authUser = useAuthUser();
  const firebase = useFirebase();
  useEffect(() => {
    if (!authUser) return;
    console.log("authUser.uid:" + authUser.uid);
    firebase.db
      .collection("models")
      .where("owner_uid", "==", authUser.uid)
      .get()
      .then(function (querySnapshot) {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc);
        });
        setDocs(docs);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, [authUser, firebase]);

  return (
    <div>
      <p>
        {authUser &&
          `You're signed in as ${authUser.email}. These are your simulations:`}
      </p>
      <ModelListMain authUser={authUser} docs={docs} />
    </div>
  );
}
