import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { 
  Dialog, 
  DialogTitle,
  DialogContent, DialogContentText,
  DialogActions,
  Button,
 } from "@material-ui/core";

import { rollbarInfo } from "../../RollbarInit";
import { ErrorBoundary } from "../../ErrorBoundary";
import { VariablesTable } from "./Builder/VariablesEquations";
import { PolicyTable } from "./Builder/policyTable/PolicyTable";
import { EXAMPLES } from "../Examples/Examples";
import { RightSplitMain } from "./Builder/RightSide/RightSplitMain";
import TopCard from "./Builder/TopCard";
import Card from "UI/components/Card";
import Title from "UI/components/Title";
import Wrapper from "UI/Wrapper";
import { SimulationOutputTable } from "./Builder/SimulationOutputTable";
import { Explorer } from "./Explorer/Explorer";
import { useHistory } from "react-router-dom";
import * as constants from "api/constants";
import Datasets from "./Builder/Datasets";
import { ExternalModels } from "./Builder/ExternalModels";
import { getTypeAheadWords } from "./simUtils";
import { useFirebase } from "firebaseHelpers";
import { useAuthUser } from "App";
import { fetchModel, pushUpdate, pushFork, actions } from "modelSlice/slice";
import useMainApi from "api/useMainApi";

const GraphContainer = styled.div`
  width: 40%;
  margin: 0px 24px 24px 12px;
  box-sizing: border-box;
  overflow: auto;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Simulator = (props) => {
  const buildMode = props.buildMode;
  const firebase = useFirebase();
  const authUser = useAuthUser();
  const [changingPosition, setChangingPosition] = useState(false);
  const { example_name, model_id } = useParams();

  const dispatch = useDispatch();
  const modelState = useSelector((state) => state.model);
  const model = modelState.model;
  // watch for changes to model_id and example_name (i.e. the url params)
  useEffect(() => {
    if (example_name) {
      console.log("Switching to example with example_name:", example_name);
      dispatch(actions.setExample(example_name));
    } else if (!model_id) {
      console.log("Resetting model.");
      dispatch(actions.resetModel());
    } else {
      console.log("model_id changed. Fetching model with id ", model_id);
      // TODO: if there's a pending fetch, we should cancel it
      dispatch(fetchModel({ firebase, modelId: model_id })).then(() => {
        console.debug("Fetch complete. Attempting migration.");
        /* TODO: right now, we unconditionally fire this event, and in the reducer
          body, we check model.version and dip out if it's set to 1.0. Would be a bit
          nicer if we did the check here, to avoid dispatching a no-op action.
          This would mean we would need to unwrap the model obj from the resolved thunk.
          */
        dispatch(actions.migratePolicies());
      });
    }
  }, [model_id, dispatch, firebase, example_name]);

  // XXX: Creating these vars to ease transition from old state mgmt code to
  // redux. Eventually, the components should be selecting these fields themselves,
  // rather than receiving them as props.
  const {
    datasets,
    decision,
    dsVarCollections,
    externalModels,
    filters,
    granularity,
    mainVarCollections,
    periods,
    policies,
    selectedPolicies,
    timeRange,
    variables,
  } = model;
  const setVariables = (val) =>
    dispatch(actions.setVariables({ vars: val }));
  const setTimeRange = (val) =>
    dispatch(actions.setModelProperty({ field: "timeRange", value: val }));
  const setMainVarCollections = (val) =>
    dispatch(
      actions.setModelProperty({ field: "mainVarCollections", value: val })
    );
  const setSelectedPolicies = (val) =>
    dispatch(
      actions.setModelProperty({ field: "selectedPolicies", value: val })
    );
  const setDsVarCollections = (val) =>
    dispatch(
      actions.setModelProperty({ field: "dsVarCollections", value: val })
    );
  const filtersSetter = (val) =>
    dispatch(actions.setModelProperty({ field: "filters", value: val }));

  // ???
  if (example_name && !EXAMPLES[example_name]) {
    window.location.pathname = "";
  }

  const BACKEND_URL = props.backendUrl;
  const numSims = buildMode
    ? constants.SIMS_PER_REQUEST
    : constants.EXPLORE_NUM_SIMS;
  const [apiResults, apiStatus, apiProgress] = useMainApi(
    BACKEND_URL,
    numSims,
    example_name,
    model_id
  );

  const [showEquationHelp, setShowEquationHelp] = useState(false);

  const syncing = modelState.syncing;

  // What is this?
  const [saveInfo, setSaveInfo] = useState();

  const owner = modelState.owner_uid;
  const viewable = useSelector((state) => state.model.viewable);

  let history = useHistory();

  /* Creates a fork of the current model in FireStore and updates url to navigate
  to newly created fork.
  */
  const doFork = useCallback(() => {
    return dispatch(
      pushFork({
        firebase: firebase,
        userId: authUser.uid,
      })
    )
      .then(unwrapResult)
      .then((docRef) => {
        // TODO: brittle hardcoding of url structure
        const basePath = props.buildMode ? "/model/" : "/explore/model/";
        const path = basePath + docRef.id;
        history.push(path);
      });
  }, [firebase, authUser, dispatch, history, props.buildMode]);

  // TODO: this should probably be integrated with whatever code determines whether to
  // fork on change
  useEffect(() => {
    if (authUser) {
      if (model_id && authUser.uid === owner) {
        setSaveInfo(null);
      } else {
        setSaveInfo("Your changes will be saved as a new model.");
      }
    } else {
      setSaveInfo("Log in to save your changes.");
    }
    // TODO: check if model belongs to this user
  }, [model_id, authUser, owner]);

  useEffect(() => {
    document.title = `${decision || "Decision"} Simulation`;
  });

  const saveModel = () => {
    /*
    Cases to consider:
    - no logged in user -> do nothing
    - we're currently in the process of fetching model data -> do nothing?
      though if this is a 'legitimate' change to the model, we probably want
      to enqueue an update/fork?
    - no material changes to metamodel since last fetch/push -> do nothing
        we'll be in this state immediately after a successful fetch from FireStore,
        or upon first render when model is initialized with a blank model, or with
        an example. 
        Could perhaps address this by adding a dirty bit to modelSlice state.
        Alternatives? Guess we could have a ref that stores the previous value
        of model. Then we can do a deep comparison, and if they compare equal, don't
        fire an update/clone. (There's a minor benefit of this over the dirty bit
        approach. It's possible for model setter action to be dispatched which is
        effectively a no-op. In this case, we'd prefer not to fire a redundant sync.)
    - current model has no owner (i.e. it's either a blank slate model and we're
      at the root url, or it's an example model) -> push it to FireStore, and
      update url with newly created model_id when done
    - current model is owned by someone other than logged-in user -> fork the model
      (same procedure as above)
    - logged in user owns current model -> push update to FireStore
    */
    if (!authUser) {
      return;
    }
    if (modelState.status === "loading") {
      console.debug("Skipping potential push during loading phase.");
      return;
    }
    if (!modelState.dirty) {
      console.debug("Dirty bit not set, so skipping sync.");
      return;
    }
    if (syncing) {
      console.debug("Not attempting another sync mid-sync.");
      return;
    }
    if (model_id && owner === authUser.uid) {
      console.debug("Dispatching Firestore update");
      dispatch(pushUpdate({ firebase: firebase, modelId: model_id }));
    } else {
      // Create a new model
      console.log(`Forking (owner=${owner}, authUser.uid=${authUser.uid})`);
      doFork();
    }
  };
  useEffect(saveModel, [
    model,
    viewable,
    authUser,
    model_id,
    modelState.version,
    modelState.dirty,
    syncing,
    doFork,
    dispatch,
    firebase,
    owner,
    modelState.status,
  ]);

  function sendUserSimResults(mainSimResultsData) {
    // TODO: Sort by sim first rather than t first.
    // TODO: Replace policyId in file with Policy Name
    const simToCsv = (raw) => {
      const colNames = Object.keys(raw[0]);
      const fileHeader = colNames.join(",") + "\n";
      const inSimData = mainSimResultsData.filter((row) => row["t"] > 0);
      const dataRows = inSimData.map((row) =>
        colNames.map((myCol) => row[myCol]).join(",")
      );
      const data = dataRows.join("\n");
      return fileHeader + data;
    };
    const filename = "simulation_results.csv";
    const fileContent = encodeURI(simToCsv(mainSimResultsData));
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/json;charset=utf-8," + fileContent);
    element.setAttribute("download", filename);
    element.style.display = "none";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    rollbarInfo("User downloaded sim results");
  }

  const typeAheadWords = useMemo(
    () =>
      getTypeAheadWords(
        variables,
        policies,
        datasets,
        externalModels,
        apiResults.functionsAvailable || []
      ),
    [
      variables,
      policies,
      datasets,
      externalModels,
      apiResults.functionsAvailable,
    ]
  );

  useEffect(() => {
    const listener = () => {
      setChangingPosition(buildMode && window.pageYOffset >= 200);
    };
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [buildMode]);

  // TODO: Make this a component. The fact it isn't is just technical debt and issues about
  // breaking up the namespace between SImulator and SimEditor
  function editor() {
    return (
      <Fragment>
        <div className={"mainContainer"}>
          <div className="split left">
            <div
              className="centered"
              style={{
                width: "100%",
              }}
            >
              <TopCard
                saveInfo={saveInfo}
                ownsModel={authUser && owner && owner === authUser.uid}
              />

              <Wrapper>
                <Title>Variables</Title>
                <Card>
                  <VariablesTable
                    variables={variables}
                    setVariables={setVariables}
                    errors={apiResults.mainErrors}
                    serverError={apiStatus === "error"}
                    typeAheadWords={typeAheadWords}
                    setShowEquationHelp={setShowEquationHelp}
                  />
                </Card>
              </Wrapper>
              <Wrapper>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Title>{(model.decision || "") + " Options"}</Title>
                  </div>
                </div>
                <Card>
                  <PolicyTable
                    formulaErrors={apiResults.policyErrors}
                    typeAheadWords={typeAheadWords}
                  />
                </Card>
              </Wrapper>
              <Wrapper>
                <Title>Models</Title>
                <Card>
                  <ExternalModels modelId={model_id} />
                </Card>
              </Wrapper>
              <Wrapper>
                <Title>Data Files</Title>
                <Card>
                  <Datasets
                    modelId={model_id}
                    deps={apiResults.deps}
                    setShowEquationHelp={setShowEquationHelp}
                    typeAheadWords={typeAheadWords}
                    datasetResults={apiResults.datasetVarValuesExcerpt}
                    datasetErrors={apiResults.datasetErrors}
                  />
                </Card>
              </Wrapper>
              {/* Commenting out as an experiment based on beta tester feedback */}
              {/* <Wrapper>
                <Title> Sample Output </Title>
                <Card>
                  <SimulationOutputTable
                    mainSimResults={apiResults.mainSimResults}
                  />
                </Card>
              </Wrapper> */}
              <div className={"emptydiv"} />
            </div>
          </div>
          <GraphContainer changingPosition={changingPosition}>
            <Card style={{ padding: 24 }}>
              <RightSplitMain
                serverStatus={apiStatus}
                mainSimResults={apiResults.mainSimResults || []}
                deps={apiResults.deps}
                showEquationHelp={showEquationHelp}
                setShowEquationHelp={setShowEquationHelp}
              />
            </Card>
          </GraphContainer>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {buildMode && editor()}
      {!buildMode && (
        <ErrorBoundary>
          <Explorer
            mainVarCollections={mainVarCollections}
            setMainVarCollections={setMainVarCollections}
            dsVarCollections={dsVarCollections}
            setDsVarCollections={setDsVarCollections}
            filters={filters}
            filtersSetter={filtersSetter}
            selectedPolicies={selectedPolicies}
            setSelectedPolicies={setSelectedPolicies}
            variables={variables}
            numPeriods={periods}
            policies={policies}
            granularity={granularity}
            datasets={datasets}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            decision={decision}
            downloadSimResults={() =>
              sendUserSimResults(apiResults.mainSimResults)
            }
            simResults={apiResults.mainSimResults}
            backendUrl={BACKEND_URL}
            apiProgress={apiProgress}
          />
        </ErrorBoundary>
      )}
      <MissingModelDialog error={modelState.error} />
    </Fragment>
  );
};

const MissingModelDialog = ({error}) => {
  const history = useHistory();
  const onClose = () => {
    history.push("/");
  };
  return (
    <Dialog
      open={Boolean(error)}
      onClose={onClose}
    >
      <DialogTitle>Missing or inaccessible simulation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This simulation doesn't exist or you don't have permission to view it.
          If you followed a link to get here, you may want to ask the owner of the
          simulation to update its sharing settings.
        </DialogContentText>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default Simulator;
