import React from "react";
import styled from "@emotion/styled";

const Title = props => {
    return(
        <StyledTitle style={props.style}>
            {props.children}
        </StyledTitle>
    )
};

export default Title

const StyledTitle = styled.h3`
    margin: 0;
    color: #57585C;
    margin-bottom: 12px;
    font-size: 16px;
`;
