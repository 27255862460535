import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import GetAppIcon from "@material-ui/icons/GetApp";
import TimeSeriesControls from "../../Charting/TimeSeriesControls";

const useStyles = makeStyles({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dataTransferHeading: {
    paddingLeft: 16,
    marginBottom: 0,
  },
  buttons: {
    "& .MuiTypography-body1": {
      fontSize: 19,
    },
  },
  timeSeriesControls: {
    paddingLeft: 16,
    paddingRight: 16,
    "& .MuiTypography-body1, & .MuiFormLabel-root, & p": {
      fontSize: 19,
    },
    "& .MuiSlider-root": {
      width: "98%",
    },
  },
  drawerButton: {
    color: "#0079EE",
    background: "transparent",
    padding: "4px 8px",
    marginBottom: 4,
    textTransform: "none",
    "&:hover, &:active": {
      background: "transparent",
    },
  },
});

export default function AdvancedOptionsDrawer(props) {
  let [minT, maxT] = props.explorerTsGraphRange;
  minT = minT === null ? 1 : Number(minT);
  maxT = maxT === null ? props.numPeriods : Number(maxT);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={handleDrawerOpen}
        className={classes.drawerButton}
        variant="contained"
        disableElevation
      >
        Advanced Options
      </Button>
      <Drawer
        open={open}
        ModalProps={{
          onBackdropClick: handleDrawerClose,
          disableScrollLock: true,
          disableRestoreFocus: true,
          BackdropProps: { invisible: true },
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div role="presentation" className={classes.buttons}>
          <h2 className={classes.dataTransferHeading}>Data Transfer</h2>
          <List>
            <ListItem
              button
              key={"Download Simulation Results"}
              onClick={() => props.downloadSimResults()}
            >
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Download Simulation Results"} />
            </ListItem>
          </List>
          <Divider />
          <div className={classes.timeSeriesControls}>
            <h2>Time Series Graphs</h2>
            <FormControlLabel
              control={(
                <Checkbox checked={props.showTimeSeries}
                  onChange={(e) => {
                    props.setShowTimeSeries(e.target.checked);
                  }}
                />)
              }
              label="Show time series charts"
            />
            <TimeSeriesControls
              timeRange={[minT, maxT]}
              numPeriods={props.numPeriods}
              handleTimeMouseUp={props.handleTsRangeMouseUp}
              confidenceRegion={props.confidenceRegion}
              setConfidenceRegion={props.setConfidenceRegion}
            />
          </div>
        </div>
        <Divider />
      </Drawer>
    </>
  );
}
