import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import axios, { Canceler } from "axios";
import JSON5 from "json5";

import { selectModelPostData, makeDrilldownRequestDat } from "./munging";
import { RootState } from "store";

export default function useDrilldownApi(BACKEND_URL: string) {
  const modelPostData = useSelector(selectModelPostData);
  // NB: we assume at most one drilldown config
  const ddConfig = useSelector(
    (state: RootState) => state.model.model?.dsVarCollections?.[0]
  );
  // object mapping row index to variable name to array of values per policy
  const [drillData, setDrillData] = useState(null);
  const [loading, setLoading] = useState(false);
  const CancelToken = axios.CancelToken;
  const cancel = useRef<Canceler>();

  useEffect(() => {
    console.debug("Maybe fetching drilldown results");
    if (
      !ddConfig ||
      !ddConfig?.keyIndex?.length ||
      !ddConfig?.variableNames?.length
    ) {
      console.debug("No actionable drilldown config.");
      return;
    }
    console.log("Sending drilldown request");
    setLoading(true);
    cancel.current && cancel.current("prior DD request cancelled");
    const ddPostData = makeDrilldownRequestDat(ddConfig);
    // TODO: a bit brittle. Should probably be passing in the root url.
    const ddUrl = BACKEND_URL.slice(0, -1 * "main".length) + "drilldown";
    axios
      .post(
        ddUrl,
        {
          ...modelPostData,
          numSims: 50,
          numPreviousSims: 0,
          drilldown: ddPostData,
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel.current = c;
          }),
          transformResponse: (x) => {
            return JSON5.parse(x);
          },
        }
      )
      .then(
        (response) => {
          console.debug("Setting drill data to ", response.data);
          setDrillData(response.data);
        },
        (error) => {
          if (!axios.isCancel(error)) {
            console.error("Unexpected drilldown server error:", error);
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [modelPostData, ddConfig, BACKEND_URL, CancelToken]);
  return [drillData, loading];
}
