import { EXAMPLES } from "components/Examples/Examples";
import * as policyHelpers from "policyHelpers";
import modelReducers from "./modelReducers";
import { initialState } from "./state";

const reducers = {
  resetModel: (state, action) => {
    return initialState;
  },
  setExample: (state, action) => {
    const exampleName = action.payload;
    const model = { ...EXAMPLES[exampleName] };
    return {
      status: "idle" as const,
      syncing: false,
      model: model,
      example: {
        name: exampleName,
        description: EXAMPLES[exampleName].description,
      },
      dirty: false,
      version: 1.0,
    };
  },
  migratePolicies: (state, action) => {
    if (state.version) {
      console.log("Skipping redundant migration");
      return;
    }
    console.log("Upgrading policy data to schema version 1.0");
    if (state.model.policies) {
      state.model.policies = policyHelpers.migratePolicies(
        state.model.policies
      );
      console.log("New policies:", state.model.policies);
    }
    state.version = 1.0;
    state.dirty = true;
  },
  ...modelReducers,
};
export default reducers;
