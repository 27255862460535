// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React, { useContext } from "react";

export const config = {
  apiKey: "AIzaSyCVyozE0wXJ0ypFAmah4tk6ZcXESJf6tBY",
  authDomain: "dai-sim-app.firebaseapp.com",
  databaseURL: "https://dai-sim-app.firebaseio.com",
  projectId: "dai-sim-app",
  storageBucket: "dai-sim-app.appspot.com",
  messagingSenderId: "698187945110",
  appId: "1:698187945110:web:3996d494c686d01d12deb7",
  measurementId: "G-HNF38RMJEM",
};

export class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.firestore();
    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.storage = app.storage();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
}

export const FirebaseContext = React.createContext(null);

export const useFirebase = () => {
  return useContext(FirebaseContext);
};

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);
