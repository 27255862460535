// TODO: move this. Maybe into a top-level Policies/ directory under src? 
// or a Model/ directory?
export const attributeNamesFromPolicies = (policies) => {
  // policy_name is one of the key names but not one of the attribute names, so remove it to get attribute names
  return policies.length > 0
    ? Object.keys(policies[0]).filter((x) => x !== 'policy_name')
    : [];
};

/* This was intended to solve the issue that object property enumeration order
is a really brittle thing to rely on (for ordering of attributes), though it 
turns out to affect a lot of pieces of the app. Maybe the simpler solution would
have been to add an 'attributes' array with the canonical attribute order, keeping
the same policies object as the source of policy names and formulas.
*/
export const migratePolicies = (policies) => {
  const policyNames = policies.map( pol => pol.policy_name);
  const attributes = attributeNamesFromPolicies(policies);
  const formulas = policies.map( pol => (
    // Ugggh, firestore doesn't support directly nested arrays. Who knows why.
    // So we'll wrap the inner arrays in dummy objects
    // Could maybe use an adapter (as provided by redux-toolkit) to work around this?
    // Would be nice to hide this hack from component code that has to interact with policies
    {arr: attributes.map( attr => pol[attr])}
  ));
  return {policyNames, attributes, formulas};
};

/* Performs the reverse of the transformation in migratePolicies.
*/
export const demigratePolicies = (policies) => {
  const {policyNames, attributes, formulas} = policies;
  if (!policyNames) {
    // Hack
    return;
  }
  return policyNames.map( (polname, ipol) => {
    const pol_obj = {policy_name: polname};
    attributes.forEach( (attr, iatt) => {
      pol_obj[attr] = formulas[ipol].arr[iatt];
    });
    return pol_obj;
  });
};
