import { useMemo, useRef, useReducer, useEffect } from 'react';
import JSON5 from "json5";
import { useSelector } from 'react-redux';
import axios, { Canceler } from "axios";

import { deepEqual } from 'equals';
import { rollbarError } from "RollbarInit";
import * as constants from "./constants";
import {
  PartialApiResults,
  ApiResults,
  SimResultRow,
} from './types';
import {
  formatResponseSimData,
  parsePartialResultsFromResponse,
  selectModelPostData,
} from "./munging";
import {
  selectNumDatasets,
  selectNumExternalModels,
} from 'modelSlice/selectors';
import recordEvent from 'eventRecording';
import { useAuthUser } from 'App';


type ApiState = {
  // Whether we have a request in flight for the current goal
  loading: boolean;
  // model data for which we want results
  modelDat: any;
  results?: PartialApiResults;
  // The ith sub-array corresponds to the mainSimResults for the ith request
  mainSimResults: SimResultRow[][];
  // Number of requests loaded. Should be equal to this.mainSimResults.length
  // NB: it is entirely possible for this to be greater than reqsSought. We
  // keep around surplus results for easy toggling between build and explore.
  reqsLoaded: number;
  // how many requests we must make to achieve number of sims sought
  reqsSought: number;
  /* Set when we get an error from the server. As a failsafe, once the error
     flag has been set, we won't send any more API requests. Without this guard,
     there's a possibility of getting stuck in an infinite loop of firing off
     API requests (because our model always 500's, so reqsLoaded never reaches
     reqsSought).
     Reset to false when modelDat changes.
  */
  error: boolean;
}

// Set to true for verbose debugging of reducer calls
const DEBUG = false;

const reducer = (state: ApiState, action) => {
  DEBUG && console.debug("Reducing action with type ", action.type, " and payload ",
                         action.payload, " where current state = ", state,
                        );
  const payload = action.payload;
  let newState;
  switch (action.type) {
    case "setGoal":
      const { modelDat, numSims } = payload;
      const modelDiffers = !modelDatEqual(modelDat, state.modelDat);
      const nReqs = numSims / constants.SIMS_PER_REQUEST;
      if (!modelDiffers) {
        return {
          ...state,
          reqsSought: nReqs,
        };
      } else {
        return {
          // Even if we had a request in flight, it's no longer relevant to our goal
          loading: false,
          modelDat,
          results: null,
          mainSimResults: [],
          reqsLoaded: 0,
          reqsSought: nReqs,
          error: false,
        };
      }
    case "requestInitiated":
      if (!modelDatEqual(payload.modelDat, state.modelDat)) {
        console.log("Ignoring requestInitiated action for stale model.");
        return state;
      }
      newState = {
        ...state,
        loading: true,
      };
      DEBUG && console.debug("Setting new state to ", newState);
      return newState;
    case "apiResponse":
      if (!modelDatEqual(payload.modelDat, state.modelDat)) {
        console.log("Ignoring request that came in for stale model.");
        return state;
      }
      const response = payload.response;
      const main = formatResponseSimData(response.data.mainSimResults);
      newState = {
        ...state,
        loading: false,
        reqsLoaded: state.reqsLoaded + 1,
        mainSimResults: [...state.mainSimResults, main],
      };
      // Set state.results if it's null (which should mean this is the first req to arrive)
      if (newState.results === null) {
        console.assert(state.reqsLoaded === 0);
        const partial = parsePartialResultsFromResponse(response);
        newState.results = partial;
      }
      DEBUG && console.debug("Setting new state to ", newState);
      return newState;
    case "setError":
      return {
        ...state,
        error: true,      
      };
  }
};

const getFlattenedMainSimResults = (state: ApiState): (SimResultRow[] | null) => {
  if (state.reqsLoaded === 0) {
    return null;
  }
  // NB: we're implicitly assuming that reqsSought can only take on the values
  // 1 or one other fixed constant > 1 (currently 5)
  if (state.reqsSought === 1) {
    console.assert(state.mainSimResults.length > 0);
    return state.mainSimResults[0];
  }
  if (state.reqsSought > state.reqsLoaded) {
    // We don't expose partial results. It's all or nothing.
    return null;
  }
  console.assert(state.mainSimResults.length === state.reqsSought,
                 {length: state.mainSimResults.length, reqsSought: state.reqsSought},
                );
  return state.mainSimResults.flat();
};
const getProgress = (state: ApiState): number => {
  if (state.reqsSought === 0) {
    return 1;
  }
  return state.reqsLoaded / state.reqsSought;
};
const INITIAL_STATE = {
  loading: false,
  modelDat: null,
  results: null,
  mainSimResults: [],
  reqsLoaded: 0,
  reqsSought: 0,
  error: false,
};
const modelDatEqual = (m1, m2) => {
  return deepEqual(m1, m2);
}

const apiPromiseForModelDat = (modelDat, numPrevRequests: number, url, cancelRef) => {
  const postDat = {
    ...modelDat,
    numSims: constants.SIMS_PER_REQUEST,
    numPreviousSims: numPrevRequests * constants.SIMS_PER_REQUEST,
  };
  return axios
    .post(
      url,
      postDat,
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelRef.current = c;
        }),
        transformResponse: (x) => {
          return JSON5.parse(x);
        },
      }
    )
};

export type Status = (
  "quiescent" // Nothing loaded and nothing to load
  | "loading"
  | "semiloaded" // we have PartialApiResults available, not not full mainSimResults
  | "loaded"
  | "error"
);
const statusForState = (state: ApiState): Status => {
  if (state.error) {
    return "error";
  }
  if (state.reqsSought === 0) {
    return "quiescent";
  }
  const shortfall = state.reqsSought - state.reqsLoaded;
  if (shortfall === 0) {
    return "loaded";
  }
  if (state.reqsLoaded > 0) {
    return "semiloaded";
  }
  return "loading";
}

/* Fetches results from the /main API endpoint for the current model (as
   it exists in Redux), and the given numSims.
   Exposes a 3-tuple to callers of type:
     [ ApiResults?, Status, number ]
   If status is "loaded", the first element is guaranteed to be a complete ApiResults
   object.
   If status is "semiloaded", then the mainSimResults member will be null, but all
   other fields will be present.
   Otherwise, any fields may be missing or null.
   The final element of the tuple represents progress towards fetching the results,
   and will be a number in the range [0, 1].
*/
export default function useMainApi(
  BACKEND_URL: string,
  numSims: number,
  example_name?: string,
  model_id?: string,
) {

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const numDatasets = useSelector(selectNumDatasets);
  const numExternalModels = useSelector(selectNumExternalModels);
  const authUser = useAuthUser();
  const status: Status = useMemo(
    () => statusForState(state),
    [state],
  );
  const modelPostData = useSelector(selectModelPostData);
  // NB: should be possible to use same token for multiple reqs
  const cancel = useRef<Canceler>();

  // When underlying model changes, cancel any pending requests.
  useEffect(() => {
    if (!modelDatEqual(modelPostData, state.modelDat)) {
      console.debug("Got model change. Cancelling pending stale api requests");
      cancel.current && cancel.current("Pending stale api requests cancelled.");
    }
  }, [
    state.modelDat,
    modelPostData,
  ]);

  // Ensure that the current 'goal' reified in state matches current received information
  // about model and number of sims sought.
  useEffect(() => {
    console.debug("Updating api goal");
    dispatch({
      type: "setGoal",
      payload: {
        modelDat: modelPostData,
        numSims,
      },
    });
  }, [
    modelPostData,
    numSims,
  ]);

  // Interrogate state object to fire off any necessary requests.
  useEffect(() => {
    console.debug("Checking whether we need to fire off any further requests.");
    if (state.error) {
      console.warn("Error flag set. Bailing out.");
      return;
    }
    if (state.loading) {
      console.debug("Have a request in flight, so doing nothing.");
      return;
    }
    if (state.reqsLoaded >= state.reqsSought) {
      console.debug("Have enough results loaded, so doing nothing.");
      return;
    }
    // Record an analytics event for the first server request per batch
    if (state.reqsLoaded === 0) {
      // brittle
      const mode = window.location.pathname.includes("explore") ? "explore" : "build";
      recordEvent('serverCall', {
        model_id,
        example_name,
        numDatasets,
        numExternalModels,
        mode,
        uid: authUser?.uid,
      });
    }
    const prevRequests = state.reqsLoaded;
    const prom = apiPromiseForModelDat(state.modelDat, prevRequests, BACKEND_URL, cancel);
    dispatch({
      type: "requestInitiated",
      payload: {
        modelDat: state.modelDat,
      },
    });
    prom.then(
      (response) => {
        dispatch({
          type: "apiResponse",
          payload: {
            modelDat: state.modelDat,
            response,
          },
        });
      },
      (error) => {
        if (axios.isCancel(error)) {
          console.debug("Cancellation error observed: ", error);
        } else {
          console.error(error);
          rollbarError("server error");
          dispatch({type: "setError"});
        }
      },
    );
  }, [
    state,
    BACKEND_URL,
    example_name,
    model_id,
    numDatasets,
    numExternalModels,
    authUser,
  ]);

  // When component unmounts, cancel any pending requests.
  useEffect( () => {
    return () => {
      // This raises a linter warning ("The ref value cancel.current will likely
      // have changed..."), which we can safely ignore since the ref does *not*
      // point to a node.
      cancel.current && cancel.current("Cancelling pending requests on unmount");
    };
  }, []);

  /* TODO: this is going to yield a different object (in terms of identity) on
     every call, even though the contents of the object will often be unchanged
     (e.g. in the explore mode case, our results will be unchanged between when
     the first API response comes in, and the last one. But in that interval, we
     will have something like 7 state updates as intermediate requests are 
     dispatched and come in).
     Doing some kind of caching/memoization may help components consuming these
     results avoid extra cycles.
  */
  const finalResults: ApiResults = {
    ...state.results,
    mainSimResults: getFlattenedMainSimResults(state),
  };

  const progress = getProgress(state);

 return [
   finalResults,
   status,
   progress,
 ];
}
