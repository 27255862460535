import React from "react";
import { StyledCard, Content, StyledP } from "./Styles";
import Dialog from "@material-ui/core/Dialog";
import Title from "../../UI/components/Title";
import { PasswordForgetForm } from "./ForgotPassword";

const PasswordForgetModal = (props) => {
  return (
    <Dialog open={true} BackdropProps={{ invisible: true }}>
      <Content>
        <Title>Forgot Password</Title>
        <StyledCard>
          <PasswordForgetForm closeModal={props.onClose} />
        </StyledCard>
      </Content>
    </Dialog>
  );
};

export default PasswordForgetModal;
