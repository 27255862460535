import Rollbar from "rollbar";

const TOKEN = "973d6510a83842f09b38266ac922cf79";

function initRollbar() {
  let rollbar = null;
  let env = null;
  const code_version = process.env.REACT_APP_GIT_SHA;

  if (process.env.NODE_ENV === 'production') {
    env = 'prod';
  } else if (process.env.REACT_APP_USE_ROLLBAR === '1') {
    env = 'dev'
  }

  if (env) {
    rollbar = new Rollbar({
      accessToken: TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: env,
      code_version: code_version,
      source_map_enabled: true,
      sourceMapEnabled: true,
      codeVersion: code_version,

      payload: {
        client: {
          javascript: {
            code_version: code_version,
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      },


      config: {
        accessToken: TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,

        payload: {
          client: {
            javascript: {
              code_version: code_version,
              source_map_enabled: true,
              guess_uncaught_frames: true
            }
          }
        }
      }
    });
  }

  return rollbar
}

const ROLLBAR = initRollbar()

export const rollbarError = (error) => {
  if (ROLLBAR) {
    ROLLBAR.error(error)
  }
};

export const rollbarInfo = (message) => {
  if (ROLLBAR) {
    ROLLBAR.info(message)
  }
};
