import React, { memo } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { format_number } from "../Builder/DataOutputTable";
import { formatVarName } from 'formatters';
import { Policies, DrilldownConfig } from 'types';
import {selectDatasetsByLabel} from 'modelSlice/selectors';
import {useSelector} from "react-redux";

type LbProps = {
  policies: Policies;
  // array of policy indices
  selectedPolicies: number[];
  drillData?: object;
  config: DrilldownConfig;
  // label of the dataset column used to key rows
  keyColumn: string;
}
function DrilldownLeaderboard({
  policies,
  selectedPolicies,
  drillData,
  config,
  keyColumn,
}: LbProps) {
  const dataset = useSelector(selectDatasetsByLabel)[config.dataset];
  if (!drillData) {
    // TODO: better handling of loading state
    // In particular, need to better handle the case where drillData exists
    // but is stale wrt config. For now, I guess just nuke drillData when 
    // the ddConfig changes? The problem with this is that the change to the
    // config happens faster than that (i.e. there's a render between that change
    // and the nuking of drillData). Longer term solution would be to have a state
    // variable (in whatever hook/component that handles fetching the drildown results)
    // tracking whether we're in a loading state. When that's done, we can get rid of
    // some of the hacky optional chaining below related to flatRowDat
    return <span>Loading...</span>;
  }
  // If there are 0 explicit policies specified, we treat this as a single
  // implicit policy.
  let selectedPolicyNames;
  if (!policies?.policyNames?.length) {
    // TODO: in this case, would maybe be a little clearer to just remove the 
    // 'subhead' row altogether (and move the key column up to the main header)
    selectedPolicies = [0];
    selectedPolicyNames = [""];
  } else {
    selectedPolicyNames = selectedPolicies.map( ix => policies.policyNames[ix] );
  }
  const rowLabels = config.keyIndex.map( i => dataset.keyVals[i] );

  const columnAlign = selectedPolicies.length === 1 ? 'left' : 'center';
  let headingPrefix: string;
  switch (config.type) {
    case 'mean':
      headingPrefix = 'Mean';
      break;
    case 'total':
      headingPrefix = 'Total';
      break;
    case 'point':
      headingPrefix = 'Period ' + config.time;
      break;
  }
  const headingLabels = config.variableNames.map( (varname) => (
    headingPrefix + ' ' + formatVarName(varname)
  ));
  const mainHeading = (
    <TableRow>
      <TableCell />
      {headingLabels.map( (label) => (
        <TableCell key={label} colSpan={selectedPolicies.length} align={columnAlign}>
          { label }
        </TableCell>
      ))}
    </TableRow>
  );

  const polHeadingLabels = config.variableNames.map( _ => selectedPolicyNames).flat();
  const subHeadLabels = [keyColumn].concat(polHeadingLabels);
  const subHead = (
    <TableRow>
      { subHeadLabels.map( (label, i) => (
        <TableCell key={i} align={columnAlign}>{ label }</TableCell>
      )) }
    </TableRow>
  );

  // Array of numerical values per row (flattened over the variable dimension)
  const flatRowDat = config.keyIndex.map( rowIx => (
    config.variableNames.map( varName => (
      selectedPolicies.map(polIx => drillData[rowIx]?.[varName]?.[polIx])
    )).flat()
  ));
  const bodyRows = Object.entries(drillData).map( ([rowIx, varValues], i) => (
    <TableRow hover tabIndex={-1} key={i}>
      <TableCell component="th" scope="row">{ rowLabels[i] }</TableCell>
      { flatRowDat[i]?.map( (val, j) => (
        <TableCell key={j} align={columnAlign}>
          { format_number(val) }
        </TableCell>
      ))}
    </TableRow>
  ));


  return (
    <Table>
      <TableHead>
      { mainHeading }
      { subHead }
      </TableHead>
      <TableBody>
        { bodyRows }
      </TableBody>
    </Table>
  );
}

export default memo(DrilldownLeaderboard);
