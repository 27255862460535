import React from "react";
import styled from "@emotion/styled";

const LabelTable = (props) => {
  return <StyledTd>{props.children}</StyledTd>;
};

export default LabelTable;

const StyledTd = styled.td`
  color: #57585c;
`;
