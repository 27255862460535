import React, { useState } from "react";
import { VegaLite } from "react-vega";
import { VisualizationSpec } from "vega-embed";
import { plotConfig } from "./plotConfig";
import { formatVarName } from "formatters";
import {
  selectNumPeriods,
  selectPolicyNames,
  selectValidVariables,
} from "modelSlice/selectors";

import "./Charts.css";
import { SimResultRow } from "api/types";
import {useSelector} from "react-redux";

export const histogramSpec = (
  data: SimResultRow[],
  x: string,
  showPolicies: boolean,
  policyNames: string[]
): VisualizationSpec => {
  const xs = data.map((row) => row[x]);
  const uniqueVals = new Set(xs).size;
  const plotType = uniqueVals <= 10 ? "bar" : "area";
  const myMaxBins = plotType === "bar" ? 10 : 60;
  let max = Math.max(...xs);
  let min = Math.min(...xs);

  max = max + 0.001 * Math.abs(max);
  min = min - 0.001 * Math.abs(min);

  const out = {
    $schema: "https://vega.github.io/schema/vega-lite/v4.json",
    config: {
      axis: {
        titleFontSize: 14,
        titleFont: "Avenir-medium",
      },
      view: {
        continuousHeight: 100,
        continuousWidth: 350,
      },
    },
    facet: {
      row: {
        field: "option",
        type: "nominal",
        sort: policyNames,
        header: { title: null, labels: false },
      },
    },
    data: {
      values: data,
    },
    spec: {
      encoding: {
        color: showPolicies
          ? {
              field: "option",
              type: "nominal",
              sort: policyNames,
              legend: {
                titleFontSize: 12,
                labelFontSize: 12,
              },
              scale: { range: plotConfig.range.category },
            }
          : {},
        x: {
          bin: { extent: [min, max], maxbins: myMaxBins },
          field: x,
          type: "quantitative",
          title: formatVarName(x),
          axis: {
            labelFontSize: 14,
          },
        },
        y: {
          aggregate: "count",
          type: "quantitative",
          stack: null,
          title: "Probability",
          axis: {
            labels: false,
          },
        },
      },
      mark: {
        opacity: 0.9,
        type: plotType,
      },
    },

    usermeta: {
      embedOptions: {
        theme: "default",
      },
    },
  };
  // TODO: we're bulldozing over the type checker here because without this
  // assertion we get some spurious complaints resulting from type widening.
  // There are syntactic tricks to defeat this (e.g. we could replace
  // type: "nominal" with type: ("nominal" as "nominal")) but this would be
  // tedious to do in all the necessary places above.
  // The further inner cast to unknown is to silence a warning about the cast.
  return (out as unknown) as VisualizationSpec;
};

type HistProps = {
  computing: boolean;
  data: SimResultRow[];
};
export const Histograms = (props: HistProps) => {
  const policies = useSelector(selectPolicyNames);
  const numPeriods = useSelector(selectNumPeriods);
  const showPolicies = policies.length > 0;
  const data = props.data;
  // Which point in time are we visualizing
  const [time, setTime] = useState(numPeriods);
  const [timeInputValue, setTimeInputValue] = useState(numPeriods.toString());

  const dataAtT = data
    .filter((row) => row.t === time)
    .filter((row) => !showPolicies || row.policyId < policies.length) // This is a hack to prevent race condition when a policy is removed.
    .map((row) =>
      showPolicies ? { ...row, option: policies[row.policyId] } : row
    );

  const variables = useSelector(selectValidVariables);
  return (
    <>
      <p>
        Time shown:
        <input
          value={timeInputValue}
          onChange={(e) => {
            /* If they type something weird in this field, we'll go along with
               it, but time will get set to NaN (or 0 if they leave the field
               empty).
            */
            setTimeInputValue(e.target.value);
            setTime(Number(e.target.value));
          }}
          type={"number"}
        />
      </p>

      {props.computing && (
        <div>
          <p />
          <p />
          <strong style={{ paddingTop: "48px" }}>Computing...</strong>
        </div>
      )}
      <div>
        {variables.map((variable) => (
          <VegaLite
            key={variable.short_name}
            style={{ marginTop: 24, width: 500 }}
            spec={histogramSpec(
              props.computing ? [] : dataAtT,
              variable.short_name,
              showPolicies,
              policies
            )}
          />
        ))}
      </div>
    </>
  );
};
