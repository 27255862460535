import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Input from "UI/components/StyledInput";
import { FileDropzone } from "./FileDropzone/FileDropzone";

const toValidIdentifier = (s) => s.replace(/[\W_]+/g, "_").toUpperCase();

/* 
  For an existing Dataset or ExternalModel, shows the label and a file upload
  affordance, for replacing the file associated with that Dataset/Model.
  Can also serve as a placeholder for instantiating a new Dataset/EM, by entering
  a label and uploading a file.
  (It's questionable whether the same component should be serving both these functions)
*/
interface Props {
  modelId: string;
  label: string;
  // the underlying obj *collection* (Datasets or ExternalModels)
  metadata: any;
  // setter for collection
  setMetadata: any;
  fileType: "dataset" | "model";
  style?: any;
}
export const FileSectionHead = ({
  modelId,
  label,
  metadata,
  setMetadata,
  fileType,
  style,
}: Props) => {
  const [enteredLabel, setEnteredLabel] = useState(label || "");

  useEffect(() => {
    setEnteredLabel(label);
  }, [label]);

  const handleEnteredLabelChange = (e) => {
    setEnteredLabel(toValidIdentifier(e.target.value));
  };

  return (
    <FileSectionHeadWrapper style={style}>
      {label === "" ? (
        <Input
          value={enteredLabel}
          onChange={handleEnteredLabelChange}
          placeholder={"File Label"}
          type="text"
        />
      ) : (
        label
      )}

      <FileDropzone
        modelId={modelId}
        label={enteredLabel}
        fileType={fileType}
        metadata={metadata}
        setMetadata={setMetadata}
      />
    </FileSectionHeadWrapper>
  );
};

const FileSectionHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`;
