import React from "react";
import { DeleteOutlined, DragIndicator, SwapVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  icon: {
    cursor: "pointer",
  },
}));

export const TrashCan = () => {
  const classes = useStyles();
  return (
    <DeleteOutlined
      htmlColor="#57585c" // Could be "#0079ee"
      className={classes.icon}
      fontSize="small"
    />
  );
};

export const DragHandle = () => {
  const classes = useStyles();
  return (
    <DragIndicator
      htmlColor="#57585c"
      className={classes.icon}
      fontSize="small"
    />
  );
};

export const UpDownArrows = () => {
  const classes = useStyles();
  return (
    <SwapVert
      htmlColor="#57585c"
      className={classes.icon}
      fontSize="small"
    />
  );
};