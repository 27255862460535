import { readString } from "react-papaparse";

export const parseCSV = (content: string) => {
  const out = readString(content, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  })["data"];
  return out;
};

export const getCsvSummary = (fileContent) => {
  const fileData = parseCSV(fileContent);
  const fileColumnNames = Object.keys(fileData[0]);
  const fileKeyName = fileColumnNames[0];
  const fileKeyVals = fileData.map((row) => row[fileKeyName]);
  const csvSummary = {
    keyName: fileKeyName,
    keyVals: fileKeyVals,
    columnNames: fileColumnNames,
  };
  return csvSummary;
};
