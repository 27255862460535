import React, { Fragment, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { VegaLite } from "react-vega";
import { plotConfig } from "./plotConfig";
import "./Charts.css";
import TimeSeriesControls, { ConfidenceRegion } from "./TimeSeriesControls";
import { formatVarName } from "formatters";
import {
  selectPolicyNames,
  selectTimeRange,
  selectGranularity,
  selectNumPeriods,
  selectValidVariables,
} from "modelSlice/selectors";
import { SimResultRow } from "api/types";
import { actions } from "modelSlice";

export const timeSeriesSpec = (
  data,
  varName,
  showPolicies,
  granularity,
  policyNames,
  confidenceRegion: ConfidenceRegion,
) => {
  const spec = {
    $schema: "https://vega.github.io/schema/vega-lite/v4.0.2.json",
    autosize: { resize: true },
    config: {
      axis: {
        titleFontSize: 14,
        titleFont: "Avenir-medium",
      },
      view: {
        continuousHeight: 250,
        continuousWidth: 300,
      },
    },
    data: {
      values: data,
    },

    layer: [],
  };

  const legendConfig = {
    titleFontSize: 12,
    labelFontSize: 12,
    titleFontWeight: 400,
  };

  if (confidenceRegion !== null) {
    const distributionLayer = {
      encoding: {
        color: showPolicies
          ? {
              field: "option",
              type: "nominal",
              sort: policyNames,
              legend: legendConfig,
              scale: { range: plotConfig.range.category },
            }
          : {},
        x: {
          field: "t",
          title: `${granularity}`,
          type: "quantitative",
        },
        y: {
          field: "lowerBound",
          type: "quantitative",
          title: formatVarName(varName),
          scale: {
            zero: false,
          },
        },
        y2: {
          field: "upperBound",
          type: "quantitative",
        },
      },
      mark: {
        opacity: 0.3,
        type: "area",
      },
    };
    spec.layer.push(distributionLayer);
  }

  const meanLayer = {
    encoding: {
      color: showPolicies
        ? {
            field: "option",
            type: "nominal",
            sort: policyNames,
            legend: legendConfig,
            scale: { range: plotConfig.range.category },
          }
        : {},
      x: {
        field: "t",
        title: `${granularity}`,
        type: "quantitative",
        axis: {
          labelFontSize: 14,
        },
      },
      y: {
        field: "mean",
        type: "quantitative",
        title: formatVarName(varName),
        scale: {
          zero: false,
        },
        axis: {
          labelFontSize: 14,
        },
      },
    },
    mark: "line",
  };
  spec.layer.push(meanLayer);

  return spec;
};

const getMean = (data) => {
  const notNullData = data.filter((v) => v !== null);
  const total = notNullData.reduce((acc, c) => acc + c, 0);
  return total / notNullData.length;
};

const getPercentile = (dataArray, percentiles) => {
  const sortedArray = [...dataArray].sort((a, b) => a - b);
  var locs = percentiles.map(
    (p) => Math.round((dataArray.length * p) / 100) - 1
  );
  return locs.map((l) => sortedArray[l]);
};

// Data to graph for a particular time, var, and policy
type SummaryStatItem = {
  t: number;
  varName: string;
  policyId: number;
  mean: number;
  lowerBound?: number;
  upperBound?: number;
};
export const getSummaryStats = (
  data: SimResultRow[],
  policies: string[],
  varNames: string[],
  myMinT: number,
  myMaxT: number,
  confidenceRegion: ConfidenceRegion,
): SummaryStatItem[] => {
  var out = [];
  var thisTime = [];

  const policyWithHack = policies.length > 0 ? policies : ["this is stupid"];

  for (let t = myMinT; t <= myMaxT; t++) {
    thisTime = data.filter((row) => row.t === t);
    for (let policyId = 0; policyId < policyWithHack.length; policyId++) {
      let thisTimeAndPolicy = thisTime.filter(
        (row) => row.policyId === policyId
      );
      varNames.forEach((varName) => {
        let thisTimeAndPolicyAndVar = thisTimeAndPolicy.map(
          (row) => row[varName]
        );
        let mean = getMean(thisTimeAndPolicyAndVar);
        const datum: SummaryStatItem = {
          t: t,
          varName: varName,
          policyId: policyId,
          mean: mean,
        };
        if (confidenceRegion !== null) {
          const [lower, upper] = getPercentile(thisTimeAndPolicyAndVar, 
            [confidenceRegion[0], confidenceRegion[1]],
          );
          datum.lowerBound = lower;
          datum.upperBound = upper;
        }
        out.push(datum);
      });
    }
  }
  return out;
};

type Props = {
  data: SimResultRow[];
  computing: boolean;
};
export function TimeSeriesChart(props: Props) {
  const granularity = useSelector(selectGranularity);
  const [minT, maxT]: [number, number] = useSelector(selectTimeRange);
  const dispatch = useDispatch();
  const setTimeRange = (val: [number, number]) => {
    dispatch(actions.setModelProperty({ field: "timeRange", value: val }));
  };
  // Either a 2-array of lower and upper percentiles to graph, or null to show no
  // confidence region.
  const [confidenceRegion, setConfidenceRegion] = useState<ConfidenceRegion>(null);

  const policies = useSelector(selectPolicyNames);
  const numPeriods = useSelector(selectNumPeriods);
  const showPolicies = policies && policies.length > 0;

  const variables = useSelector(selectValidVariables);

  if (props.data.length === 0) {
    return (
      <p>
        <b>Computing...</b>
      </p>
    );
  }

  const summaryStats = getSummaryStats(
    props.data,
    policies,
    variables.map((v) => v.short_name),
    minT,
    maxT,
    confidenceRegion,
  );
  const data = showPolicies
    ? summaryStats.map((row) => ({
        ...row,
        option: policies[row.policyId],
      }))
    : summaryStats;

  const handleTimeMouseUp = (event, newValue) => {
    setTimeRange([newValue[0], newValue[1]]);
  };

  return (
    <>
      <TimeSeriesControls
        timeRange={[minT, maxT]}
        numPeriods={numPeriods}
        handleTimeMouseUp={handleTimeMouseUp}
        confidenceRegion={confidenceRegion}
        setConfidenceRegion={setConfidenceRegion}
      />
      {props.computing && (
        <div>
          <p />
          <p />
          <strong style={{ paddingTop: "48px" }}>{props.computing}</strong>
        </div>
      )}
      <div>
        {variables.map((variable) => (
          <Fragment key={variable.short_name + "Graph"}>
            <VegaLite
              style={{ marginTop: 24, width: "100%" }}
              spec={timeSeriesSpec(
                props.computing
                  ? []
                  : data.filter((r) => r.varName === variable.short_name),
                variable.short_name,
                showPolicies,
                granularity,
                policies,
                confidenceRegion,
              )}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
}
